/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  // withdrawals
  fetchWithdrawals(rowsPerPage, page) {
    return apiClient.get(`/withdrawals/list?rows=${rowsPerPage}&page=${page}`);
  },
  fetchClientWithdrawals(rowsPerPage, page, clientId) {
    return apiClient.get(
      `/withdrawals/list?rows=${rowsPerPage}&page=${page}&client_id=${clientId}`
    );
  },
  fetchAgentWithdrawals(rowsPerPage, page, agentId) {
    return apiClient.get(
      `/withdrawals/list?rows=${rowsPerPage}&page=${page}&agent_id=${agentId}`
    );
  },

  // withdrawal requests
  fetchWithdrawalRequests(rowsPerPage, page) {
    return apiClient.get(
      `/withdrawals/requests/list?rows=${rowsPerPage}&page=${page}`
    );
  },
};
