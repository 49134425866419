import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { bottomMenu, menuItems } from "../../configs/SidebarNav";

const Sidebar = ({ showMenu, toggleSidebar }) => {
  const SidebarItem = ({ item, index, onClick }) => {
    const { pathname } = useLocation();
    return (
      <Tooltip title={item.label} placement="right-end" arrow>
        <Link
          onClick={onClick}
          to={item.path}
          className={`${
            activeIndex === index || pathname === item.path
              ? "text-primary border-l-4 font-extrabold rounded-l-lg border-primary pl-5"
              : "border-l-4 rounded-l-lg border-transparent pl-5"
          } flex pl-6 items-center gap-2 p-1 hover:text-primary active:bg-secondary text-base mr-2 rounded-r-full mt-2`}
        >
          <div
            className={`${isMobile ? "text-xl" : "text-2xl"} font-extrabold`}
          >
            {item.icon}
          </div>
          <p className={`text-sm ${isMobile ? "" : "hidden"}`}>{item.label}</p>
        </Link>
      </Tooltip>
    );
  };
  const [isMobile, setIsMobile] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = menuItems.findIndex((item) => item.key === curPath);

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 969) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  return (
    <div
      className={`ease-in-out duration-1000 bg-gray-50 flex-col text-gray-700 w-16 sm:w-[250px] md:w-[250px] drop-shadow-xl sm:z-10 md:z-10 z-5 bottom-0 fixed top-10 overflow-y-scroll no-scrollbar
    ${
      isMobile
        ? showMenu
          ? "flex translate-x-0"
          : "hidden translate-x-full "
        : "flex"
    }`}
    >
      <div className="flex-1 flex flex-col">
        <div className="flex-1">
          {menuItems.map((item, index) => (
            <SidebarItem
              key={index}
              item={item}
              index={index}
              onClick={() => toggleSidebar()}
            />
          ))}
        </div>
      </div>
      <div>
        <div className="border-t border-gray-200 mb-4">
          {bottomMenu.map((item, index) => (
            <SidebarItem
              key={index}
              item={item}
              onClick={() => toggleSidebar()}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
