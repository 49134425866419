import {
  APPROVE_MERCHANT,
  SET_MERCHANTS,
  SET_MERCHANTS_DOCUMENTS,
  SET_SELECTED_MERCHANT,
  TOGGLE_FETCHING_MERCHANTS,
  TOGGLE_FETCHING_MERCHANTS_DOCUMENTS,
} from "./merchantActionTypes";

// set the initial state
const initialState = () => {
  return {
    merchants: [],
    merchantsDocuments: [],
    fetchingMerchants: false,
    fetchingMerchantsDocuments: false,
    selectedMerchant: {},
  };
};

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MERCHANTS:
      return { ...state, merchants: action.payload };

    case TOGGLE_FETCHING_MERCHANTS:
      return { ...state, fetchingMerchants: action.payload };

    case TOGGLE_FETCHING_MERCHANTS_DOCUMENTS:
      return { ...state, fetchingMerchantsDocuments: action.payload };

    case SET_SELECTED_MERCHANT:
      return { ...state, selectedMerchant: action.payload };

    case SET_MERCHANTS_DOCUMENTS:
      return { ...state, merchantsDocuments: action.payload };

    case APPROVE_MERCHANT:
      let newList = [...state.merchants];
      let foundIndex = newList?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      newList[foundIndex] = action.payload;
      return {
        ...state,
        merchants: newList,
      };
    /* falls through */

    default:
      return state;
  }
};

export default merchantReducer;
