import { roles } from "../../constants/data";

function RoleName({ id }) {
  const name = roles
    ? roles?.find((item) => item.role_id === Number(id)).role_name
    : "Undefined";
  return name;
}

export default RoleName;
