import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import rootReducer from "./ReducerRoutes";
import { configureStore } from "@reduxjs/toolkit";

// persist the state so that it doesnt get lost on page refresh
const persistConfig = {
  key: "paygram",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// get the reducers from the index

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

const persistor = persistStore(store);

export default store;

export { persistor };
