
import MerchantsClient from "../../api/MerchantsClient";
import {
  SET_MERCHANTS,
  SET_MERCHANTS_DOCUMENTS,
  SET_SELECTED_MERCHANT,
  TOGGLE_FETCHING_MERCHANTS,
  TOGGLE_FETCHING_MERCHANTS_DOCUMENTS,
} from "./merchantActionTypes";

// set selected merchant
export const toggleFetchingMerchants = (status) => {
  return {
    type: TOGGLE_FETCHING_MERCHANTS,
    payload: status,
  };
};

export const toggleFetchingMerchantsDocuments = (status) => {
  return {
    type: TOGGLE_FETCHING_MERCHANTS_DOCUMENTS,
    payload: status,
  };
};

export const setMerchants = (data) => {
  return {
    type: SET_MERCHANTS,
    payload: data,
  };
};

export const setMerchantsDocuments = (data) => {
  return {
    type: SET_MERCHANTS_DOCUMENTS,
    payload: data,
  };
};

export const setSelectedMerchant = (data) => {
  return {
    type: SET_SELECTED_MERCHANT,
    payload: data,
  };
};

// DISPATCH

export function setSelectedMerchantAction(data) {
  return function action(dispatch) {
    dispatch(setSelectedMerchant(data));
  };
}

export function fetchingMerchantsActionsAction(rowPerPage, page) {
  return function action(dispatch) {
    if (page === 1) dispatch(toggleFetchingMerchants(true));

    return MerchantsClient.fetchMerchants(rowPerPage, page)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setMerchants(response.data));
          dispatch(toggleFetchingMerchants(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingMerchants(false));
      });
  };
}

export function fetchingMerchantRecordAction(query) {
  return function action() {
    return MerchantsClient.fetchMerchantRecord(query)
      .then((response) => {
        return { success: true, data: response.data };
      })
      .catch((error) => {
        return { success: false, data: null };
      });
  };
}

export function fetchMerchantsTransactionsAction(rowPerPage, page, storeId) {
  return function action() {
    return MerchantsClient.fetchMerchantTransactions(rowPerPage, page, storeId)
      .then((response) => {
        // console.log(response.data);
        return { success: true, data: response.data };
      })
      .catch((error) => {
        // console.log(error.response);
        return { success: false, data: null };
      });
  };
}

export function fetchingMerchantsDocumentsAction() {
  return function action(dispatch) {
    dispatch(toggleFetchingMerchantsDocuments(true));

    return MerchantsClient.fetchMerchantsDocuments()
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(setMerchantsDocuments(response.data.data));
          dispatch(toggleFetchingMerchantsDocuments(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingMerchantsDocuments(false));
      });
  };
}

export function verifyMerchantDocsAction(data) {
  return function action(dispatch) {
    return MerchantsClient.verifyMerchant(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          return { status: true, data: response.data.data };
        }
      })
      .catch((error) => {
        // console.log(error);
        return { status: false, data: error.response.data.message };
      });
  };
}

export function fetchMerchantReportAction(merchant_id) {
  return function action() {
    return MerchantsClient.fetchMerchantReport(merchant_id)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  };
}
