import { SET_SELECTED_OPERATOR } from "./operatorActionTypes";


// set selected item
export const setSelectedOperator = (data) => {
  return {
    type: SET_SELECTED_OPERATOR,
    payload: data,
  };
};

export function setSelectedOperatorAction(data) {
  return function action(dispatch) {
    dispatch(setSelectedOperator(data));
  };
}
