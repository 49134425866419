import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Auth from "./pages/Auth";
import Administration from "./pages/Administration";
import Dashboard from "./pages/Dashboard";
import Clients from "./pages/Clients";
import Reports from "./pages/Reports";
import Profile from "./pages/Profile";
import UsersList from "./components/admin/UsersList";
import List from "./components/clients/List";
import Notifications from "./pages/Notifications";
import Departments from "./components/admin/Departments";
import Roles from "./components/admin/Roles";
import Users from "./components/clients/Users";
import Details from "./components/clients/Details";
import Agents from "./pages/Agents";
import Banks from "./pages/Banks";
import NetworkOperators from "./pages/NetworkOperators";
import Transactions from "./pages/Transactions";
import Payments from "./pages/Payments";
import Savings from "./pages/Savings";
import Blank from "./pages/Blank";
import AgentsList from "./components/agents/AgentsList";
import AgentsTransactionsHistory from "./components/agents/AgentsTransactionsHistory";
import BankTransactionsHistory from "./components/banks/BankTransactionsHistory";
import BanksList from "./components/banks/BanksList";
import OperatorsList from "./components/networkOperators/OperatorsList";
import OperatorTransactionsHistory from "./components/networkOperators/OperatorTransactionsHistory";
import AgentsReport from "./components/reports/AgentsReport";
import BanksReport from "./components/reports/BanksReport";
import ClientsReport from "./components/reports/ClientsReport";
import PaymentsReport from "./components/reports/PaymentsReport";
import RevenueReport from "./components/reports/RevenueReport";
import SystemUsers from "./components/reports/SystemUsers";
import TransactionsReport from "./components/reports/TransactionsReport";
import NetworkOperatorsReport from "./components/reports/NetworkOperatorsReport";
import SavingsReport from "./components/reports/SavingsReport";
import ReportsMenu from "./components/reports/ReportsMenu";
import MerchantsList from "./components/merchants/MerchantsList";
import MerchantsTransactionsHistory from "./components/merchants/MerchantsTransactionsHistory";
import WithdrawalsReport from "./components/reports/WithdrawalsReport";
import DepositsReport from "./components/reports/DepositsReport";
import MerchantsTransactionsReport from "./components/reports/MerchantsTransactionsReport";
import MerchantsReports from "./components/reports/MerchantsReports";
import ProtectedRoute from "./configs/ProtectedRoute";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import About from "./pages/About";
import ApproveAccount from "./components/clients/ApproveAccount";
import ApproveAgentAccount from "./components/agents/ApproveAgentAccount";
import Wallets from "./pages/Wallets";
import Withdrawals from "./pages/Withdrawals";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* all protected routed here */}

          <Route
            path="home"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          {/* Admin routes */}
          <Route
            path="admin"
            element={
              <ProtectedRoute>
                <Administration />
              </ProtectedRoute>
            }
          >
            <Route
              path="list"
              element={
                <ProtectedRoute>
                  <UsersList />
                </ProtectedRoute>
              }
            />
            <Route
              path="roles"
              element={
                <ProtectedRoute>
                  <Roles />
                </ProtectedRoute>
              }
            />
            <Route
              path="departments"
              element={
                <ProtectedRoute>
                  <Departments />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* client routes */}
          <Route
            path="clients"
            element={
              <ProtectedRoute>
                <Clients />
              </ProtectedRoute>
            }
          >
            <Route
              path="list"
              element={
                <ProtectedRoute>
                  <List />
                </ProtectedRoute>
              }
            />
            <Route
              path="details"
              element={
                <ProtectedRoute>
                  <Details />
                </ProtectedRoute>
              }
            />
            <Route
              path="users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="approve"
              element={
                <ProtectedRoute>
                  <ApproveAccount />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Agents routes */}
          <Route
            path="agents"
            element={
              <ProtectedRoute>
                <Agents />
              </ProtectedRoute>
            }
          >
            <Route
              path="list"
              element={
                <ProtectedRoute>
                  <AgentsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="transactions"
              element={
                <ProtectedRoute>
                  <AgentsTransactionsHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="approve"
              element={
                <ProtectedRoute>
                  <ApproveAgentAccount />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* banks routes */}
          <Route
            path="banks"
            element={
              <ProtectedRoute>
                <Banks />
              </ProtectedRoute>
            }
          >
            <Route
              path="list"
              element={
                <ProtectedRoute>
                  <BanksList />
                </ProtectedRoute>
              }
            />
            <Route
              path="transactions"
              element={
                <ProtectedRoute>
                  <BankTransactionsHistory />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* network operators routes */}
          <Route
            path="network_operators"
            element={
              <ProtectedRoute>
                <NetworkOperators />
              </ProtectedRoute>
            }
          >
            <Route
              path="list"
              element={
                <ProtectedRoute>
                  <OperatorsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="transactions"
              element={
                <ProtectedRoute>
                  <OperatorTransactionsHistory />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* transactions routes */}
          <Route
            path="transactions"
            element={
              <ProtectedRoute>
                <Transactions />
              </ProtectedRoute>
            }
          >
            <Route
              path="Blank"
              element={
                <ProtectedRoute>
                  <Blank />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* payments routes */}
          <Route
            path="payments"
            element={
              <ProtectedRoute>
                <Payments />
              </ProtectedRoute>
            }
          >
            <Route
              path="Blank"
              element={
                <ProtectedRoute>
                  <Blank />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* savings routes */}
          <Route
            path="savings"
            element={
              <ProtectedRoute>
                <Savings />
              </ProtectedRoute>
            }
          >
            <Route
              path="Blank"
              element={
                <ProtectedRoute>
                  <Blank />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* wallets routes */}
          <Route
            path="wallets"
            element={
              <ProtectedRoute>
                <Wallets />
              </ProtectedRoute>
            }
          >
            <Route
              path="Wallets"
              element={
                <ProtectedRoute>
                  <Wallets />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Withdrawals routes */}
          <Route
            path="Withdrawals"
            element={
              <ProtectedRoute>
                <Withdrawals />
              </ProtectedRoute>
            }
          >
            <Route
              path="Withdrawals"
              element={
                <ProtectedRoute>
                  <Withdrawals />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* merchants routes */}
          <Route
            path="merchants"
            element={
              <ProtectedRoute>
                <Agents />
              </ProtectedRoute>
            }
          >
            <Route
              path="list"
              element={
                <ProtectedRoute>
                  <MerchantsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="transactions"
              element={
                <ProtectedRoute>
                  <MerchantsTransactionsHistory />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* reports routes */}
          <Route
            path="reports"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          >
            <Route
              path="menu"
              element={
                <ProtectedRoute>
                  <ReportsMenu />
                </ProtectedRoute>
              }
            />
            <Route
              path="agents"
              element={
                <ProtectedRoute>
                  <AgentsReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="clients"
              element={
                <ProtectedRoute>
                  <ClientsReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="system_users"
              element={
                <ProtectedRoute>
                  <SystemUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path="banks"
              element={
                <ProtectedRoute>
                  <BanksReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="network_operators"
              element={
                <ProtectedRoute>
                  <NetworkOperatorsReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="transactions"
              element={
                <ProtectedRoute>
                  <TransactionsReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="payments"
              element={
                <ProtectedRoute>
                  <PaymentsReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="savings"
              element={
                <ProtectedRoute>
                  <SavingsReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="revenue"
              element={
                <ProtectedRoute>
                  <RevenueReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="withdrawals"
              element={
                <ProtectedRoute>
                  <WithdrawalsReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="deposits"
              element={
                <ProtectedRoute>
                  <DepositsReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="merchants"
              element={
                <ProtectedRoute>
                  <MerchantsReports />
                </ProtectedRoute>
              }
            />
            <Route
              path="merchants_transactions"
              element={
                <ProtectedRoute>
                  <MerchantsTransactionsReport />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route
            path="notifications"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Route>
        {/* list the public routes */}
        <Route index path="/" element={<Auth />} />
        <Route index path="terms" element={<Terms />} />
        <Route index path="privacy" element={<Privacy />} />
        <Route index path="about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
