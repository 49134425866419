import DocumentsClient from "../../api/DocumentsClient";
import {
  SET_DOCUMENTS,
  SET_SELECTED_ACCOUNT,
  TOGGLE_FETCHING_DOCUMENTS,
} from "./documentsActionTypes";

export const toggleFetchingDocuments = (status) => {
  return {
    type: TOGGLE_FETCHING_DOCUMENTS,
    payload: status,
  };
};

export const setDocuments = (data) => {
  return {
    type: SET_DOCUMENTS,
    payload: data,
  };
};

export const setSelectedAccount = (data) => {
  return {
    type: SET_SELECTED_ACCOUNT,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchingDocumentsActions() {
  return function action(dispatch) {
    dispatch(toggleFetchingDocuments(true));

    return DocumentsClient.fetchDocuments()
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setDocuments(response.data.data));
          dispatch(toggleFetchingDocuments(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingDocuments(false));
      });
  };
}

export function fetchClientProfilePicAction(id) {
  return function action() {
    return DocumentsClient.getClientDocs(id)
      .then((response) => {
        // console.log(response.data);
        let data = null;
        if (response.status === 200) {
          const res = response.data.data;
          if (res?.length) {
            const item = res?.find((el) => el.type === "PROF");
            data = item?.file;
          }
        }
        return data;
      })
      .catch((error) => {
        return null;
      });
  };
}

export function fetchClientDocsAction(id) {
  return function action() {
    return DocumentsClient.getClientDocs(id)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          return response.data.data;
        }
      })
      .catch((error) => {
        return null;
      });
  };
}

export function setSelectedAccountAction(data) {
  return function action(dispatch) {
    dispatch(setSelectedAccount(data));
  };
}
