import React from "react";
import Loader from "../components/global/Loader";
import DataTable from "../components/datatable/DataTable";
import CurrencyFormatter from "../components/global/CurrencyFormatter";
import DateTimeFormat from "../components/global/DateTimeFormat";
import { connect } from "react-redux";
import HeaderText from "../components/global/HeaderText";
import { payments } from "../constants/data";
import { paymentStatus } from "../components/global/PaymentStatus";

function Payments(props) {
  const headers = [
    "Payment ID",
    "Amount",
    "Payment date",
    "Paid to",
    "Paybill number",
    "Paid by",
    "Account number",
    "Status",
  ];
  let tableRows = [];

  if (payments.length) {
    for (let i = 0; i < payments.length; i++) {
      tableRows.push({
        payment_id: payments[i].payment_id,
        amount: <CurrencyFormatter value={payments[i].amount} />,
        created_at: <DateTimeFormat>{payments[i].created_at}</DateTimeFormat>,
        paid_to: payments[i].bank_name,
        paybill_number: payments[i].bank_id,
        paid_by: payments[i].client,
        payer_id: payments[i].account_number,
        status: paymentStatus(payments[i].status),
      });
    }
  }

  const displayContent = payments ? (
    <DataTable headers={headers} data={tableRows} />
  ) : (
    <Loader />
  );

  return (
    <div>
      {/* header information */}
      <HeaderText title="Bank transfers" />
      {displayContent}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedOperator: state.networkOperators?.selectedOperator ?? {},
  };
};

export default connect(mapStateToProps)(Payments);
