import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import { deposits } from "../../constants/data";
import CurrencyFormatter from "../global/CurrencyFormatter";
import DateTimeFormat from "../global/DateTimeFormat";
import { deposit_commissions } from "../../constants/Tariffs";
import HeaderInfo from "./HeaderInfo";

function Deposits() {
  const headers = [
    "Transaction ID",
    "Client",
    "Client ID",
    "Amount",
    "Commission",
    "Date",
  ];
  let tableRows = [];
  let commission = 0;

  if (deposits.length) {
    for (let i = 0; i < deposits.length; i++) {
      let commissionEarned = deposit_commissions(deposits[i].amount);
      commission = commission += commissionEarned;
      tableRows.push({
        transaction_id: deposits[i].transaction_id,
        client_name: deposits[i].client_name,
        client_id: deposits[i].client_id_number,
        amount: <CurrencyFormatter value={deposits[i].amount} />,
        commission: <CurrencyFormatter value={commissionEarned} />,
        created_at: <DateTimeFormat>{deposits[i].created_at}</DateTimeFormat>,
      });
    }
  }

  // fetching data
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  useEffect(() => {
    handleAction(selectedMonth);
  }, [selectedMonth]);

  let handleAction = (val) => {
    console.log(val);
  };

  const displayContent = deposits ? (
    !deposits.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );

  return (
    <div>
      {/* header information */}
      <HeaderInfo commission={commission} action={setSelectedMonth} />
      {displayContent}
    </div>
  );
}

export default Deposits;
