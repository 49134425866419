import React, { useEffect, useState } from "react";
import DashboardCard from "../components/dashboard/DashboardCard";
import MonthlyRevenue from "../components/dashboard/MonthlyRevenue";
import HeaderText from "../components/global/HeaderText";
import TransactionsGrid from "../components/dashboard/TransactionsGrid";
import NumberOfMerchantsChart from "../components/dashboard/NumberOfMerchantsChart";
import NumberOfAgentsChart from "../components/dashboard/NumberOfAgentsChart";
import NumberOfClientsChart from "../components/dashboard/NumberOfClientsChart";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChartsAnalysisAction,
  fetchTransactionsAnalysisAction,
  fetchingDashboardAnalysisAction,
} from "../redux/dashboard/dashboardActions";
import CurrencyFormatter from "../components/global/CurrencyFormatter";

function Dashboard() {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);
  const [data, setData] = useState(dashboardState.dashboardAnalysis);

  useEffect(() => {
    dispatch(fetchingDashboardAnalysisAction());
    dispatch(fetchChartsAnalysisAction());
    dispatch(fetchTransactionsAnalysisAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(dashboardState.dashboardAnalysis);
  }, [dashboardState.dashboardAnalysis]);

  const cards = [
    {
      title: "Total deposits",
      num: data?.total_deposits ?? 0,
      icon: <i className="bx bx-group"></i>,
      bgColor: "bg-red-50",
    },
    {
      title: "Total withdrawals",
      num: data?.total_withdrawals ?? 0,
      icon: <i className="bx bx-file"></i>,
      bgColor: "bg-gray-50",
    },
    {
      title: "Total transfers",
      num: data?.total_transfers ?? 0,
      icon: <i className="bx bx-moon"></i>,
      bgColor: "bg-secondary-50",
    },
    {
      title: "Total payments",
      num: data?.total_payments ?? 0,
      icon: <i className="bx bx-money"></i>,
      bgColor: "bg-green-50",
    },
  ];

  return (
    <div>
      <HeaderText title="Dashboard" />
      {/* cards */}
      <div className="flex space-x-2 w-full sm:grid sm:space-x-0 sm:space-y-3 md:space-x-2">
        {cards.map((item, index) => (
          <DashboardCard
            key={index}
            title={item.title}
            num={item.num ? <CurrencyFormatter value={item.num} /> : item.num}
            icon={item.icon}
            bgColor={item.bgColor}
          />
        ))}
      </div>
      {/* display recently added clients*/}
      <TransactionsGrid />

      {/*Total revenue chart */}
      <div className="w-full border-gray-200 border rounded-md shadow-sm">
        <div className=" text-gray-400 font-semibold text-sm border-gray-200 border-b px-4 py-2">
          Total revenue
        </div>
        <div className="px-4 py-2">
          <MonthlyRevenue />
        </div>
      </div>
      {/*monthly Total number of clients chart */}
      <div className="w-full border-gray-200 border rounded-md shadow-sm my-4">
        <div className=" text-gray-400 font-semibold text-sm border-gray-200 border-b px-4 py-2">
          Number of Clients
        </div>
        <div className="px-4 py-2">
          <NumberOfClientsChart />
        </div>
      </div>
      {/* monthly Total number of agents chart */}
      <div className="w-full border-gray-200 border rounded-md shadow-sm my-4">
        <div className=" text-gray-400 font-semibold text-sm border-gray-200 border-b px-4 py-2">
          Number of Agents
        </div>
        <div className="px-4 py-2">
          <NumberOfAgentsChart />
        </div>
      </div>
      {/*Monthly total revenue chart */}
      <div className="w-full border-gray-200 border rounded-md shadow-sm my-4">
        <div className=" text-gray-400 font-semibold text-sm border-gray-200 border-b px-4 py-2">
          Number of Merchants
        </div>
        <div className="px-4 py-2">
          <NumberOfMerchantsChart />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
