/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../components/global/Loader";
import CurrencyFormatter from "../components/global/CurrencyFormatter";
import { useDispatch, useSelector } from "react-redux";
import HeaderText from "../components/global/HeaderText";
import { fetchWalletBalancesAction } from "../redux/Index";
import ApiDataTable from "../components/datatable/ApiDataTable";

function Wallets() {
  const dispatch = useDispatch();
  const [walletBalances, setWalletBalances] = useState([]);
  const walletBalancesState = useSelector((state) => state?.transactions);
  useEffect(() => {
    getWalletBalances(10, 1);
  }, []);

  const getWalletBalances = (rowsPerPage, page) => {
    dispatch(fetchWalletBalancesAction(rowsPerPage, page));
  };

  useEffect(() => {
    setWalletBalances(walletBalancesState?.walletBalances);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletBalancesState?.walletBalances]);

  const headers = [
    "Client",
    "Phone number",
    "Wallet balance",
    "Withdrawal balance",
    "Creation date",
  ];
  let tableRows = [];
  const list = walletBalances?.data ? [...walletBalances.data] : [];

  if (list?.length) {
    for (let i = 0; i < list?.length; i++) {
      const element = list[i];
      tableRows.push({
        client: element?.client_full_name,
        phone_number: element?.client_phone_number,
        amount: <CurrencyFormatter value={element?.amount ?? 0} />,
        withdrawal_balance: (
          <CurrencyFormatter value={element?.withdrawal_balance ?? 0} />
        ),
        created_date: element?.created_date,
      });
    }
  }

  const displayContent = !walletBalancesState?.fetchingwalletBalances ? (
    <ApiDataTable
      headers={headers}
      data={tableRows}
      loadDataAction={getWalletBalances}
      totalPageCount={walletBalances?.last_page}
    />
  ) : (
    <Loader />
  );

  // console.log(walletBalances);

  return (
    <div>
      {/* header information */}
      <div className="flex flex-row items-center justify-between">
        <HeaderText title="Clients Wallet balances" />
        <div className="flex flex-row items-center">
          <p className="text-gray-700 text-sm">Total wallet balances:&nbsp; </p>
          <CurrencyFormatter
            value={walletBalances?.total_amount_transacted ?? 0}
          />
        </div>
      </div>
      {displayContent}
    </div>
  );
}

export default Wallets;
