import React from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import { payments } from "../../constants/data";
import CurrencyFormatter from "../global/CurrencyFormatter";
import DateTimeFormat from "../global/DateTimeFormat";
import { connect } from "react-redux";

function OperatorReversedTransactions(props) {
  const headers = [
    "Transaction ID",
    "Amount",
    "Transaction date",
    "Client",
    "Account number",
  ];
  let tableRows = [];

  let list = payments?.filter(
    (item) =>
      Number(item.status) === 2 &&
      item.bank_id === props.selectedOperator?.operator_id
  );

  if (list.length) {
    for (let i = 0; i < list.length; i++) {
      tableRows.push({
        payment_id: list[i].payment_id,
        amount: <CurrencyFormatter value={list[i].amount} />,
        created_at: <DateTimeFormat>{list[i].created_at}</DateTimeFormat>,
        client: list[i].client,
        account_number: list[i].account_number,
      });
    }
  }

  const displayContent = list ? (
    <DataTable headers={headers} data={tableRows} />
  ) : (
    <Loader />
  );

  return (
    <div>
      <div className="flex items-center justify-start"></div>
      {displayContent}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedOperator: state.networkOperators?.selectedOperator ?? {},
  };
};

export default connect(mapStateToProps)(OperatorReversedTransactions);
