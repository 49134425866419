import React from "react";

function CheckBox(props) {
  return (
    <div className="flex items-center mb-2">
      <label className="text-gray-600 text-sm flex items-center cursor-pointer">
        <input
          onChange={props.onChange}
          checked={props.isChecked}
          className="mr-2 h-4 w-4 cursor-pointer"
          type="checkbox"
          name={props.name}
          value={props.value}
        />
        {props.title}
      </label>
    </div>
  );
}

export default CheckBox;
