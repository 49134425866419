import React from "react";
import Spinner from "./Spinner";

const Button = ({ disabled, bgColor, type, text, textColor, onClick }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled ?? false}
      className={`${disabled ? "bg-gray-400" : bgColor ?? "bg-green-500"} ${
        textColor ?? "text-white"
      } btn bx-pull-right min-w-[100px] px-4 min-h-[32px] text-sm font-semibold rounded-md`}
      type={type}
    >
      {disabled ? <Spinner color={"white"} size={15} /> : text}
    </button>
  );
};

export default Button;
