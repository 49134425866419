import React, { useState } from "react";
import bgImg from "../assets/images/mainBg.jpg";
import logo from "../assets/images/logo.png";
import AuthInput from "../components/global/AuthInput";
import Button from "../components/global/Button";
import CheckBox from "../components/global/CheckBox";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { login } from "../redux/auth/authActions";
import { initializeToken } from "../redux/ApiClient";
import Footer from "../components/footer/Footer";

function Auth() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isPassword, setIsPassword] = useState(true);
  let togglePassword = () => {
    setIsPassword(!isPassword);
  };
  initializeToken();

  const [showLoginForm, setShowLoginForm] = useState(true);

  let toggleForms = () => {
    setShowLoginForm(!showLoginForm);
  };

  let [state, setState] = useState({
    username: "",
    password: "",
  });

  let handleChange = (e) => {
    const value = e.target.value;
    setState({ ...state, [e.target.name]: value });
  };

  //   execute login process
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (state.username && state.password) {
      dispatch(login(state)).then((status) => {
        if (status) {
          toast.success("Logged in successfuly");
          setLoading(false);
          window.location.href = "/home";
        } else {
          toast.error("Failed to log you in.");
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
      toast.error("All fields are required");
    }
  };

  //   send otp from forgotpass form
  let setOtpForm = () => {
    setLoading(true);
    if (state.username && state.password) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="flex-1 h-screen"
    >
      <div className="flex bg-primary bg-opacity-70 top-[5%] bottom-[5%] left-[5%] w-[90%] absolute rounded-3xl p-8">
        {/* right side container */}
        <div className="sm:hidden md:hidden w-1/2 items-center flex justify-center">
          <div className="flex flex-col items-center justify-center">
            {/* <h1 className="text-white text-6xl font-audiowide-regular">
              PayGram
            </h1> */}
            <img className="h-44 rounded-full" src={logo} alt="Logo" />
          </div>
        </div>
        {/* end of eft container */}

        {/* right side container */}
        <div className=" py-[25%] flex-col flex justify-center items-center w-1/2 sm:w-full md:w-full sm:border-l-0 md:border-l-0 border-l border-l-secondary">
          {/* logo to be displayed on small devices only */}
          <div className="hidden sm:flex md:flex flex-col items-center justify-center mb-2">
            <img className="w-[35%] rounded-full" src={logo} alt="Logo" />
          </div>

          {/* body of the form */}
          {/* title */}
          <div className="my-4 m-auto flex justify-center">
            <h3 className="text-3xl sm:text-xl text-white font-bold">
              {showLoginForm ? "Sign In" : "Forgot Password"}
            </h3>
          </div>

          {/* form */}
          {showLoginForm && (
            <form onSubmit={submitForm}>
              <div className="px-8 sm:px-4 w-[95%] sm:w-full m-auto">
                {/* input fields */}
                <AuthInput
                  prefix={
                    <i className="bx bxs-user-circle text-2xl text-gray-300"></i>
                  }
                  name="username"
                  type="text"
                  value={state.username}
                  onChange={handleChange}
                  required={true}
                  placeholder="username"
                />
                <AuthInput
                  prefix={
                    <i className="bx bxs-lock text-2xl text-gray-300"></i>
                  }
                  name="password"
                  type={isPassword ? "password" : "text"}
                  value={state.password}
                  onChange={handleChange}
                  required={true}
                  placeholder="Password"
                />
                <CheckBox
                  value="0"
                  title="Show password"
                  onChange={togglePassword}
                  checked={isPassword}
                />
              </div>

              {/* action button */}
              <div className="justify-center flex">
                {
                  <Button
                    bgColor={"bg-secondary"}
                    type="submit"
                    text="Sign in"
                    disabled={loading}
                  />
                }
              </div>
            </form>
          )}

          {/* reset password form */}
          {!showLoginForm && (
            <form onSubmit={setOtpForm}>
              <div className="px-8 sm:px-4 w-96 sm:w-full m-auto">
                <p className="text-sm text-white mb-4">
                  Enter your email so that we send you a One Time Password
                  (OTP).
                </p>
                {/* input fields */}
                <AuthInput
                  prefix={
                    <i className="bx bxs-user-circle text-2xl text-gray-300"></i>
                  }
                  name="email"
                  type="email"
                  value={state.username}
                  onChange={handleChange}
                  required={true}
                  placeholder="Email"
                />
              </div>

              {/* action button */}
              <div className="justify-center flex">
                {
                  <Button
                    bgColor={"bg-secondary"}
                    type="submit"
                    text={loading ? "Requesting..." : "Send OTP"}
                    disabled={loading}
                  />
                }
              </div>
            </form>
          )}
          <div className="flex mt-3 m-auto justify-center">
            <p
              onClick={toggleForms}
              className="text-white cursor-pointer sm:text-sm"
            >
              {showLoginForm ? "Forgot password?" : "Back to login page"}
            </p>
          </div>
          <div className="bottom-4 mt-[10%] w-full">
            <Footer />
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Auth;
