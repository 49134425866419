import React from "react";

function GridWrapper(props) {
  return (
    <div className="flex sm:grid space-x-2 my-3 sm:space-x-0 sm:space-y-3 md:space-x-2">
      {props.children}
    </div>
  );
}

export default GridWrapper;
