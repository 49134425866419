import React, { useEffect, useState } from "react";
import DataTable from "../datatable/DataTable";
import Button from "../global/Button";
import FullDateFormat from "../global/FullDateFormat";
import HeaderNav from "../global/HeaderNav";
import Loader from "../global/Loader";
import TextInput from "../global/TextInput";
import { merchantTransactions } from "../../constants/data";
import CurrencyFormatter from "../global/CurrencyFormatter";
import DateTimeFormat from "../global/DateTimeFormat";
import { merchant_commissions } from "../../constants/Tariffs";

function PaymentsReport() {
  const [inputValues, setInputValues] = useState({
    start_date: "",
    end_date: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [loadedData, setLoadedData] = useState(null);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  let loadData = () => {
    setLoadedData(merchantTransactions);
    setIsSubmit(false);
  };

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  //submit the form
  const handleSubmit = () => {
    setIsSubmit(true);

    if (
      inputValues.start_date !== "" &&
      inputValues.end_date !== "" &&
      isSubmit
    ) {
      loadData();
    }
  };

  const headers = [
    "Transaction ID",
    "Client",
    "Client ID",
    "Amount",
    "Commission",
    "Date",
    "Statement",
  ];
  let tableRows = [];

  if (loadedData?.length) {
    for (let i = 0; i < loadedData?.length; i++) {
      let commissionEarned = merchant_commissions(loadedData[i].amount);
      tableRows.push({
        transaction_id: loadedData[i].transaction_id,
        client_name: loadedData[i].client_name,
        client_id: loadedData[i].client_id_number,
        amount: <CurrencyFormatter value={loadedData[i].amount} />,
        commission: <CurrencyFormatter value={commissionEarned} />,
        created_at: <DateTimeFormat>{loadedData[i].created_at}</DateTimeFormat>,
        more: (
          <i
            className="bx bx-printer links text-[16px] cursor-pointer"
            onClick={() => printStatement(loadedData[i])}
          ></i>
        ),
      });
    }
  }

  const printStatement = (id) => {
    console.log(id);
  };

  const displayContent = isSubmit ? (
    <Loader />
  ) : loadedData ? (
    <DataTable headers={headers} data={tableRows} />
  ) : (
    ""
  );

  let exportPDF = () => {
    console.log("Export PDF");
  };

  return (
    <div>
      {/* display header */}
      <HeaderNav title="Merchants report" />

      {/* filters */}
      <div className="flex justify-center space-x-6">
        <div className="flex space-x-4 flex-row items-center">
          <TextInput
            required="required"
            label="From"
            name="start_date"
            type="date"
            value={inputValues.start_date}
            onChange={handleChange}
          />
        </div>
        <div className="flex space-x-4 flex-row items-center">
          <TextInput
            required="required"
            label="To"
            name="end_date"
            type="date"
            value={inputValues.end_date}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* summary */}
      {loadedData ? (
        <div className="flex flex-row sm:flex-col sm:space-y-2 justify-between items-center m-4">
          <div className="text-xs text-gray-600">
            <FullDateFormat>{inputValues.start_date}</FullDateFormat> &ensp; -
            &ensp; <FullDateFormat>{inputValues.end_date}</FullDateFormat>
          </div>
          <Button
            onClick={exportPDF}
            text="Export PDF"
            bgColor={"bg-primary"}
          />
        </div>
      ) : (
        ""
      )}

      {/* display contents */}
      {displayContent}
    </div>
  );
}

export default PaymentsReport;
