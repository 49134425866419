export const INITIALIZE_WITHDRAWAL_CHARGES_STATE =
  "INITIALIZE_WITHDRAWAL_CHARGES_STATE";

export const TOGGLE_FETCHING_WITHDRAWAL_CHARGES =
  "TOGGLE_FETCHING_WITHDRAWAL_CHARGES";
export const TOGGLE_CREATING_WITHDRAWAL_CHARGE =
  "TOGGLE_CREATING_WITHDRAWAL_CHARGE";
export const TOGGLE_UPDATING_WITHDRAWAL_CHARGE =
  "TOGGLE_UPDATING_WITHDRAWAL_CHARGE";
export const SET_WITHDRAWAL_CHARGES = "SET_WITHDRAWAL_CHARGES";
export const CREATE_WITHDRAWAL_CHARGE = "CREATE_WITHDRAWAL_CHARGE";
export const UPDATE_WITHDRAWAL_CHARGE = "UPDATE_WITHDRAWAL_CHARGE";

export const SET_WITHDRAWAL_CHARGES_SUCCESS_MESSAGE =
  "SET_WITHDRAWAL_CHARGES_SUCCESS_MESSAGE";
export const SET_WITHDRAWAL_CHARGES_ERROR_MESSAGE =
  "SET_WITHDRAWAL_CHARGES_ERROR_MESSAGE";
