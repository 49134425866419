import PermissionsClient from "../../api/PermissionsClient";
import {
  CREATE_MODULE,
  INITIALIZE_MODULES_STATE,
  SET_MODULES,
  SET_MODULE_ERROR_MESSAGE,
  SET_MODULE_SUCCESS_MESSAGE,
  TOGGLE_CREATING_MODULE,
  TOGGLE_FETCHING_MODULES,
  TOGGLE_UPDATING_MODULE,
  UPDATE_MODULE,
} from "./actionTypes";

// set the actions
export const initializeModulesState = () => {
  return {
    type: INITIALIZE_MODULES_STATE,
  };
};

export const toggleFetchingModules = (status) => {
  return {
    type: TOGGLE_FETCHING_MODULES,
    payload: status,
  };
};

export const toggleCreatingModule = (status) => {
  return {
    type: TOGGLE_CREATING_MODULE,
    payload: status,
  };
};

export const toggleUpdatingModule = (status) => {
  return {
    type: TOGGLE_UPDATING_MODULE,
    payload: status,
  };
};

export const setModules = (data) => {
  return {
    type: SET_MODULES,
    payload: data,
  };
};

export const createModule = (data) => {
  return {
    type: CREATE_MODULE,
    payload: data,
  };
};

export const updateModule = (data) => {
  return {
    type: UPDATE_MODULE,
    payload: data,
  };
};

export const setModuleSuccessMessage = (msg) => {
  return {
    type: SET_MODULE_SUCCESS_MESSAGE,
    payload: msg,
  };
};

export const setModuleErrorMessage = (error) => {
  return {
    type: SET_MODULE_ERROR_MESSAGE,
    payload: error,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchModulesAction() {
  return function action(dispatch) {
    dispatch(initializeModulesState);
    dispatch(toggleFetchingModules(true));

    return PermissionsClient.fetchModules()
      .then((response) => {
        if (response.status === 200) {
          dispatch(setModules(response.data.data));
          dispatch(toggleFetchingModules(false));
        } else {
          dispatch(setModuleErrorMessage("Something went wrong!"));
          dispatch(toggleFetchingModules(false));
        }
      })
      .catch((error) => {
        dispatch(setModuleErrorMessage("Server error"));
        dispatch(toggleFetchingModules(false));
      });
  };
}
export function createModuleAction(data) {
  return function action(dispatch) {
    dispatch(initializeModulesState);
    dispatch(toggleCreatingModule(true));

    return PermissionsClient.createModule(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(createModule(response.data.data));
          dispatch(toggleCreatingModule(false));
        } else {
          dispatch(setModuleErrorMessage("Something went wrong!"));
          dispatch(toggleCreatingModule(false));
        }
      })
      .catch((error) => {
        dispatch(setModuleErrorMessage("Server error"));
        dispatch(toggleCreatingModule(false));
      });
  };
}
export function updateModuleAction(data) {
  return function action(dispatch) {
    dispatch(initializeModulesState);
    dispatch(toggleUpdatingModule(true));

    return PermissionsClient.updateModule(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(updateModule(response.data.data));
          dispatch(toggleUpdatingModule(false));
        } else {
          dispatch(setModuleErrorMessage("Something went wrong!"));
          dispatch(toggleUpdatingModule(false));
        }
      })
      .catch((error) => {
        dispatch(setModuleErrorMessage("Server error"));
        dispatch(toggleUpdatingModule(false));
      });
  };
}
