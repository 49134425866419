export const sumValues = (arr, col) => {
  return arr.reduce(function (a, b) {
    return a + b[col];
  }, 0);
};

export const moneyFormat = (n) => {
  return (
    "Ksh. " +
    Number(n.toFixed(2)).toLocaleString("en", { minimumFractionDigits: 2 })
  );
};

export const searchQueryFormatter = (query) => {
  return query.replace(/(^"|"$)/g, "");
};
