/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import SidePanel from "../global/SidePanel";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClientsAction,
  fetchingClientRecordAction,
  fetchingClientReportAction,
  openAddSideModal,
  setSelectedAccountAction,
  setSelectedClientAction,
} from "../../redux/Index";
import HeaderText from "../global/HeaderText";
import Withdrawals from "./Withdrawals";
import TabsComponent from "../global/TabsComponent";
import avatar from "../../assets/images/avatar.jpg";
import { Link } from "react-router-dom";
import ApiDataTable from "../datatable/ApiDataTable";
import { ToastContainer, toast } from "react-toastify";
import Button from "../global/Button";

function List() {
  const [selectedItem, setSelectedItem] = useState({});
  const dispatch = useDispatch();
  const [clientsList, setClientsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const clientsState = useSelector((state) => state?.clients);

  useEffect(() => {
    getClients(10, 1);
    // eslint-disable-next-line
  }, []);

  const getClients = (rowsPerPage, page) => {
    dispatch(fetchClientsAction(page, rowsPerPage));
  };

  const fetchRecord = (query) => {
    toast.info("Searching...");
    dispatch(fetchingClientRecordAction(query)).then((res) => {
      if (res.success) {
        const num = res.data?.data?.length;
        if (num) {
          setClientsList(res.data);
          toast.success(`${num} Record(s) found`);
        } else {
          setClientsList(clientsState.clients);
          toast.warning(`No Records found`);
        }
      } else {
        setClientsList(clientsState.clients);
        toast.error(
          "Sorry, an error occured while searching. Please try again"
        );
      }
    });
  };

  useEffect(() => {
    setClientsList(clientsState?.clients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsState?.clients]);

  const exportPdf = () => {
    setLoading(true);
    dispatch(fetchingClientReportAction(selectedItem.id)).then((res) => {
      res
        ? toast.success(`Report generated, please check your email.`)
        : toast.error(`Failed to export report. Please try again later.`);

      setLoading(false);
    });
  };

  const headers = [
    "Profile picture",
    "National ID number",
    "First name",
    "Last name",
    "Email",
    "Telephone",
    "Creation date",
    "Approve account",
    "View transactions",
  ];

  // console.log(clientsList?.data);

  let tableRows = [];
  if (clientsList?.data?.length) {
    for (let i = 0; i < clientsList?.data?.length; i++) {
      const element = clientsList?.data[i];
      tableRows.push({
        profile_image: (
          <img
            src={element?.file ?? avatar}
            className="w-8 h-8 rounded-full"
            alt="Uimage"
          />
        ),
        id_number: element.id_number,
        first_name: element.first_name,
        last_name: element.last_name,
        email: element.email_address,
        telephone: element.phone_number,
        created_at: element.created_at,
        approve: (
          <Link to="/clients/approve">
            <i
              className="bx bx-user-check links text-[16px] cursor-pointer"
              onClick={() => approveAccount(element)}
            ></i>
          </Link>
        ),
        more: (
          <i
            className="bx bx-link-external links text-[16px] cursor-pointer"
            onClick={() => openMore(element)}
          ></i>
        ),
      });
    }
  }

  const openMore = (item) => {
    setSelectedItem(item);
    dispatch(setSelectedClientAction(item.id));
    openSidePanel();
  };
  const approveAccount = (item) => {
    dispatch(setSelectedAccountAction(item));
  };

  const displayContent = !clientsState?.fetchingClients ? (
    <ApiDataTable
      headers={headers}
      data={tableRows}
      loadDataAction={getClients}
      searchAction={fetchRecord}
      totalPageCount={clientsList?.last_page}
    />
  ) : (
    <Loader />
  );

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const tabs = [
    { name: "Withdrawals", component: <Withdrawals /> },
    // { name: "Deposits", component: <Deposits /> },
    // { name: "Bank transfers", component: <BankTransfers /> },
  ];

  return (
    <div>
      {/* page title */}
      <HeaderText title="Clients" />

      {displayContent}
      {/* display sidepanel */}
      <SidePanel
        width="75%"
        title={`${selectedItem?.first_name} ${selectedItem?.last_name}`}
        subtitle="View transactions"
      >
        <div className="flex flex-row items-center justify-end mb-4">
          <Button
            disabled={loading}
            onClick={exportPdf}
            text="Export all transactions report"
            bgColor={"bg-primary"}
          />
        </div>
        <TabsComponent tabsList={tabs} />
      </SidePanel>

      <ToastContainer />
    </div>
  );
}

export default List;
