/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAgentsAction,
  fetchingDocumentsActions,
  setSelectedAgentAction,
} from "../../redux/Index";
import HeaderText from "../global/HeaderText";
import { Link } from "react-router-dom";
import { standardDateTime } from "../global/DateUtilities";
import ApiDataTable from "../datatable/ApiDataTable";

function AgentsList() {
  const dispatch = useDispatch();
  const [agentsList, setAgentsList] = useState(null);
  const agentsState = useSelector((state) => state?.agents);

  const fetchData = (rowsPerPage, page) => {
    dispatch(fetchAgentsAction(rowsPerPage, page));
    dispatch(fetchingDocumentsActions());
  };
  useEffect(() => {
    fetchData(10, 1);
  }, []);

  useEffect(() => {
    setAgentsList(agentsState?.agents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentsState?.agents]);

  const headers = [
    "Agent ID",
    "Business name",
    "Business phone",
    "Contact person",
    "Contact number",
    "Business Address",
    "Float Limit",
    "Date created",
    "Approve account",
    "View transactions",
  ];
  let tableRows = [];
  const list = agentsList?.data;
  if (list?.length) {
    for (let i = 0; i < list?.length; i++) {
      const element = list[i];
      tableRows.push({
        agent_id: element?.id,
        business_name: element?.business_name,
        business_phone: element?.business_phone,
        contactPerson: element?.client_name,
        contactNumber: element?.client_phone_number,
        branch: element?.business_address,
        float_limit: 0,
        created_at: standardDateTime(element?.created_at),
        approve: (
          <Link to="/agents/approve">
            <i
              className="bx bx-user-check links text-[20px] cursor-pointer"
              onClick={() => setSelectedAgent(element)}
            ></i>
          </Link>
        ),
        more: (
          <Link to="/agents/transactions">
            <i
              className="bx bx-link-external links text-[16px] cursor-pointer"
              onClick={() => setSelectedAgent(element)}
            ></i>
          </Link>
        ),
      });
    }
  }

  const setSelectedAgent = (item) => {
    dispatch(setSelectedAgentAction(item));
  };

  return (
    <div>
      {/* page title */}
      <HeaderText title="Agents" />

      <div className="flex items-center justify-start"></div>
      {agentsState?.fetchingAgents ? (
        <Loader />
      ) : (
        <ApiDataTable
          headers={headers}
          data={tableRows}
          loadDataAction={fetchData}
          totalPageCount={agentsList?.last_page}
        />
      )}
    </div>
  );
}

export default AgentsList;
