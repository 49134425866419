/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  //all transactions
  fetchTransactions(rowsPerPage, page) {
    return apiClient.get(`/transactions/view?rows=${rowsPerPage}&page=${page}`);
  },

  // TRANSFER CHARGES
  createTransferCharge(data) {
    return apiClient.post("/transfer/charges/create", data);
  },
  fetchTransferCharges() {
    return apiClient.get("/transfer/charges/view");
  },
  updateTransferCharge(data) {
    return apiClient.put("/transfer/charges/update", data);
  },

  // WITHDRAWAL CHARGES
  createWithdrawalCharge(data) {
    return apiClient.post("/withdrawal/charges/create", data);
  },
  fetchWithdrawalCharges() {
    return apiClient.get("/withdrawal/charges/view");
  },
  updateWithdrawalCharge(data) {
    return apiClient.put("/withdrawal/charges/update", data);
  },

  // wallet
  fetchClientsWalletBalances(rowsPerPage, page) {
    return apiClient.get(`/wallets/view?rows=${rowsPerPage}&page=${page}`);
  },
};
