import React, { useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import { network_operators } from "../../constants/data";
import SidePanel from "../global/SidePanel";
import { useDispatch } from "react-redux";
import { openAddSideModal } from "../../redux/Index";
import Button from "../global/Button";
import TabsComponent from "../global/TabsComponent";
import HeaderNav from "../global/HeaderNav";
import FullDateFormat from "../global/FullDateFormat";
import OperatorCompletedTransactionsReport from "../networkOperators/OperatorCompletedTransactionsReport";
import OperatorReversedTransactionsReport from "../networkOperators/OperatorReversedTransactions Report";
import OperatorCancelledTransactionsReport from "../networkOperators/OperatorCancelledTransactionsReport";

function NetworkOperatorsReport() {
  const dispatch = useDispatch();
  const headers = [
    "Logo",
    "Operator name",
    "Paybill number",
    "Commission",
    "Date created",
    "Transactions",
  ];
  let tableRows = [];
  if (network_operators?.length) {
    for (let i = 0; i < network_operators?.length; i++) {
      tableRows.push({
        logo: (
          <img
            src={network_operators[i].logo}
            className="w-10 h-10 rounded-full"
            alt="Uimage"
          />
        ),
        operator_name: network_operators[i].operator_name,
        paybill_number: network_operators[i].paybill_number,
        commission: network_operators[i].commission,
        created_at: (
          <FullDateFormat>{network_operators[i].created_at}</FullDateFormat>
        ),
        more: (
          <i
            className="bx bx-link-external links text-[16px] cursor-pointer"
            onClick={() => openMore(network_operators[i])}
          ></i>
        ),
      });
    }
  }

  const [selectedOperator, setSelectedOperator] = useState();

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const openMore = (item) => {
    setSelectedOperator(item.operator_name);
    openSidePanel();
  };

  const displayContent = network_operators ? (
    !network_operators?.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );

  // TRANSACTIONS DATA
  const tabs = [
    {
      name: "Completed",
      component: <OperatorCompletedTransactionsReport />,
    },
    {
      name: "Reversed",
      component: <OperatorReversedTransactionsReport />,
    },
    {
      name: "Cancelled",
      component: <OperatorCancelledTransactionsReport />,
    },
  ];

  return (
    <div>
      {/* header information */}
      <div className="flex sm:flex-col justify-between items-center mb-2">
        <HeaderNav title="Network operators report" />
        <div className="flex items-center justify-end">
          <Button text={"Export Excel"} />
          <Button text={"Export PDF"} bgColor={"bg-red-500"} />
        </div>
      </div>

      {displayContent}
      {/* display sidepanel */}
      <SidePanel
        width="75%"
        title={selectedOperator}
        subtitle={`Transactions report`}
      >
        <TabsComponent tabsList={tabs} />
      </SidePanel>
    </div>
  );
}

export default NetworkOperatorsReport;
