import React, { useEffect, useState } from "react";
import { openAddSideModal } from "../../redux/Index";
import { useDispatch } from "react-redux";
import { clientSavings, savingsRecords } from "../../constants/data";
import CurrencyFormatter from "../global/CurrencyFormatter";
import DataTable from "../datatable/DataTable";
import Loader from "../global/Loader";
import DateTimeFormat from "../global/DateTimeFormat";
import SidePanel from "../global/SidePanel";
import HeaderNav from "../global/HeaderNav";
import Button from "../global/Button";
import SelectInput from "../global/SelectInput";

function SavingsReport(props) {
  const dispatch = useDispatch();
  // Total amount saved
  const [totalAmountSaved, setTotalAmountSaved] = useState(0);

  useEffect(() => {
    setTotalAmountSaved(2000000);
  }, []);
  const headers = [
    "Saver's national ID",
    "Saver's name",
    "Total savings",
    "More",
  ];
  let tableRows = [];

  if (clientSavings?.length) {
    for (let i = 0; i < clientSavings.length; i++) {
      tableRows.push({
        client_id: clientSavings[i].client_id,
        client: clientSavings[i].client_name,
        balance: <CurrencyFormatter value={clientSavings[i].balance} />,
        more: (
          <i
            className="bx bx-link-external links text-[16px] cursor-pointer"
            onClick={() => openMore(clientSavings[i])}
          ></i>
        ),
      });
    }
  }

  const [selectedClient, setSelectedClient] = useState();
  const [userTransactions, setUserTransactions] = useState();

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const selectedItem = (id) => {
    const transactions = savingsRecords?.filter(
      (item) => item.client_id === id
    );
    setUserTransactions(transactions);
  };

  const openMore = (item) => {
    selectedItem(item.client_id);
    setSelectedClient(item.client_name);
    openSidePanel();
  };

  const displayContent = clientSavings ? (
    <DataTable headers={headers} data={tableRows} />
  ) : (
    <Loader />
  );

  // DISPLAY SAVINGS AS PER CLIENT
  const savingsHeaders = [
    "Transaction ID",
    "Transaction date",
    "Type",
    "Amount",
    "Balance",
    "Status",
    "Fail reason",
    "Statement",
  ];
  let savingsTableRows = [];

  if (userTransactions?.length) {
    for (let i = 0; i < userTransactions.length; i++) {
      savingsTableRows.push({
        savings_id: userTransactions[i].savings_id,
        created_at: (
          <DateTimeFormat>{userTransactions[i].created_at}</DateTimeFormat>
        ),
        type: (
          <p
            className={` font-semibold ${
              userTransactions[i].type > 1 ? "text-red-500" : "text-green-500"
            }`}
          >
            {userTransactions[i].type > 1 ? "Debit" : "Credit"}
          </p>
        ),
        amount: <CurrencyFormatter value={userTransactions[i].amount} />,
        balance: <CurrencyFormatter value={userTransactions[i].balance} />,
        status: userTransactions[i].status > 0 ? "Completed" : "Failed",
        fail_reason:
          userTransactions[i].fail_reason === ""
            ? "N/A"
            : userTransactions[i].fail_reason,
        more: (
          <i
            className="bx bx-printer links text-[16px] cursor-pointer"
            onClick={() => printStatement(userTransactions[i])}
          ></i>
        ),
      });
    }
  }
  const printStatement = (id) => {
    console.log(id);
  };

  const exportPDF = () => {};
  const exportExcel = () => {};
  const exportTransactionsPDF = () => {};
  const exportTransactionsExcel = () => {};

  const [selectedFrequency, setSelectedFrequency] = useState(1);

  useEffect(() => {
    handleAction(selectedFrequency);
  }, [selectedFrequency]);

  let handleAction = (val) => {
    console.log(val);
  };

  const options = [
    { label: "Daily", value: 1 },
    { label: "Weekly", value: 2 },
    { label: "Monthly", value: 3 },
  ];

  const handleChange = (e) => {
    const val = e.target.value;
    setSelectedFrequency(val);
    handleAction(val);
  };

  return (
    <div>
      {/* header information */}
      <div className="flex flex-row sm:flex-col sm:space-y-2 justify-between items-center my-4">
        <HeaderNav title="Savings report" />
        <div className="flex flex-row sm:flex-col sm:space-y-2 justify-end items-center">
          <Button
            onClick={exportExcel}
            text="Export Excel"
            bgColor={"bg-green-500"}
          />
          <Button
            onClick={exportPDF}
            text="Export PDF"
            bgColor={"bg-primary"}
          />
        </div>
      </div>

      {/* display the total amount saved */}
      <div className="flex items-center justify-end w-full my-4">
        <div className=" border border-gray-200 h-16 w-max flex-col p-4 rounded-md shadow-md">
          <p className="text-end text-xs text-gray-500">Total amount saved</p>
          <span className="font-bold text-gray-500">
            {<CurrencyFormatter value={totalAmountSaved} />}
          </span>
        </div>
      </div>
      {displayContent}
      <SidePanel width="75%" title={selectedClient} subtitle={`Savings report`}>
        <div className="flex sm:flex-col justify-between items-center mb-2">
          <div className="w-40 sm:w-full mt-3">
            <SelectInput
              label="Select frequency"
              onChange={handleChange}
              options={options}
              name="switch_frequency"
              value={selectedFrequency}
            />
          </div>
          <div className="flex items-center justify-end">
            <Button onClick={exportTransactionsExcel} text={"Export Excel"} />
            <Button
              onClick={exportTransactionsPDF}
              text={"Export PDF"}
              bgColor={"bg-red-500"}
            />
          </div>
        </div>
        <DataTable headers={savingsHeaders} data={savingsTableRows} />
      </SidePanel>
    </div>
  );
}

export default SavingsReport;
