import React from "react";

const TextInput = (props) => {
  return (
    <div className="relative z-0 h-10 w-full px-2 mb-6 border-b border-gray-300">
      <input
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        autoComplete="off"
        className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent appearance-none dark:text-white focus:outline-none peer"
        placeholder=" "
        maxLength={props.maxLength}
      />
      <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
        {props.label}
      </label>
      <p className="text-red text-sm mt-2"> {props.inputError}</p>
    </div>
  );
};

export default TextInput;
