import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PromptModal from "../global/PromptModal";
import { logoutRequestAction } from "../../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

function Header({ toggleSidebar, setPromptOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth?.userInfo);
  console.log(userData);

  const [showMenu, setShowMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let logout = () => {
    setShowMenu(!showMenu);
    setOpenModal(!openModal);
    setPromptOpen(true);
  };

  let logoutActions = () => {
    setLoading(true);
    dispatch(logoutRequestAction()).then((status) => {
      if (status) {
        toast.success("Logout out successfully");
        setLoading(false);
        setPromptOpen(false);
        setOpenModal(!openModal);
        navigate("/");
      } else {
        toast.error("Logout failed. try again");
        setLoading(false);
      }
    });
  };

  return (
    <div>
      <PromptModal
        title="Logout"
        bodyText="Your session will be ended!"
        action={logoutActions}
        setPromptOpen={setPromptOpen}
        open={openModal}
        setOpenModal={setOpenModal}
        loading={loading}
      />
      {showMenu && (
        <div className="bg-white py-2 shadow-md absolute top-12 w-40 right-0 divide-y divide-gray-300 text-gray-500 z-50">
          <MenuItem
            onClick={() => setShowMenu(!showMenu)}
            title="Profile"
            routeTo="/profile"
            icon={<i className="bx bx-user-circle "></i>}
          />
          <MenuItem
            onClick={logout}
            title="Logout"
            icon={<i className="bx bx-log-out "></i>}
          />
        </div>
      )}
      <div className="bg-primary w-screen h-10 items-center justify-between flex flex-row p-2 fixed z-[0]">
        <div className="flex flex-row justify-center items-center">
          <i
            onClick={() => toggleSidebar()}
            className="bx bx-menu text-white font-bold text-xl mr-2 hidden sm:flex md:flex hover:text-secondary cursor-pointer"
          ></i>
          <div className="w-40 h-[100%] flex items-center justify-start rounded-full pl-6 sm:pl-1 md:pl-1">
            <p className="text-white text-xl font-audiowide-regular">PayGram</p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center">
          <i className="bx bxs-bell text-white font-bold text-xl mr-2 cursor-pointer"></i>
          <div
            onClick={() => setShowMenu(!showMenu)}
            className="flex flex-row justify-center items-center  text-white font-bold text-2xl cursor-pointer"
          >
            <p className="text-white text-sm sm:text-xs">
              {`${userData?.first_name} ${userData?.last_name}`}
            </p>
            <i className="bx bx-caret-down text-xl"></i>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

const MenuItem = ({ title, routeTo, icon, onClick }) => {
  return (
    <Link
      onClick={onClick}
      to={routeTo}
      className="flex flex-row space-x-2 mx-3 py-2 items-center"
    >
      <div className="text-xl">{icon}</div>
      <p>{title}</p>
    </Link>
  );
};

export default Header;
