/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import FabButton from "../global/FabButton";
import SidePanel from "../global/SidePanel";
import { connect, useDispatch } from "react-redux";
import {
  closeAddSideModal,
  createModuleAction,
  fetchModulesAction,
  openAddSideModal,
  updateModuleAction,
} from "../../redux/Index";
import TextInput from "../global/TextInput";
import Button from "../global/Button";
import { ToastContainer, toast } from "react-toastify";
import { Switch } from "@mui/material";
import CustomDateFormat from "../global/CustomDateFormat";

function Modules(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [modules, setModules] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    loadModules();
  }, []);

  const loadModules = () => {
    dispatch(fetchModulesAction());
  };

  useEffect(() => {
    if (isSubmit) {
      if (isEdit) {
        if (props.modulesState?.processSet) {
          setIsSubmit(false);
          closeSidePanel();
          toast.success("Module updated successfully");
        } else {
          toast.error("Failed to update Module");
        }
      } else {
        if (props.modulesState?.processSet) {
          setIsSubmit(false);
          closeSidePanel();
          toast.success("Module created");
        } else {
          toast.error("Failed to create Module");
        }
      }
    }
    setModules(props.modulesState?.modules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modulesState?.modules]);

  const headers = ["Module", "Created on", "Updated on", "Status", "Edit"];
  let tableRows = [];
  if (modules?.length) {
    for (let i = 0; i < modules?.length; i++) {
      tableRows.push({
        name: modules[i].name,
        created_at: (
          <CustomDateFormat>{modules[i].created_at}</CustomDateFormat>
        ),
        updated_at: (
          <CustomDateFormat>{modules[i].updated_at}</CustomDateFormat>
        ),
        status: (
          <Switch
            checked={modules[i].is_active}
            onChange={() => toggleModuleStatus(modules[i].is_active)}
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
        edit: (
          <i
            className="bx bx-edit links text-[20px] cursor-pointer"
            onClick={() => editModuleDetails(modules[i])}
          ></i>
        ),
      });
    }
  }

  useEffect(() => {
    if (isEdit && selectedItem) {
      setInputValues({
        name: selectedItem.name,
      });
    }
  }, [isEdit, selectedItem]);

  const toggleModuleStatus = (id) => {
    console.log(id);
  };
  const editModuleDetails = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    openSidePanel();
  };

  const [inputValues, setInputValues] = useState({
    name: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (inputValues.name !== "") {
      if (isEdit) {
        inputValues.module_id = selectedItem.id;
        dispatch(updateModuleAction(inputValues));
      } else {
        dispatch(createModuleAction(inputValues));
      }
    }
  };

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const closeSidePanel = () => {
    dispatch(closeAddSideModal());
  };

  const fabIconClick = () => {
    openSidePanel();
    setIsEdit(false);
    setInputValues({
      name: "",
    });
  };

  if (props.modulesState?.fetchingModules) {
    return <Loader />;
  }

  return (
    <div className="flex-1 h-full">
      <FabButton onPressAction={fabIconClick} />
      {/* load items in table */}
      <DataTable headers={headers} data={tableRows} />
      {/* display sidepanel */}
      <SidePanel width="30%" title="Modules" subtitle="Create a Module">
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            required="required"
            label="Module name"
            name="name"
            type="text"
            value={inputValues.name}
            onChange={handleChange}
          />
          <Button
            text={isSubmit ? "Processing..." : isEdit ? "Update" : "Save"}
            disabled={isSubmit}
          />
        </form>
      </SidePanel>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    modulesState: state.modules ? state.modules : [],
  };
};

export default connect(mapStateToProps)(Modules);
