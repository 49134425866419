/* eslint-disable import/no-anonymous-default-export */
import { searchQueryFormatter } from "../components/global/utilityFunctions";
import apiClient from "../redux/ApiClient";

export default {
  //paygram clients
  fetchClients(page, rowsPerPage) {
    return apiClient.get(`/clients/view?rows=${rowsPerPage}&page=${page}`);
  },
  fetchClientRecord(query) {
    return apiClient.get(`/clients/view?query=${searchQueryFormatter(query)}`);
  },
  fetchClientReport(client_id) {
    return apiClient.get(`/transactions/client/report?client_id=${client_id}`);
  },
  fetchClientsSavings(rowsPerPage, page) {
    return apiClient.get(`/goal/view?rows=${rowsPerPage}&page=${page}`);
  },
  clientActions(data) {
    return apiClient.post("clients/actions", data);
  },
};
