import { CLOSE_ADD_SIDE_MODAL, OPEN_ADD_SIDE_MODAL } from "./modalActionTypes";

// set the initial state
const initialState = () => {
  return {
    panelOpen: false,
  };
};

const sidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ADD_SIDE_MODAL:
      return { panelOpen: true };

    case CLOSE_ADD_SIDE_MODAL:
      return { panelOpen: false };

    default:
      return state;
  }
};

export default sidePanelReducer;
