export const usersList = [
  {
    xced_id: 1,
    first_name: "Daniel",
    last_name: "Mwambala",
    email: "danielmwambala@gmail.com",
    phone: "+25471457569",
    address: "Nairobi, Kenya",
    id_number: "34566778",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLe5PABjXc17cjIMOibECLM7ppDwMmiDg6Dw&usqp=CAU",
    role: "1",
    department: "2",
    assigned_modules: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  {
    xced_id: 2,
    first_name: "Daniel",
    last_name: "Mwambala",
    email: "sam@gmail.com",
    phone: "5335353534",
    address: "Mombasa Kenya",
    id_number: "58304983",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIKcTkARlljahDz7xR5gq-lwY3NSwsYMQdl_AlXfua4Yc2QcQ9QIG38gxtEiMGNAdoEck&usqp=CAU",
    role: "2",
    department: "2",
    assigned_modules: [1, 2, 3, 6, 8, 9, 10, 11],
  },
  {
    xced_id: 3,
    first_name: "Daniel",
    last_name: "Mwambala",
    email: "ogloa@gmail.com",
    phone: "9350923589",
    address: "Accra, Ghana",
    id_number: "23456789",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7LeozXy4Idugd5NRZ_tUiC3fyckljKnuFOoKjZhqkCJ9tPmt9Kbc4cZP3Yicl7LMyrnE&usqp=CAU",
    role: "3",
    department: "1",
    assigned_modules: [1, 2, 3, 4, 5, 6, 9],
  },
  {
    xced_id: 4,
    first_name: "Daniel",
    last_name: "Mwambala",
    email: "ogloa@gmail.com",
    phone: "9350923589",
    address: "Accra, Ghana",
    id_number: "23456789",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7LeozXy4Idugd5NRZ_tUiC3fyckljKnuFOoKjZhqkCJ9tPmt9Kbc4cZP3Yicl7LMyrnE&usqp=CAU",
    role: "3",
    department: "1",
    assigned_modules: [1, 2, 4, 6, 8, 10, 11],
  },
  {
    xced_id: 5,
    first_name: "Daniel",
    last_name: "Mwambala",
    email: "ogloa@gmail.com",
    phone: "9350923589",
    address: "Accra, Ghana",
    id_number: "23456789",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7LeozXy4Idugd5NRZ_tUiC3fyckljKnuFOoKjZhqkCJ9tPmt9Kbc4cZP3Yicl7LMyrnE&usqp=CAU",
    role: "3",
    department: "1",
    assigned_modules: [1, 3, 4, 6, 7, 9],
  },
  {
    xced_id: 6,
    first_name: "Daniel",
    last_name: "Mwambala",
    email: "ogloa@gmail.com",
    phone: "9350923589",
    address: "Accra, Ghana",
    id_number: "23456789",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7LeozXy4Idugd5NRZ_tUiC3fyckljKnuFOoKjZhqkCJ9tPmt9Kbc4cZP3Yicl7LMyrnE&usqp=CAU",
    role: "3",
    department: "1",
    assigned_modules: [1, 2, 3, 4, 5, 6],
  },
];

export const roles = [
  {
    role_id: 1,
    role_name: "Receptionist",
    department: "1",
  },
  {
    role_id: 2,
    role_name: "Marketer",
    department: "3",
  },
  {
    role_id: 3,
    role_name: "Accounting & Finance officer",
    department: "3",
  },
  {
    role_id: 4,
    role_name: "Sales & Marketing office",
    department: "2",
  },
];

export const departments = [
  {
    department_id: 1,
    department_name: "Front office",
  },
  {
    department_id: 2,
    department_name: "Back office",
  },
  {
    department_id: 3,
    department_name: "Accounting & Finance office",
  },
  {
    department_id: 4,
    department_name: "Sales & Marketing office",
  },
];

export const clientsList = [
  {
    user_id: 1236652,
    first_name: "Denis",
    last_name: "Ikulu",
    email: "ikulu@gmail.com",
    telephone: "0748395348",
    country: 1,
    address: "Nairobi, Kenya",
    created_at: "2022-05-10T12:59-0500",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLe5PABjXc17cjIMOibECLM7ppDwMmiDg6Dw&usqp=CAU",
  },
  {
    user_id: 2536589,
    first_name: "Moris",
    last_name: "Jomba",
    email: "jomba@gmail.com",
    telephone: "0748395348",
    country: 1,
    address: "Nairobi, Kenya",
    created_at: "2022-05-13T12:59-0500",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIKcTkARlljahDz7xR5gq-lwY3NSwsYMQdl_AlXfua4Yc2QcQ9QIG38gxtEiMGNAdoEck&usqp=CAU",
  },
  {
    user_id: 2365213,
    first_name: "Johnstone",
    last_name: "Musa",
    email: "musa@gmail.com",
    telephone: "0748395348",
    country: 2,
    address: "Nairobi, Kenya",
    created_at: "2022-05-11T12:59-0500",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7LeozXy4Idugd5NRZ_tUiC3fyckljKnuFOoKjZhqkCJ9tPmt9Kbc4cZP3Yicl7LMyrnE&usqp=CAU",
  },
  {
    user_id: 4025362,
    first_name: "Florence",
    last_name: "Akoth",
    email: "akoth@gmail.com",
    telephone: "0748395348",
    country: 3,
    address: "Nairobi, Kenya",
    created_at: "2022-05-18T12:59-0500",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7LeozXy4Idugd5NRZ_tUiC3fyckljKnuFOoKjZhqkCJ9tPmt9Kbc4cZP3Yicl7LMyrnE&usqp=CAU",
  },
  {
    user_id: 3695866,
    first_name: "Derrick",
    last_name: "Kelly",
    email: "kelly@gmail.com",
    telephone: "0748395348",
    country: 1,
    address: "Nairobi, Kenya",
    created_at: "2022-05-16T12:59-0500",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7LeozXy4Idugd5NRZ_tUiC3fyckljKnuFOoKjZhqkCJ9tPmt9Kbc4cZP3Yicl7LMyrnE&usqp=CAU",
  },
  {
    user_id: 3265322,
    first_name: "Jane",
    last_name: "Nyambura",
    email: "jane@gmail.com",
    telephone: "0746395348",
    country: 1,
    address: "Nairobi, Kenya",
    created_at: "2022-05-19T12:59-0500",
    profile_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7LeozXy4Idugd5NRZ_tUiC3fyckljKnuFOoKjZhqkCJ9tPmt9Kbc4cZP3Yicl7LMyrnE&usqp=CAU",
  },
];

export const subscriptionsList = [
  {
    client: "Mwanza Technical college",
    number_of_users: 310,
    fee_per_user: 250,
  },
  {
    client: "Homabay University",
    number_of_users: 650,
    fee_per_user: 250,
  },
  {
    client: "Njoro Technical college",
    number_of_users: 500,
    fee_per_user: 250,
  },
  {
    client: "Machakos University",
    number_of_users: 400,
    fee_per_user: 250,
  },
  {
    client: "Makueni College",
    number_of_users: 95,
    fee_per_user: 250,
  },
  {
    client: "Mombasa Aviation College",
    number_of_users: 56,
    fee_per_user: 150,
  },
  {
    client: "Kenya Institute of Professional Studies",
    number_of_users: 98,
    fee_per_user: 300,
  },
  {
    client: "Ikulu college",
    number_of_users: 120,
    fee_per_user: 150,
  },
];

// sendind and receiving
export const transactions = [
  {
    transaction_id: "ASD23JKVD44G1",
    name: "Susan Omaya",
    amount: 20000,
    date: "2022-04-19T12:59-0500",
    type: 2,
    status: 1,
    from_id: 22722947,
    from_name: "Susan Omaya",
    receiver_id: 25454555,
    receiver_name: "Gifton Nyambu",
  },
  {
    transaction_id: "ASD23JKVD44G2",
    name: "Fred Onyango",
    amount: 500,
    date: "2022-04-19T12:59-0500",
    type: 2,
    status: 0,
    from_id: 3366664,
    from_name: "Fed Onyango",
    receiver_id: 25825825,
    receiver_name: "Lilian Oloo",
  },
  {
    transaction_id: "ASD23JKVD44G3",
    name: "Kevin Msochi",
    amount: 833.54,
    date: "2022-01-07T12:59-0500",
    type: 1,
    status: 1,
    from_id: 74859658,
    from_name: "Kevin Msochi",
    receiver_id: 89562347,
    receiver_name: "Rose Ngalia",
  },
  {
    transaction_id: "ASD23JKVD44G4",
    name: "Grid Networks Ltd",
    amount: 895,
    date: "2022-04-19T12:59-0500",
    type: 2,
    status: 1,
    from_id: 98653287,
    from_name: "Grid Networks",
    receiver_id: 36251478,
    receiver_name: "Mjanja John",
  },
  {
    transaction_id: "ASD23JKVD44G5",
    name: "Beatrice Nguyo",
    amount: 400,
    date: "2022-04-19T12:59-0500",
    type: 1,
    status: 0,
    from_id: 22722946,
    from_name: "Bob Holf",
    receiver_id: 36214545,
    receiver_name: "Maria James",
  },
  {
    transaction_id: "ASD23JKVD4476",
    name: "KPLC",
    amount: 560,
    date: "2022-04-19T12:59-0500",
    type: 1,
    status: 0,
    from_id: 69555715,
    from_name: "Susan Okola",
    receiver_id: 58252525,
    receiver_name: "Joram Kililo",
  },
  {
    transaction_id: "ASD23JKVD44G7",
    name: "Equity Bank",
    amount: 1000,
    date: "2022-04-11T12:59-0500",
    type: 2,
    status: 1,
    from_id: 78251436,
    from_name: "Berry John",
    receiver_id: 25361425,
    receiver_name: "Kilion Harris",
  },
  {
    transaction_id: "ASD23JKVD44G8",
    name: "Piple Accesories",
    amount: 450,
    date: "2022-04-19T12:59-0500",
    type: 1,
    status: 0,
    from_id: 90909089,
    from_name: "SKilo hugo",
    receiver_id: 47584758,
    receiver_name: "Jack Jiil",
  },
  {
    transaction_id: "ASD23JKVD44G9",
    name: "Momasa Cement Ltd",
    amount: 4500,
    date: "2022-05-19T12:59-0500",
    type: 1,
    status: 1,
    from_id: 69584512,
    from_name: "Hassan Juma",
    receiver_id: 69554842,
    receiver_name: "Andriod Nyumba",
  },
  {
    transaction_id: "ASD23JKVD4410",
    name: "Mary Susan",
    amount: 630,
    date: "2022-04-19T12:59-0500",
    type: 2,
    status: 0,
    from_id: 89562358,
    from_name: "Bessof Hauof",
    receiver_id: 36251478,
    receiver_name: "Khan Halif",
  },
];

export const agentsList = [
  {
    agent_id: 145667,
    business_name: "Boon Communications",
    branch: "Gachie",
    float_limit: 1,
    country: 1,
    county: 41,
    address: "Nairobi, Kenya",
    contact_person: "Fred Boon",
    email: "business1@gmail.com",
    telephone: "0748395348",
    created_at: "2022-05-10T12:59-0500",
  },
  {
    agent_id: 234545,
    business_name: "Machoon Machants",
    branch: "Ruaka",
    float_limit: 2,
    country: 2,
    county: 61,
    address: "Nairobi, Kenya",
    contact_person: "Anita Mochy",
    email: "business2@gmail.com",
    telephone: "07483335348",
    created_at: "2022-05-10T12:59-0500",
  },
  {
    agent_id: 335871,
    business_name: "Dandora Tech",
    branch: "Danora",
    float_limit: 3,
    country: 1,
    county: 31,
    address: "Nairobi, Kenya",
    contact_person: "Omolo Jayson",
    email: "business3@gmail.com",
    telephone: "0748395555",
    created_at: "2022-05-10T12:59-0500",
  },
  {
    agent_id: 489568,
    business_name: "Nairobi Comm Ltd",
    branch: "Gachie",
    float_limit: 4,
    country: 1,
    county: 20,
    address: "Nairobi, Kenya",
    contact_person: "Fred Karanja",
    email: "business4@gmail.com",
    telephone: "0748789348",
    created_at: "2022-05-10T12:59-0500",
  },
  {
    agent_id: 741255,
    business_name: "Angel Technologies",
    branch: "Juja",
    float_limit: 5,
    country: 1,
    county: 18,
    address: "Nairobi, Kenya",
    contact_person: "Arnold Boon",
    email: "business5@gmail.com",
    telephone: "0748395348",
    created_at: "2022-05-10T12:59-0500",
  },
  {
    agent_id: 693547,
    business_name: "Thika Farm Comm Ltd",
    branch: "Thika",
    float_limit: 6,
    country: 1,
    county: 10,
    address: "Thika, Kenya",
    contact_person: "Onyango Jayson",
    email: "business6@gmail.com",
    telephone: "0748395348",
    created_at: "2022-05-10T12:59-0500",
  },
];

export const withdrawals = [
  {
    withdrawal_id: "HGDJHFDSSKJF",
    client_name: "Karanja Mwaura",
    client_id: 2,
    client_id_number: 29636258,
    agent_id: 145667,
    agent_name: "Thika Farm Comm Ltd",
    amount_withdrawn: 500,
    created_at: "2022-05-10T12:59-0500",
    type: 1, //1 for registered users, 2 from unregistered users
  },
  {
    withdrawal_id: "BFJDSKFJS",
    client_name: "Mary Alton",
    client_id: 3,
    client_id_number: 56456544,
    agent_id: 145667,
    agent_name: "Cisa Comm Ltd",
    amount_withdrawn: 23220,
    created_at: "2022-05-10T12:59-0500",
    type: 1, //1 for registered users, 2 from unregistered users
  },
  {
    withdrawal_id: "78HFSDFHKJ",
    client_name: "Karani John",
    client_id: 1,
    client_id_number: 27585555,
    agent_id: 234545,
    agent_name: "KIM Tech",
    amount_withdrawn: 3000,
    created_at: "2022-05-10T12:59-0500",
    type: 2, //1 for registered users, 2 from unregistered users
  },
  {
    withdrawal_id: "HTNJ778JFF",
    client_name: "Emily Kathure",
    client_id: 1,
    client_id_number: 78454644,
    agent_id: 234545,
    agent_name: "KIM Tech",
    amount_withdrawn: 3000,
    created_at: "2022-05-10T12:59-0500",
    type: 2, //1 for registered users, 2 from unregistered users
  },
  {
    withdrawal_id: "KDJDFHJ777FK",
    client_name: "Nancy John",
    client_id: 1,
    client_id_number: 589565874,
    agent_id: 335871,
    agent_name: "KIM Tech",
    amount_withdrawn: 3000,
    created_at: "2022-05-10T12:59-0500",
    type: 2, //1 for registered users, 2 from unregistered users
  },

  {
    withdrawal_id: "KDJDFHJ777FK",
    client_name: "Nancy John",
    client_id: 1,
    client_id_number: 589565874,
    agent_id: 489568,
    agent_name: "KIM Tech",
    amount_withdrawn: 3000,
    created_at: "2022-05-10T12:59-0500",
    type: 2, //1 for registered users, 2 from unregistered users
  },

  {
    withdrawal_id: "KDJDFHJ777FK",
    client_name: "Nancy John",
    client_id: 1,
    client_id_number: 589565874,
    agent_id: 489568,
    agent_name: "KIM Tech",
    amount_withdrawn: 3000,
    created_at: "2022-05-10T12:59-0500",
    type: 2, //1 for registered users, 2 from unregistered users
  },

  {
    withdrawal_id: "KDJDFHJ777FK",
    client_name: "Nancy John",
    client_id: 1,
    client_id_number: 589565874,
    agent_id: 741255,
    agent_name: "KIM Tech",
    amount_withdrawn: 3000,
    created_at: "2022-05-10T12:59-0500",
    type: 2, //1 for registered users, 2 from unregistered users
  },

  {
    withdrawal_id: "KDJDFHJ777FK",
    client_name: "Nancy John",
    client_id: 1,
    client_id_number: 589565874,
    agent_id: 741255,
    agent_name: "KIM Tech",
    amount_withdrawn: 3000,
    created_at: "2022-05-10T12:59-0500",
    type: 2, //1 for registered users, 2 from unregistered users
  },

  {
    withdrawal_id: "KDJDFHJ777FK",
    client_name: "Nancy John",
    client_id: 1,
    client_id_number: 589565874,
    agent_id: 741255,
    agent_name: "KIM Tech",
    amount_withdrawn: 3000,
    created_at: "2022-05-10T12:59-0500",
    type: 2, //1 for registered users, 2 from unregistered users
  },

  {
    withdrawal_id: "KDJDFHJ777FK",
    client_name: "Nancy John",
    client_id: 1,
    client_id_number: 589565874,
    agent_id: 693547,
    agent_name: "KIM Tech",
    amount_withdrawn: 3000,
    created_at: "2022-05-10T12:59-0500",
    type: 2, //1 for registered users, 2 from unregistered users
  },

  {
    withdrawal_id: "KDJDFHJ777FK",
    client_name: "Nancy John",
    client_id: 1,
    client_id_number: 589565874,
    agent_id: 693547,
    agent_name: "KIM Tech",
    amount_withdrawn: 3000,
    created_at: "2022-05-10T12:59-0500",
    type: 2, //1 for registered users, 2 from unregistered users
  },
];

export const deposits = [
  {
    transaction_id: "HGDJHFDSSKJF",
    client_name: "Karanja Mwaura",
    client_id: 2,
    client_id_number: 589565874,
    agent_id: 1,
    agent_name: "Thika Farm Comm Ltd",
    amount: 600,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "BFJDSKFJS",
    client_name: "Mary Alton",
    client_id: 3,
    client_id_number: 25456465,
    agent_id: 2,
    agent_name: "Cisa Comm Ltd",
    amount: 29220,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "78HFSDFHKJ",
    client_name: "Karani John",
    client_id: 1,
    client_id_number: 78565565,
    agent_id: 1,
    agent_name: "KIM Tech",
    amount: 3900,
    created_at: "2022-05-10T12:59-0500",
  },
];

export const bankList = [
  {
    bank_id: 1,
    logo: "https://images.africanfinancials.com/ke-nbk-logo-min.png",
    bank_name: "National Bank of Kenya",
    paybill_number: "258695",
    commission: 0.06,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    bank_id: 2,
    logo: "https://equitygroupholdings.com/ke/templates/equity/assets/img/equity-bank-logo.png",
    bank_name: "Equity Bank",
    paybill_number: "247247",
    commission: 0.16,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    bank_id: 3,
    logo: "https://financialallianceforwomen.org/wp-content/uploads/2016/11/kcb-e1480436893425.jpg",
    bank_name: "Kenya Commercial Bank",
    paybill_number: "522522",
    commission: 0.15,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    bank_id: 4,
    logo: "https://www.saniextra.co.ke/assets/img/clients/family.png",
    bank_name: "Family Bank",
    paybill_number: "222111",
    commission: 0.125,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    bank_id: 5,
    logo: "https://pbs.twimg.com/profile_images/1226113539343110144/ORAFs3_e_400x400.png",
    bank_name: "ABSA",
    paybill_number: "303030",
    commission: 0.14,
    created_at: "2022-05-10T12:59-0500",
  },
];

export const payments = [
  {
    payment_id: "GDJGSKGSKJG",
    amount: 2600,
    created_at: "2022-05-10T12:59-0500",
    account_number: "0745883493",
    bank_id: 1,
    bank_name: "Equity Bank",
    status: 1, // 1 for completed, 2 for reversed, 3 for cancelled
    client: "Holand Jefferson",
    client_id: 1,
  },
  {
    payment_id: "LSLGGHDFD4",
    amount: 600,
    created_at: "2022-05-10T12:59-0500",
    account_number: "04586255",
    bank_id: 1,
    bank_name: "National Bank of Kenya",
    status: 2,
    client: "Fred Onyango",
    client_id: 17,
  },
  {
    payment_id: "TRANFDHGJK45",
    amount: 400,
    created_at: "2022-05-10T12:59-0500",
    account_number: "42862228",
    bank_id: 2,
    bank_name: "National Bank of Kenya",
    status: 3,
    client: "Hannah Mwala",
    client_id: 3,
  },
  {
    payment_id: "MDJKDGJD233",
    amount: 980,
    created_at: "2022-05-10T12:59-0500",
    account_number: "0745883493",
    bank_id: 2,
    bank_name: "National Bank of Kenya",
    status: 1,
    client: "Mary Johnson",
    client_id: 5,
  },
  {
    payment_id: "LSKDJGSLJLG",
    amount: 830,
    created_at: "2022-05-10T12:59-0500",
    account_number: "0745883493",
    bank_id: 3,
    bank_name: "Kenya Commercial Bank",
    status: 1,
    client: "Nancy Pelosy",
    client_id: 6,
  },
  {
    payment_id: "YRLSNGUISH2",
    amount: 900,
    created_at: "2022-05-10T12:59-0500",
    account_number: "0745883493",
    bank_id: 3,
    bank_name: "Kenya Commercial Bank",
    status: 1,
    client: "Kelly Gregory",
    client_id: 7,
  },
  {
    payment_id: "GHWJLAJSSGLKJ",
    amount: 1000,
    created_at: "2022-05-10T12:59-0500",
    account_number: "0745883493",
    bank_id: 4,
    bank_name: "Kenya Commercial Bank",
    status: 1,
    client: "Moses Davis",
    client_id: 8,
  },
  {
    payment_id: "SAGSKJGLLFL",
    amount: 740,
    created_at: "2022-05-10T12:59-0500",
    account_number: "0745883493",
    bank_id: 4,
    bank_name: "Family Bank",
    status: 3,
    client: "Mwema Kasyoki",
    client_id: 9,
  },
  {
    payment_id: "XBVXNJGHSKS",
    amount: 3000,
    created_at: "2022-05-10T12:59-0500",
    account_number: "0745883493",
    bank_id: 5,
    bank_name: "Family Bank",
    status: 3,
    client: "Musa Juma",
    client_id: 10,
  },
  {
    payment_id: "2KGHDFKGJ673",
    amount: 950,
    created_at: "2022-05-10T12:59-0500",
    account_number: "0745883493",
    bank_id: 5,
    bank_name: "Family Bank",
    status: 3,
    client: "Gilbert Nyaga",
    client_id: 11,
  },
  {
    payment_id: "GSJFSK83DKJF",
    amount: 850,
    created_at: "2022-05-10T12:59-0500",
    account_number: "0745883493",
    bank_id: 1,
    bank_name: "Equity Bank",
    status: 1,
    client: "Rose Gema",
    client_id: 12,
  },
  {
    payment_id: "NSGSJKLH3497",
    amount: 5000,
    created_at: "2022-05-10T12:59-0500",
    account_number: "0745883493",
    bank_id: 2,
    bank_name: "Equity Bank",
    status: 1,
    client: "Jane Kamau",
    client_id: 4,
  },
];

export const float_limits = [
  { id: 1, value: 10000 },
  { id: 2, value: 25000 },
  { id: 3, value: 50000 },
  { id: 4, value: 100000 },
  { id: 5, value: 150000 },
  { id: 6, value: 300000 },
];

export const months = [
  { name_long: "January", name_short: "Jan", number: 0 },
  { name_long: "February", name_short: "Feb", number: 1 },
  { name_long: "March", name_short: "Mar", number: 2 },
  { name_long: "April", name_short: "Apr", number: 3 },
  { name_long: "May", name_short: "May", number: 4 },
  { name_long: "June", name_short: "Jun", number: 5 },
  { name_long: "Jul", name_short: "Jul", number: 6 },
  { name_long: "August", name_short: "Aug", number: 7 },
  { name_long: "September", name_short: "Sep", number: 8 },
  { name_long: "October", name_short: "Oct", number: 9 },
  { name_long: "November", name_short: "Nov", number: 10 },
  { name_long: "December", name_short: "Dec", number: 11 },
];

export const network_operators = [
  {
    operator_id: 1,
    logo: "https://play-lh.googleusercontent.com/bRZF74-13jknePwUd1xam5ZCSdAJVuI_wqtkrisBgu7EEh1jobh2boZihlk-4ikY_S3V",
    operator_name: "Mpesa",
    paybill_number: "25869544",
    commission: 0.06,
    created_at: "2022-05-08T04:59-0520",
  },
  {
    operator_id: 2,
    logo: "https://e1.pxfuel.com/desktop-wallpaper/26/917/desktop-wallpaper-airtel-logo.jpg",
    operator_name: "Airtel",
    paybill_number: "3658358",
    commission: 0.09,
    created_at: "2023-05-05T12:33-0500",
  },
];

export const savingsRecords = [
  {
    savings_id: "TFHSDJ76H",
    amount: 553,
    created_at: "2023-05-05T12:33-0500",
    client_id: 154545,
    balance: 3698,
    type: 1,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "HFHSJ7855G",
    amount: 258,
    created_at: "2023-01-05T12:13-0500",
    client_id: 154545,
    balance: 550,
    type: 2,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "AHDS786HHH3",
    amount: 690,
    created_at: "2023-02-05T11:05-0500",
    client_id: 154545,
    balance: 6000,
    type: 1,
    status: 0,
    fail_reason: "Some error from backend",
  },
  {
    savings_id: "GHRJ778FHD7",
    amount: 50,
    created_at: "2023-04-05T09:15-0500",
    client_id: 154545,
    balance: 550,
    type: 2,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "ZHSDJKFSFHG",
    amount: 506,
    created_at: "2023-03-05T09:15-0500",
    client_id: 695823,
    balance: 5520,
    type: 2,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "KKKKFSFSDF",
    amount: 600,
    created_at: "2023-03-03T09:35-0500",
    client_id: 695823,
    balance: 5350,
    type: 1,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "GHRJ778FHD7",
    amount: 50,
    created_at: "2023-04-05T09:15-0500",
    client_id: 695823,
    balance: 550,
    type: 2,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "GHRJ778FHD7",
    amount: 50,
    created_at: "2023-04-05T09:15-0500",
    client_id: 258258,
    balance: 550,
    type: 1,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "GHRJ778FHD7",
    amount: 50,
    created_at: "2023-04-05T09:15-0500",
    client_id: 258258,
    balance: 550,
    type: 1,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "KESJGGKFJDK",
    amount: 530,
    created_at: "2023-04-05T09:15-0500",
    client_id: 258258,
    balance: 550,
    type: 2,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "HSGKJGHKJGHJ",
    amount: 500,
    created_at: "2023-04-05T09:15-0500",
    client_id: 475869,
    balance: 2533,
    type: 1,
    status: 0,
    fail_reason: "Server error",
  },
  {
    savings_id: "KHGDFH6867J",
    amount: 40,
    created_at: "2023-01-03T07:15-0500",
    client_id: 475869,
    balance: 5250,
    type: 1,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "FHFGJKKDFJ",
    amount: 50,
    created_at: "2023-02-05T03:15-0500",
    client_id: 475869,
    balance: 5500,
    type: 2,
    status: 0,
    fail_reason: "Some error from backend",
  },
  {
    savings_id: "GHRJ778FHD7",
    amount: 95,
    created_at: "2023-04-05T09:15-0500",
    client_id: 475869,
    balance: 8000,
    type: 2,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "NFSDKJFFJK",
    amount: 70,
    created_at: "2023-01-05T02:15-0500",
    client_id: 235623,
    balance: 550,
    type: 1,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "KFHHJS799G",
    amount: 50,
    created_at: "2022-04-05T09:15-0500",
    client_id: 235623,
    balance: 550,
    type: 2,
    status: 1,
    fail_reason: "",
  },
  {
    savings_id: "NDJKHDSFJH",
    amount: 366,
    created_at: "2023-04-05T09:15-0500",
    client_id: 235623,
    balance: 900,
    type: 2,
    status: 0,
    fail_reason: "Some error from backend",
  },
];

export const clientSavings = [
  {
    client_id: 154545,
    client_name: "Holfin Mercy",
    balance: 3698,
  },
  {
    client_id: 695823,
    client_name: "Anna Grace",
    balance: 5520,
  },
  {
    client_id: 258258,
    client_name: "George Musa",
    balance: 550,
  },
  {
    client_id: 475869,
    client_name: "Putin Joseph",
    balance: 2533,
  },
  {
    client_id: 235623,
    client_name: "Kamau Musa",
    balance: 550,
  },
];

export const merchantsList = [
  {
    merchant_id: 145667,
    merchant_name: "Boon Communications",
    branch: "Gachie",
    country: 1,
    county: 41,
    address: "Nairobi, Kenya",
    contact_person: "Fred Boon",
    email: "business1@gmail.com",
    telephone: "0748395348",
    created_at: "2022-05-10T12:59-0500",
  },
  {
    merchant_id: 234545,
    merchant_name: "Machoon Machants",
    branch: "Ruaka",
    country: 2,
    county: 61,
    address: "Nairobi, Kenya",
    contact_person: "Anita Mochy",
    email: "business2@gmail.com",
    telephone: "07483335348",
    created_at: "2022-05-10T12:59-0500",
  },
  {
    merchant_id: 335871,
    merchant_name: "Dandora Tech",
    branch: "Danora",
    country: 1,
    county: 31,
    address: "Nairobi, Kenya",
    contact_person: "Omolo Jayson",
    email: "business3@gmail.com",
    telephone: "0748395555",
    created_at: "2022-05-10T12:59-0500",
  },
  {
    merchant_id: 489568,
    merchant_name: "Nairobi Comm Ltd",
    branch: "Gachie",
    country: 1,
    county: 20,
    address: "Nairobi, Kenya",
    contact_person: "Fred Karanja",
    email: "business4@gmail.com",
    telephone: "0748789348",
    created_at: "2022-05-10T12:59-0500",
  },
  {
    merchant_id: 741255,
    merchant_name: "Angel Technologies",
    branch: "Juja",
    country: 1,
    county: 18,
    address: "Nairobi, Kenya",
    contact_person: "Arnold Boon",
    email: "business5@gmail.com",
    telephone: "0748395348",
    created_at: "2022-05-10T12:59-0500",
  },
  {
    merchant_id: 693547,
    merchant_name: "Thika Farm Comm Ltd",
    branch: "Thika",
    country: 1,
    county: 10,
    address: "Thika, Kenya",
    contact_person: "Onyango Jayson",
    email: "business6@gmail.com",
    telephone: "0748395348",
    created_at: "2022-05-10T12:59-0500",
  },
];

export const merchantTransactions = [
  {
    transaction_id: "HGDJHFDSSKJF",
    client_name: "Karanja Mwaura",
    client_id: 2536255,
    client_id_number: 589565874,
    merchant_id: 693547,
    merchant_name: "Thika Farm Comm Ltd",
    amount: 600,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "BFJDSKFJS",
    client_name: "Mary Alton",
    client_id: 3625148,
    client_id_number: 25456465,
    merchant_id: 693547,
    merchant_name: "Cisa Comm Ltd",
    amount: 29220,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "78HFSDFHKJ",
    client_name: "Karani John",
    client_id: 5689452,
    client_id_number: 78565565,
    merchant_id: 693547,
    merchant_name: "KIM Tech",
    amount: 3900,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "NFGHJGDGDJHG",
    client_name: "Karani John",
    client_id: 1425146,
    client_id_number: 78565565,
    merchant_id: 741255,
    merchant_name: "KIM Tech",
    amount: 45464,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "GNDHFFKJG89",
    client_name: "Karani John",
    client_id: 5689452,
    client_id_number: 78565565,
    merchant_id: 741255,
    merchant_name: "KIM Tech",
    amount: 545,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "253256HHHD",
    client_name: "Karani John",
    client_id: 5689452,
    client_id_number: 78565565,
    merchant_id: 741255,
    merchant_name: "KIM Tech",
    amount: 500,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "NFGHJGDGDJHG",
    client_name: "Karani John",
    client_id: 1425146,
    client_id_number: 78565565,
    merchant_id: 489568,
    merchant_name: "KIM Tech",
    amount: 3900,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "GNDHFFKJG89",
    client_name: "Karani John",
    client_id: 489568,
    client_id_number: 78565565,
    merchant_id: 741255,
    merchant_name: "KIM Tech",
    amount: 3250,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "253256HHHD",
    client_name: "Karani John",
    client_id: 489568,
    client_id_number: 78565565,
    merchant_id: 741255,
    merchant_name: "KIM Tech",
    amount: 3900,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "GNDHFFKJG89",
    client_name: "Karani John",
    client_id: 489568,
    client_id_number: 78565565,
    merchant_id: 741255,
    merchant_name: "KIM Tech",
    amount: 1200,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "253256HHHD",
    client_name: "Karani John",
    client_id: 489568,
    client_id_number: 78565565,
    merchant_id: 234545,
    merchant_name: "KIM Tech",
    amount: 3900,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "GNDHFFKJG89",
    client_name: "Karani John",
    client_id: 489568,
    client_id_number: 78565565,
    merchant_id: 234545,
    merchant_name: "KIM Tech",
    amount: 300,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "253256HHHD",
    client_name: "Karani John",
    client_id: 145667,
    client_id_number: 78565565,
    merchant_id: 741255,
    merchant_name: "KIM Tech",
    amount: 963,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "GNDHFFKJG89",
    client_name: "Karani John",
    client_id: 145667,
    client_id_number: 78565565,
    merchant_id: 741255,
    merchant_name: "KIM Tech",
    amount: 2500,
    created_at: "2022-05-10T12:59-0500",
  },
  {
    transaction_id: "253256HHHD",
    client_name: "Karani John",
    client_id: 145667,
    client_id_number: 78565565,
    merchant_id: 741255,
    merchant_name: "KIM Tech",
    amount: 9800,
    created_at: "2022-05-10T12:59-0500",
  },
];
