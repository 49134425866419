import React, { useEffect, useState } from "react";
import { openAddSideModal } from "../../redux/Index";
import { useDispatch } from "react-redux";
import { merchantTransactions, merchantsList } from "../../constants/data";
import CurrencyFormatter from "../global/CurrencyFormatter";
import DataTable from "../datatable/DataTable";
import Loader from "../global/Loader";
import DateTimeFormat from "../global/DateTimeFormat";
import SidePanel from "../global/SidePanel";
import HeaderNav from "../global/HeaderNav";
import Button from "../global/Button";
import CountryName from "../global/CountryName";
import CountyName from "../global/CountyName";
import StandardDate from "../global/StandardDate";
import { merchant_commissions } from "../../constants/Tariffs";
import SelectInput from "../global/SelectInput";

function MerchantsReports(props) {
  const dispatch = useDispatch();
  const headers = [
    "Merchant ID",
    "Merchant name",
    "Branch",
    "Country",
    "County/Province",
    "Contact person",
    "Email",
    "Date created",
    "View transactions",
  ];
  let tableRows = [];

  if (merchantsList?.length) {
    for (let i = 0; i < merchantsList?.length; i++) {
      tableRows.push({
        merchant_id: merchantsList[i].merchant_id,
        merchant_name: merchantsList[i].merchant_name,
        branch: merchantsList[i].branch,
        country: <CountryName id={merchantsList[i].country} />,
        county: <CountyName id={merchantsList[i].county} />,
        contact_person: merchantsList[i].contact_person,
        email: merchantsList[i].email,
        created_at: <StandardDate>{merchantsList[i].created_at}</StandardDate>,
        more: (
          <i
            className="bx bx-link-external links text-[16px] cursor-pointer"
            onClick={() => openMore(merchantsList[i])}
          ></i>
        ),
      });
    }
  }

  const [selectedClient, setSelectedClient] = useState();
  const [userTransactions, setUserTransactions] = useState();

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const selectedItem = (id) => {
    const transactions = merchantTransactions?.filter(
      (item) => item.merchant_id === id
    );
    setUserTransactions(transactions);
  };

  const openMore = (item) => {
    selectedItem(item.merchant_id);
    setSelectedClient(item.merchant_name);
    openSidePanel();
  };

  const displayContent = merchantsList ? (
    <DataTable headers={headers} data={tableRows} />
  ) : (
    <Loader />
  );

  // DISPLAY TRANSACTIONS PER MERCHANT
  const transactionsHeaders = [
    "Transaction ID",
    "Client",
    "Client ID",
    "Amount",
    "Commission",
    "Date",
    "Statement",
  ];
  let transactionsTableRows = [];
  let commission = 0;

  if (userTransactions?.length) {
    for (let i = 0; i < userTransactions?.length; i++) {
      let commissionEarned = merchant_commissions(userTransactions[i].amount);
      commission = commission += commissionEarned;
      transactionsTableRows.push({
        transaction_id: userTransactions[i].transaction_id,
        client_name: userTransactions[i].client_name,
        client_id: userTransactions[i].client_id_number,
        amount: <CurrencyFormatter value={userTransactions[i].amount} />,
        commission: <CurrencyFormatter value={commissionEarned} />,
        created_at: (
          <DateTimeFormat>{userTransactions[i].created_at}</DateTimeFormat>
        ),
        more: (
          <i
            className="bx bx-printer links text-[16px] cursor-pointer"
            onClick={() => printStatement(userTransactions[i])}
          ></i>
        ),
      });
    }
  }

  const printStatement = (id) => {
    console.log(id);
  };

  const exportPDF = () => {};
  const exportExcel = () => {};
  const exportTransactionsPDF = () => {};
  const exportTransactionsExcel = () => {};

  const [selectedFrequency, setSelectedFrequency] = useState(1);

  useEffect(() => {
    handleAction(selectedFrequency);
  }, [selectedFrequency]);

  let handleAction = (val) => {
    console.log(val);
  };

  const options = [
    { label: "Daily", value: 1 },
    { label: "Weekly", value: 2 },
    { label: "Monthly", value: 3 },
  ];

  const handleChange = (e) => {
    const val = e.target.value;
    setSelectedFrequency(val);
    handleAction(val);
  };

  return (
    <div>
      {/* header information */}
      <div className="flex flex-row sm:flex-col sm:space-y-2 justify-between items-center my-4">
        <HeaderNav title="Merchants report" />
        <div className="flex flex-row sm:flex-col sm:space-y-2 justify-end items-center">
          <Button
            onClick={exportExcel}
            text="Export Excel"
            bgColor={"bg-green-500"}
          />
          <Button
            onClick={exportPDF}
            text="Export PDF"
            bgColor={"bg-primary"}
          />
        </div>
      </div>
      {displayContent}
      <SidePanel
        width="75%"
        title={selectedClient}
        subtitle={`Transactions report`}
      >
        <div className="flex sm:flex-col justify-between items-center mb-2">
          <div className="w-40 sm:w-full mt-3">
            <SelectInput
              label="Select frequency"
              onChange={handleChange}
              options={options}
              name="switch_frequency"
              value={selectedFrequency}
            />
          </div>
          <div className="flex items-center justify-end">
            <Button onClick={exportTransactionsExcel} text={"Export Excel"} />
            <Button
              onClick={exportTransactionsPDF}
              text={"Export PDF"}
              bgColor={"bg-red-500"}
            />
          </div>
        </div>
        <DataTable headers={transactionsHeaders} data={transactionsTableRows} />
      </SidePanel>
    </div>
  );
}

export default MerchantsReports;
