import { counties } from "../../../src/constants/Countries";

const CountyName = ({ id }) => {
  return counties?.length
    ? counties?.find((item) => item.county_id === Number(id))
      ? counties?.find((item) => item.county_id === Number(id)).county_name
      : "Unknown"
    : "Not defined";
};

export default CountyName;
