import React, { useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import { bankList } from "../../constants/data";
import SidePanel from "../global/SidePanel";
import { useDispatch } from "react-redux";
import { openAddSideModal } from "../../redux/Index";
import Button from "../global/Button";
import TabsComponent from "../global/TabsComponent";
import HeaderNav from "../global/HeaderNav";
import FullDateFormat from "../global/FullDateFormat";
import CompletedTransactionsReport from "../banks/CompletedTransactionsReport";
import ReversedTransactionsReport from "../banks/ReversedTransactionsReport";
import CancelledTransactionsReport from "../banks/CancelledTransactionsReport";

function BanksReport() {
  const dispatch = useDispatch();
  const headers = [
    "Logo",
    "Bank name",
    "Paybill number",
    "Commission",
    "Date created",
    "Transactions",
  ];
  let tableRows = [];
  if (bankList?.length) {
    for (let i = 0; i < bankList?.length; i++) {
      tableRows.push({
        logo: (
          <img
            src={bankList[i].logo}
            className="w-10 h-10 rounded-full"
            alt="Uimage"
          />
        ),
        bank_name: bankList[i].bank_name,
        paybill_number: bankList[i].paybill_number,
        commission: bankList[i].commission,
        created_at: <FullDateFormat>{bankList[i].created_at}</FullDateFormat>,
        more: (
          <i
            className="bx bx-link-external links text-[16px] cursor-pointer"
            onClick={() => openMore(bankList[i])}
          ></i>
        ),
      });
    }
  }

  const [selectedBank, setSelectedBank] = useState();

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const openMore = (item) => {
    setSelectedBank(item.bank_name);
    openSidePanel();
  };

  const displayContent = bankList ? (
    !bankList?.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );

  // TRANSACTIONS DATA
  const tabs = [
    {
      name: "Completed",
      component: <CompletedTransactionsReport />,
    },
    {
      name: "Reversed",
      component: <ReversedTransactionsReport />,
    },
    {
      name: "Cancelled",
      component: <CancelledTransactionsReport />,
    },
  ];

  return (
    <div>
      {/* header information */}
      <div className="flex sm:flex-col justify-between items-center mb-2">
        <HeaderNav title="Banks report" />
        <div className="flex items-center justify-end">
          <Button text={"Export Excel"} />
          <Button text={"Export PDF"} bgColor={"bg-red-500"} />
        </div>
      </div>

      {displayContent}
      {/* display sidepanel */}
      <SidePanel
        width="75%"
        title={selectedBank}
        subtitle={`Transactions report`}
      >
        <TabsComponent tabsList={tabs} />
      </SidePanel>
    </div>
  );
}

export default BanksReport;
