import TransactionsClient from "../../api/TransactionsClient";
import {
  CREATE_WITHDRAWAL_CHARGE,
  INITIALIZE_WITHDRAWAL_CHARGES_STATE,
  SET_WITHDRAWAL_CHARGES,
  SET_WITHDRAWAL_CHARGES_ERROR_MESSAGE,
  SET_WITHDRAWAL_CHARGES_SUCCESS_MESSAGE,
  TOGGLE_CREATING_WITHDRAWAL_CHARGE,
  TOGGLE_FETCHING_WITHDRAWAL_CHARGES,
  TOGGLE_UPDATING_WITHDRAWAL_CHARGE,
  UPDATE_WITHDRAWAL_CHARGE,
} from "./actionTypes";

// set the actions
export const initializeWithdrawalChargesState = () => {
  return {
    type: INITIALIZE_WITHDRAWAL_CHARGES_STATE,
  };
};

export const toggleFetchingWithdrawalCharges = (status) => {
  return {
    type: TOGGLE_FETCHING_WITHDRAWAL_CHARGES,
    payload: status,
  };
};

export const toggleCreatingWithdrawalCharge = (status) => {
  return {
    type: TOGGLE_CREATING_WITHDRAWAL_CHARGE,
    payload: status,
  };
};

export const toggleUpdatingWithdrawalCharge = (status) => {
  return {
    type: TOGGLE_UPDATING_WITHDRAWAL_CHARGE,
    payload: status,
  };
};

export const setWithdrawalCharges = (data) => {
  return {
    type: SET_WITHDRAWAL_CHARGES,
    payload: data,
  };
};

export const createWithdrawalCharge = (data) => {
  return {
    type: CREATE_WITHDRAWAL_CHARGE,
    payload: data,
  };
};

export const updateWithdrawalCharge = (data) => {
  return {
    type: UPDATE_WITHDRAWAL_CHARGE,
    payload: data,
  };
};

export const setWithdrawalChargesuccessMessage = (msg) => {
  return {
    type: SET_WITHDRAWAL_CHARGES_SUCCESS_MESSAGE,
    payload: msg,
  };
};

export const setWithdrawalChargeErrorMessage = (error) => {
  return {
    type: SET_WITHDRAWAL_CHARGES_ERROR_MESSAGE,
    payload: error,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchWithdrawalChargesAction() {
  return function action(dispatch) {
    dispatch(initializeWithdrawalChargesState);
    dispatch(toggleFetchingWithdrawalCharges(true));

    return TransactionsClient.fetchWithdrawalCharges()
      .then((response) => {
        if (response.status === 200) {
          dispatch(setWithdrawalCharges(response.data.data));
          dispatch(toggleFetchingWithdrawalCharges(false));
        }
      })
      .catch((error) => {
        dispatch(toggleFetchingWithdrawalCharges(false));
      });
  };
}
export function createWithdrawalChargeAction(data) {
  return function action(dispatch) {
    return TransactionsClient.createWithdrawalCharge(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(createWithdrawalCharge(response.data.data));
        }
        return true;
      })
      .catch((error) => {
        return false;
      });
  };
}
export function updateWithdrawalChargeAction(data) {
  return function action(dispatch) {
    return TransactionsClient.updateWithdrawalCharge(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(updateWithdrawalCharge(response.data.data));
        }
        return true;
      })
      .catch((error) => {
        return false;
      });
  };
}
