import apiClient from "../ApiClient";
import AuthClient from "../../api/AuthClient";
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  SEND_OTP_FAILURE,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
} from "./authActionTypes";

// set the actions

// 1. login
export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

// 2. set and store the user info in case of success
export const loginSuccess = (userInfo) => {
  return {
    type: LOGIN_SUCCESS,
    payload: userInfo,
  };
};

// set the error message in case of an error
export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

// Registration of the user
export const signupRequest = () => {
  return {
    type: REGISTER_REQUEST,
  };
};

// incase the registration was successful
export const signupSuccess = (user) => {
  return {
    type: REGISTER_SUCCESS,
    payload: user,
  };
};

// incase the registration was unsuccessful
export const signupFailure = (error) => {
  return {
    type: REGISTER_FAILURE,
    payload: error,
  };
};

export const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};
// set and store the success message in case of logout success
export const logoutSuccess = (msg) => {
  return {
    type: LOGOUT_SUCCESS,
    payload: msg,
  };
};

// set and store the error message in case of logout failure
export const logoutFailure = (error) => {
  return {
    type: LOGOUT_FAILURE,
    payload: error,
  };
};

// send  OTP
export const otpRequest = () => {
  return {
    type: SEND_OTP_REQUEST,
  };
};
// set and store the success message in case of logout success
export const otpRequestSuccess = (msg) => {
  return {
    type: SEND_OTP_SUCCESS,
    payload: msg,
  };
};

// set and store the error message in case of logout failure
export const otpRequestFailure = (error) => {
  return {
    type: SEND_OTP_FAILURE,
    payload: error,
  };
};

// refresh token
export const refreshToken = () => {
  return {
    type: REFRESH_TOKEN,
  };
};

// ----------------------- send to reducer -----------------

// login using axios

export function login(data) {
  return function action(dispatch) {
    dispatch(loginRequest);

    return AuthClient.login(data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          dispatch(loginSuccess(response));
          return true;
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          dispatch(logoutSuccess("Failed to log you in. Retry."));
        }

        return false;
      });
  };
}

// export function logoutRequestAction() {
//   return function action(dispatch) {
//     dispatch(logoutRequest());

//     return AuthClient.logout()
//       .then((response) => {
//         if (response.status === 200) {
//           dispatch(logoutSuccess(response.message));
//           window.location.href = "/";
//         } else {
//           dispatch(logoutFailure(response.data.message));
//         }
//       })
//       .catch((error) => {
//         if (error.response.status === 401) {
//           dispatch(logoutFailure("Failed to log you out. Retry."));
//         }
//       });
//   };
// }
// logout function
export function logoutRequestAction() {
  return function action(dispatch) {
    dispatch(logoutRequest());
    return AuthClient.logout()
      .then((response) => {
        if (response.status < 205) {
          dispatch(logoutSuccess("Logged out successfully"));
          // initializeToken();
          return true;
        } else {
          dispatch(logoutFailure("Operation failed"));
          return false;
        }
      })
      .catch((error) => {
        // console.log(error.response);
        if (error.response.status === 400) {
          dispatch(loginFailure(error.response.data.message));
        } else if (error && error.response.status === 401) {
          // initializeToken();
          dispatch(loginFailure(error.response.data.detail));
        } else {
          dispatch(loginFailure(error.response.data.detail));
        }
        return false;
      });
  };
}

export function sendOtpAction() {
  return function action(dispatch) {
    dispatch(otpRequest());

    return apiClient
      .post("/api/sendOtp")
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(otpRequestSuccess(response.data.message));
          return true;
        } else {
          dispatch(otpRequestFailure(response.data.message));
          return false;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(otpRequestFailure("Something went wrong! Retry."));
        }
        return false;
      });
  };
}
