import React from "react";
import BrandHeader from "../components/global/BrandHeader";

function Privacy() {
  return (
    <div className="">
      <BrandHeader title="Privacy policy" />
      <div className="p-[11%] sm:px-4 sm:py-[40%] space-y-3">
        <p className="text-base text-justify my-2">
          When you sign up for PayGram Mobile Money you will be required to
          provide us with your name, email, gender, and birth date, among other
          information. In some cases we may ask for additional information for
          security reasons or to provide specific services to you.
        </p>

        {/* Information collection */}
        <p className="font-bold text-base text-justify my-2">
          Personal identifiable Information
        </p>
        <p className="text-base text-justify">
          You may change your personal identifiable information on PayGram
          Mobile Money at any time by contacting Customer Service or accessing
          your account profile online. Such information will be updated
          immediately.
        </p>

        {/* trademarks */}
        <p className="font-bold text-base text-justify my-2">
          Information we collect automatically from your device.
        </p>
        <p className="text-base text-justify">
          When you use PayGram Mobile Money from a computer, mobile phone, or
          other device, we may collect information from that device regarding
          your browser type, location, and IP address, as well as the pages you
          visit for security purposes.
        </p>
        <p className="text-base text-justify">
          When you use PayGram Mobile Money from a computer, mobile phone, or
          other device, we may collect information from that device regarding
          your browser type, location, and IP address, as well as the pages you
          visit for security purposes.
        </p>
        <p className="text-base text-justify">
          We may retain the details of transactions or payments you make via
          PayGram Mobile Money after the transaction is completed. This
          information will only be made available to third parties if required
          by law. However, we commit to keeping all your personal information,
          including your payment source details safe.
        </p>
        <p className="text-base text-justify">
          You agree that your information, including your personal information,
          your telephone conversations with our Customer Services and your
          transactions may be recorded and stored for record keeping purposes
          for up to seven (7) years from date of closure of account; or any
          other length of time as required by the law.
        </p>
        <p className="text-base text-justify">
          We use server firewalls and encryption to keep your account
          information safe during transmission and in storage. We also use
          automated and social measures to enhance security, such as analyzing
          account behavior for fraudulent or otherwise anomalous behavior. These
          may limit use of PayGram Mobile Money features in response to possible
          signs of abuse; and may suspend or disable accounts for violations of
          our customer terms and conditions.
        </p>
        <p className="text-base text-justify">
          Unless stated otherwise, our current privacy policy applies to all
          information that we have about you and your account. If we make
          changes to this Privacy Policy we shall accordingly notify customers.
        </p>
        <p className="text-base text-justify">
          We collect this information automatically as you navigate through and
          interact with our Site and Services. Usage Information includes:
        </p>
        <p className="text-base text-justify">
          (i) traffic data, general location data inferred from IP address,
          logs, pages visited, referring URL, length and frequency of your visit
          to the Site and use of the Services, and the resources that you access
          and use on the Site and Services; and
        </p>
        <p className="text-base text-justify">
          (ii) information about your computer and internet connection,
          including your IP address, device identifiers, operating system, and
          browser type.
        </p>
        <p className="text-base text-justify">
          The technologies we use for this automatic data collection of usage
          information may include:
        </p>
        <p className="text-base text-justify">
          Cookies (or browser cookies): A cookie is a small file placed on the
          hard drive of your computer. We use cookies to keep track of your
          login information. You may refuse to accept browser cookies by
          activating the appropriate setting on your browser. However, if you
          select this setting, you may be unable to access certain parts of our
          Site. Unless you have adjusted your browser setting so that it will
          refuse cookies, our system will issue cookies when you use the Site.
        </p>
        <p className="text-base text-justify">
          Device identifiers: When you use a mobile device like a tablet or
          phone to access our Site, we may access, collect, monitor, store on
          your device, and/or remotely store one or more “device identifiers.”
          Device identifiers are small data files or similar data structures
          stored on or associated with your mobile device, which uniquely
          identify your mobile device. A device identifier may be data stored in
          connection with the device hardware, data stored in connection with
          the device’s operating system or other software, or data sent to the
          device by us.
        </p>
        {/* other trademarks */}
        <p className="font-bold text-base text-justify my-2">
          How will we use the information you provide to us?
        </p>
        <p className="text-base text-justify">
          We and our service providers may use the information that we collect
          from or about you to:
        </p>
        <p className="text-base text-justify">
          Provide the Site and Services to you, including, but not limited to
          maintaining your profile page, supplementing your profile page with
          publicly available information,
        </p>
        <p className="text-base text-justify">
          Communicate with you, including by informing you of any relevant job
          postings that may be of interest to you based upon your expressed
          interests, to manage your participation in events, to notify you of
          changes made to the Site, to connect you with recruiters for various
          job opportunities, and to send you promotional materials that you
          request from us;
        </p>
        <p className="text-base text-justify">
          Analyze and improve the content, materials, and Services that we make
          available on the Site, and evaluate user needs;
        </p>
        <p className="text-base text-justify">
          Customize the Services, including by displaying information relevant
          to your expressed interests and preferences and generating
          recommendations for jobs that you may be interested in
        </p>
        <p className="text-base text-justify">
          Secure the Site and Services and protect against fraud in order to
          protect the rights, property, or safety of Built In, our customers, or
          others;
        </p>
        <p className="text-base text-justify">
          Comply with our legal obligations; and
        </p>
        <p className="text-base text-justify">
          For other legitimate and lawful business purposes.
        </p>
        {/* Info sharing */}
        <p className="font-bold text-base text-justify my-2">
          With whom do we share the information we collect?
        </p>
        <p className="text-base text-justify">
          We may disclose Aggregate/Deidentified Information about our users
          without restriction. We do not disclose your other information except
          in the circumstances described here:
        </p>
        <p className="text-base text-justify">To our service providers;</p>
        <p className="text-base text-justify">
          Service providers: We may disclose your information to service
          providers we use to support our business. We may share information
          with such service providers to allow them to perform services on our
          behalf, such as customer service, debugging and error resolution,
          analytics, and other services.
        </p>
        <p className="text-base text-justify">
          Business Transfers: In the event that Built In is involved in a
          merger, divestiture, restructuring, reorganization, dissolution, or
          other sale or transfer of some or all of our assets, whether as a
          going concern or as part of bankruptcy, liquidation, or similar
          proceeding, your information held by us may be among the assets
          transferred.
        </p>
        <p className="text-base text-justify">
          Legal purposes: Notwithstanding anything to the contrary in this
          Privacy Policy, we may disclose or share your information in order to
          comply with any court order, law, or legal process, including to
          respond to any government or regulatory request, or if we believe
          disclosure is necessary or appropriate to protect the rights,
          property, or safety of Built In, our customers, or others. This
          includes exchanging information with other companies and organizations
          for the purposes of fraud protection and credit risk reduction.
        </p>
        <p className="text-base text-justify">
          Enforcement: We may also use and disclose information about you to
          establish or exercise our legal rights, to enforce the Terms of Use,
          to assert and defend against legal claims, or if we believe such
          disclosure is necessary to investigate, prevent, or take other action
          regarding actual or suspected illegal or fraudulent activities or
          potential threats to the physical safety or well-being of any person.
        </p>
        <p className="text-base text-justify">
          Disclosed when Collected or With Your Consent: For any other purpose
          disclosed by us when you provide the information or otherwise with
          your consent.
        </p>
        {/* protecting information */}
        <p className="font-bold text-base text-justify my-2">
          How Do We Protect Information Collected About You?
        </p>
        <p className="text-base text-justify">
          PayGram and our Service providers take measures to protect against the
          loss, misuse and unauthorized access or disclosure, alteration and
          destruction of your personal data by aiming to maintain appropriate
          physical, procedural and electronic safeguards to protect your
          personal data, including:
        </p>
        <p className="text-base text-justify">
          • Restricting access to personal data to our employees and Service
          providers on a “need to know” basis;
        </p>
        <p className="text-base text-justify">
          • Enforcing policies and procedures for our employees in their
          handling of personal data; and;
        </p>
        <p className="text-base text-justify">
          • Using technologies designed to safeguard data during its
          transmission, such as SSL encryption for the data you provide on our
          websites.
        </p>
        <p className="text-base text-justify">
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Site, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone.
        </p>
        <p className="text-base text-justify">
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your Personal
          Information, we cannot guarantee the security of your Personal
          Information transmitted to our Site. Any transmission of Personal
          Information is at your own risk and we do not guarantee that
          information that you transmit or otherwise supply to us, or any
          communications conducted on or through the Site, is or will be totally
          secure. We are not responsible for circumvention of any privacy
          settings or security measures contained on the Site. If you become
          aware of any breach of Site security, this Privacy Policy, or the
          Terms of Use, please notify us immediately.
        </p>
        <p className="font-bold text-base text-justify my-2">
          Changes to the policy
        </p>
        <p className="text-base text-justify">
          From time to time, we may change our privacy practices, and this
          Privacy Policy, because of changes in relevant and applicable legal or
          regulatory requirements, in our business or our business practices, or
          in our attempts to better serve your needs and those of our other
          members. We will make the revised Privacy Policy available on this
          page, so you should review the Policy periodically. The date of the
          most recent revision to the policy is identified herein. If we make a
          material change to the Policy, we will provide you with appropriate
          notice in accordance with legal requirements. By continuing to access
          and use the Site, you are confirming that you have read an understood
          the latest version of this Policy.
        </p>
        <p className="font-bold text-base text-justify my-2">Acknowledgement</p>
        <p className="text-base text-justify">
          BY ACCESSING OR USING THE SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ,
          UNDERSTOOD, AND CONSENT TO OUR PRIVACY PRACTICES AND TO THE USES AND
          DISCLOSURES OF INFORMATION THAT WE COLLECT ABOUT YOU, AND YOU AGREE TO
          BE BOUND BY THE TERMS OF USE APPLICABLE TO YOU.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
