export const countries = [
  {
    id: 1,
    name: "Kenya",
    value: "ke",
    currency: "Kshs",
    abbrev: "KE",
    zipcode: "+254",
  },
  {
    id: 2,
    name: "Tanzania",
    value: "tz",
    currency: "Tshs",
    abbrev: "TZN",
    zipcode: "+255",
  },
  {
    id: 3,
    name: "Uganda",
    value: "ug",
    currency: "Ushs",
    abbrev: "UG",
    zipcode: "+256",
  },
];

export const counties = [
  // Kenya
  {
    county_id: 1,
    county_name: "Mombasa county",
    country: 1,
  },
  {
    county_id: 2,
    county_name: "Kwale county",
    country: 1,
  },
  {
    county_id: 3,
    county_name: "Kilifi county",
    country: 1,
  },
  {
    county_id: 4,
    county_name: "Taita/Taveta county",
    country: 1,
  },
  {
    county_id: 5,
    county_name: "Lamu county",
    country: 1,
  },
  {
    county_id: 6,
    county_name: "Tana River county",
    country: 1,
  },
  {
    county_id: 7,
    county_name: "Garissa county",
    country: 1,
  },
  {
    county_id: 8,
    county_name: "Mandera county",
    country: 1,
  },
  {
    county_id: 9,
    county_name: "Wajir county",
    country: 1,
  },
  {
    county_id: 10,
    county_name: "Marsabit county",
    country: 1,
  },
  {
    county_id: 11,
    county_name: "Isiolo county",
    country: 1,
  },
  {
    county_id: 12,
    county_name: "Meru county",
    country: 1,
  },
  {
    county_id: 13,
    county_name: "Tharaka-Nithi county",
    country: 1,
  },
  {
    county_id: 14,
    county_name: "Embu county",
    country: 1,
  },
  {
    county_id: 15,
    county_name: "Kitui county",
    country: 1,
  },
  {
    county_id: 16,
    county_name: "Machakos county",
    country: 1,
  },
  {
    county_id: 17,
    county_name: "Makueni county",
    country: 1,
  },
  {
    county_id: 18,
    county_name: "Nyandarua county",
    country: 1,
  },
  {
    county_id: 19,
    county_name: "Nyeri county",
    country: 1,
  },
  {
    county_id: 20,
    county_name: "Kirinyaga county",
    country: 1,
  },
  {
    county_id: 21,
    county_name: "Murang'a county",
    country: 1,
  },
  {
    county_id: 22,
    county_name: "Kiambu county",
    country: 1,
  },
  {
    county_id: 23,
    county_name: "Turkana county",
    country: 1,
  },
  {
    county_id: 24,
    county_name: "West Pokot county",
    country: 1,
  },
  {
    county_id: 25,
    county_name: "Samburu county",
    country: 1,
  },
  {
    county_id: 26,
    county_name: "Trans-Nzoia county",
    country: 1,
  },
  {
    county_id: 27,
    county_name: "Uasin Gishu county",
    country: 1,
  },
  {
    county_id: 28,
    county_name: "Elgeyo Marakwet county",
    country: 1,
  },
  {
    county_id: 29,
    county_name: "Nandi county",
    country: 1,
  },
  {
    county_id: 30,
    county_name: "Baringo county",
    country: 1,
  },
  {
    county_id: 31,
    county_name: "Laikipia county",
    country: 1,
  },
  {
    county_id: 32,
    county_name: "Nakuru county",
    country: 1,
  },
  {
    county_id: 33,
    county_name: "Narok county",
    country: 1,
  },
  {
    county_id: 34,
    county_name: "Kajiado county",
    country: 1,
  },
  {
    county_id: 35,
    county_name: "Kericho county",
    country: 1,
  },
  {
    county_id: 36,
    county_name: "Bomet county",
    country: 1,
  },
  {
    county_id: 37,
    county_name: "Kakamega county",
    country: 1,
  },
  {
    county_id: 38,
    county_name: "Vihiga county",
    country: 1,
  },
  {
    county_id: 39,
    county_name: "Bungoma county",
    country: 1,
  },
  {
    county_id: 40,
    county_name: "Busia county",
    country: 1,
  },
  {
    county_id: 41,
    county_name: "Siaya county",
    country: 1,
  },
  {
    county_id: 42,
    county_name: "Kisumu county",
    country: 1,
  },
  {
    county_id: 43,
    county_name: "Homa Bay county",
    country: 1,
  },
  {
    county_id: 44,
    county_name: "Migori county",
    country: 1,
  },
  {
    county_id: 45,
    county_name: "Kisii county",
    country: 1,
  },
  {
    county_id: 46,
    county_name: "Nyamira county",
    country: 1,
  },
  {
    county_id: 47,
    county_name: "Nairobi county",
    country: 1,
  },

  // Tanzania
  {
    county_id: 49,
    county_name: "Arusha region",
    country: 2,
  },
  {
    county_id: 49,
    county_name: "Dar es Salaam region",
    country: 2,
  },
  {
    county_id: 50,
    county_name: "Dodoma region",
    country: 2,
  },
  {
    county_id: 51,
    county_name: "Geita region",
    country: 2,
  },
  {
    county_id: 52,
    county_name: "Iringa region",
    country: 2,
  },
  {
    county_id: 53,
    county_name: "Kagera region",
    country: 2,
  },
  {
    county_id: 54,
    county_name: "Katavi region",
    country: 2,
  },
  {
    county_id: 55,
    county_name: "Kigoma region",
    country: 2,
  },
  {
    county_id: 56,
    county_name: "Kilimanjaro region",
    country: 2,
  },
  {
    county_id: 57,
    county_name: "Lindi region",
    country: 2,
  },
  {
    county_id: 58,
    county_name: "Manyara region",
    country: 2,
  },
  {
    county_id: 59,
    county_name: "Mara region",
    country: 2,
  },
  {
    county_id: 60,
    county_name: "Mbeya region",
    country: 2,
  },
  {
    county_id: 61,
    county_name: "Mjini Magharibi region",
    country: 2,
  },
  {
    county_id: 62,
    county_name: "Morogoro region",
    country: 2,
  },
  {
    county_id: 63,
    county_name: "Mtwara region",
    country: 2,
  },
  {
    county_id: 64,
    county_name: "Mwanza region",
    country: 2,
  },
  {
    county_id: 65,
    county_name: "Arusha region",
    country: 2,
  },
  {
    county_id: 66,
    county_name: "Njombe region",
    country: 2,
  },
  {
    county_id: 67,
    county_name: "Pemba North region",
    country: 2,
  },
  {
    county_id: 68,
    county_name: "Pemba South region",
    country: 2,
  },
  {
    county_id: 69,
    county_name: "Pwani region",
    country: 2,
  },
  {
    county_id: 70,
    county_name: "Rukwa region",
    country: 2,
  },
  {
    county_id: 71,
    county_name: "Ruvuma region",
    country: 2,
  },
  {
    county_id: 72,
    county_name: "Shinyanga region",
    country: 2,
  },
  {
    county_id: 73,
    county_name: "Simiyu region",
    country: 2,
  },
  {
    county_id: 74,
    county_name: "Singida region",
    country: 2,
  },
  {
    county_id: 75,
    county_name: "Songwe region",
    country: 2,
  },
  {
    county_id: 76,
    county_name: "Tabora region",
    country: 2,
  },
  {
    county_id: 77,
    county_name: "Tanga region",
    country: 2,
  },
  {
    county_id: 78,
    county_name: "Unguja North region",
    country: 2,
  },

  // Uganda
  {
    county_id: 79,
    county_name: "Northern region",
    country: 3,
  },
  {
    county_id: 80,
    county_name: "Eastern region",
    country: 3,
  },
  {
    county_id: 81,
    county_name: "Western region",
    country: 3,
  },
  {
    county_id: 82,
    county_name: "Central region",
    country: 3,
  },
];
