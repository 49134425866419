/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../components/global/Loader";
import CurrencyFormatter from "../components/global/CurrencyFormatter";
import DateTimeFormat from "../components/global/DateTimeFormat";
import { useDispatch, useSelector } from "react-redux";
import HeaderText from "../components/global/HeaderText";
import { fetchTransactionsAction } from "../redux/Index";
import ApiDataTable from "../components/datatable/ApiDataTable";

function Transactions(props) {
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState([]);
  const transactionsState = useSelector((state) => state?.transactions);
  useEffect(() => {
    getTransactions(10, 1);
  }, []);

  const getTransactions = (rowsPerPage, page) => {
    dispatch(fetchTransactionsAction(rowsPerPage, page));
  };

  useEffect(() => {
    setTransactions(transactionsState?.transactions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsState?.transactions]);

  const headers = [
    "Client",
    "Account number",
    "Transaction type",
    "Credit",
    "Debit",
    "Transaction date",
  ];
  let tableRows = [];
  const list = transactions?.data;

  if (list?.length) {
    for (let i = 0; i < list?.length; i++) {
      const element = list[i];
      tableRows.push({
        client: element?.client_full_name,
        account_number: element?.client_phone_number,
        type: element?.type_display,
        cr_amount: <CurrencyFormatter value={element?.cr_amount ?? 0} />,
        dr_amount: <CurrencyFormatter value={element?.dr_amount ?? 0} />,
        created_at: <DateTimeFormat>{element?.created_at}</DateTimeFormat>,
      });
    }
  }

  const displayContent = !transactionsState?.fetchingTransactions ? (
    <ApiDataTable
      headers={headers}
      data={tableRows}
      loadDataAction={getTransactions}
      totalPageCount={transactions?.last_page}
    />
  ) : (
    <Loader />
  );

  return (
    <div>
      {/* header information */}
      <HeaderText title="Transactions" />
      {displayContent}
    </div>
  );
}

export default Transactions;
