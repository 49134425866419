export const withdrawal_commissions_registered = (amount) => {
  let commission;
  switch (true) {
    case amount >= 1 && amount <= 50:
      commission = 1;
      break;

    case amount >= 51 && amount <= 100:
      commission = 3;
      break;

    case amount >= 101 && amount <= 500:
      commission = 3;
      break;

    case amount >= 501 && amount <= 1000:
      commission = 8;
      break;

    case amount >= 1001 && amount <= 1500:
      commission = 10;
      break;

    case amount >= 1501 && amount <= 2500:
      commission = 10;
      break;

    case amount >= 2501 && amount <= 3500:
      commission = 15;
      break;

    case amount >= 3501 && amount <= 5000:
      commission = 15;
      break;

    case amount >= 5001 && amount <= 7500:
      commission = 20;
      break;

    case amount >= 7501 && amount <= 10000:
      commission = 27;
      break;

    case amount >= 10001 && amount <= 12500:
      commission = 30;
      break;
    case amount >= 12501 && amount <= 15000:
      commission = 35;
      break;
    case amount >= 15001 && amount <= 20000:
      commission = 40;
      break;
    case amount >= 20001 && amount <= 25000:
      commission = 45;
      break;
    case amount >= 25001 && amount <= 30000:
      commission = 45;
      break;
    case amount >= 30001 && amount <= 35000:
      commission = 45;
      break;
    case amount >= 35001 && amount <= 40000:
      commission = 45;
      break;
    case amount >= 40001 && amount <= 70000:
      commission = 45;
      break;

    default:
      commission = 0;
      break;
  }

  return commission;
};

export const withdrawal_commissions_unregistered = (amount) => {
  let commission;
  switch (true) {
    case amount >= 1 && amount <= 50:
      commission = 1;
      break;

    case amount >= 51 && amount <= 100:
      commission = 3;
      break;

    case amount >= 101 && amount <= 500:
      commission = 5;
      break;

    case amount >= 501 && amount <= 1000:
      commission = 8;
      break;

    case amount >= 1001 && amount <= 1500:
      commission = 8;
      break;

    case amount >= 1501 && amount <= 2500:
      commission = 10;
      break;

    case amount >= 2501 && amount <= 3500:
      commission = 15;
      break;

    case amount >= 3501 && amount <= 5000:
      commission = 15;
      break;

    case amount >= 5001 && amount <= 7500:
      commission = 20;
      break;

    case amount >= 7501 && amount <= 10000:
      commission = 27;
      break;

    case amount >= 10001 && amount <= 12500:
      commission = 30;
      break;
    case amount >= 12501 && amount <= 15000:
      commission = 35;
      break;
    case amount >= 15001 && amount <= 20000:
      commission = 40;
      break;
    case amount >= 20001 && amount <= 25000:
      commission = 45;
      break;
    case amount >= 25001 && amount <= 30000:
      commission = 45;
      break;
    case amount >= 30001 && amount <= 35000:
      commission = 45;
      break;
    case amount >= 35001 && amount <= 40000:
      commission = 45;
      break;
    case amount >= 40001 && amount <= 70000:
      commission = 45;
      break;

    default:
      commission = 0;
      break;
  }

  return commission;
};

export const deposit_commissions = (amount) => {
  let commission;
  switch (true) {
    case amount >= 1 && amount <= 50:
      commission = 1;
      break;

    case amount >= 51 && amount <= 100:
      commission = 3;
      break;

    case amount >= 101 && amount <= 500:
      commission = 3;
      break;

    case amount >= 501 && amount <= 1000:
      commission = 8;
      break;

    case amount >= 1001 && amount <= 1500:
      commission = 10;
      break;

    case amount >= 1501 && amount <= 2500:
      commission = 10;
      break;

    case amount >= 2501 && amount <= 3500:
      commission = 15;
      break;

    case amount >= 3501 && amount <= 5000:
      commission = 15;
      break;

    case amount >= 5001 && amount <= 7500:
      commission = 20;
      break;

    case amount >= 7501 && amount <= 10000:
      commission = 27;
      break;

    case amount >= 10001 && amount <= 12500:
      commission = 30;
      break;
    case amount >= 12501 && amount <= 15000:
      commission = 35;
      break;
    case amount >= 15001 && amount <= 20000:
      commission = 40;
      break;
    case amount >= 20001 && amount <= 25000:
      commission = 45;
      break;
    case amount >= 25001 && amount <= 30000:
      commission = 45;
      break;
    case amount >= 30001 && amount <= 35000:
      commission = 45;
      break;
    case amount >= 35001 && amount <= 40000:
      commission = 45;
      break;
    case amount >= 40001 && amount <= 70000:
      commission = 45;
      break;

    default:
      commission = 0;
      break;
  }

  return commission;
};

export const merchant_commissions = (amount) => {
  let commission;
  switch (true) {
    case amount >= 1 && amount <= 50:
      commission = 1;
      break;

    case amount >= 51 && amount <= 100:
      commission = 3;
      break;

    case amount >= 101 && amount <= 500:
      commission = 3;
      break;

    case amount >= 501 && amount <= 1000:
      commission = 8;
      break;

    case amount >= 1001 && amount <= 1500:
      commission = 10;
      break;

    case amount >= 1501 && amount <= 2500:
      commission = 10;
      break;

    case amount >= 2501 && amount <= 3500:
      commission = 15;
      break;

    case amount >= 3501 && amount <= 5000:
      commission = 15;
      break;

    case amount >= 5001 && amount <= 7500:
      commission = 20;
      break;

    case amount >= 7501 && amount <= 10000:
      commission = 27;
      break;

    case amount >= 10001 && amount <= 12500:
      commission = 30;
      break;
    case amount >= 12501 && amount <= 15000:
      commission = 35;
      break;
    case amount >= 15001 && amount <= 20000:
      commission = 40;
      break;
    case amount >= 20001 && amount <= 25000:
      commission = 45;
      break;
    case amount >= 25001 && amount <= 30000:
      commission = 45;
      break;
    case amount >= 30001 && amount <= 35000:
      commission = 45;
      break;
    case amount >= 35001 && amount <= 40000:
      commission = 45;
      break;
    case amount >= 40001 && amount <= 70000:
      commission = 45;
      break;

    default:
      commission = 0;
      break;
  }

  return commission;
};
