import {
  INITIALIZE_CLIENTS_STATE,
  SET_CLIENTS,
  SET_CLIENT_ERROR_MESSAGE,
  SET_CLIENT_SUCCESS_MESSAGE,
  SET_SAVINGS,
  SET_SELECTED_CLIENT,
  TOGGLE_FETCHING_CLIENTS,
  TOGGLE_FETCHING_SAVINGS,
  TOGGLE_UPDATING_CLIENT,
  UPDATE_CLIENT,
  UPDATE_USER_PROFILE_PIC,
} from "./actionTypes";

// set the initial state
const initialState = () => {
  return {
    success: "",
    error: "",
    fetchingClients: false,
    fetchingSavings: false,
    updatingClient: false,
    clients: [],
    savings: [],
    selectedClient: "",
  };
};

// set the reducer
const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_CLIENTS_STATE:
      return {
        ...state,
        success: "",
        error: "",
      };
    /* falls through */
    case TOGGLE_FETCHING_CLIENTS:
      return {
        ...state,
        fetchingClients: action.payload,
      };
    /* falls through */
    case TOGGLE_FETCHING_SAVINGS:
      return {
        ...state,
        fetchingSavings: action.payload,
      };
    /* falls through */
    case TOGGLE_UPDATING_CLIENT:
      return {
        ...state,
        updatingClient: action.payload,
      };
    /* falls through */
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };
    /* falls through */
    case UPDATE_USER_PROFILE_PIC:
      let newClientList = state.clients?.data ? [...state.clients?.data] : [];
      let clIndex = newClientList?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      if (clIndex > -1) newClientList[clIndex].file = action.payload?.file;
      return {
        ...state,
        clients: newClientList,
      };
    /* falls through */
    case SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.payload,
      };
    /* falls through */
    case SET_SAVINGS:
      return {
        ...state,
        savings: action.payload,
      };
    /* falls through */
    case UPDATE_CLIENT:
      let newClients = [...state.clients];
      let foundIndex = newClients?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      newClients[foundIndex] = action.payload;
      return {
        ...state,
        clients: newClients,
        processSet: true,
      };
    /* falls through */

    case SET_CLIENT_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.payload,
      };
    /* falls through */
    case SET_CLIENT_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default clientsReducer;
