import React from "react";
import BrandHeader from "../components/global/BrandHeader";

function Terms() {
  return (
    <div className="">
      <BrandHeader title="Terms & Conditions" />
      <div className="p-[11%] sm:px-4 sm:py-[40%] space-y-3">
        <p className="text-base text-justify my-2">
          On this screen, you will find a standard version of the PayGram Terms
          and Conditions. Please note that different terms and conditions may
          apply in certain regions.
        </p>

        <p className="text-base text-justify my-2">
          You are required to read and understand the rules in this agreement
          stated below, as they are binding between you and PayGram Company
          Limited (“PayGram”), being the providers of PayGram Mobile Money.
        </p>

        <p className="text-base text-justify my-2">
          The Terms and Conditions of use of the PayGram are as follows:
        </p>

        {/* The agreement */}
        <p className="font-bold text-base text-justify my-2">The agreement</p>
        <p className="text-base text-justify">
          I (“Customer”) confirm and agree that the following terms and
          conditions shall govern my PayGram Mobile Money wallet with the
          organization. When you open a Mobile Money Wallet you must know the
          terms and conditions („‟rules”) for using your wallet. You need to
          understand each clause of the rules set out in this document. You are
          required to keep these rules as they are a binding agreement between
          you and PayGram Mobile Money.
        </p>

        {/* copyright */}
        <p className="font-bold text-base text-justify my-2">Copyright</p>
        <p className="text-base text-justify">
          The copyright in this publication is owned by PayGram Company Limited.
        </p>

        {/* trademarks */}
        <p className="font-bold text-base text-justify my-2">
          PayGram Company Limited trademarks
        </p>
        <p className="text-base text-justify">
          No license to use any of these trademarks is given or implied. These
          trademarks may not be copied, downloaded, reproduced, used, modified
          or distributed in any way (except as an integral part of an authorized
          copy of material appearing in these web pages, as set forth in the
          previous section paragraph) without prior written permission.
        </p>

        {/* other trademarks */}
        <p className="font-bold text-base text-justify my-2">
          Other trademarks
        </p>
        <p className="text-base text-justify">
          All other trademarks or trade names referred to in these materials are
          the property of their respective owners.
        </p>

        {/* interactive features */}
        <p className="font-bold text-base text-justify my-2">
          Use of interactive features on this app
        </p>
        <p className="text-base text-justify">
          For your convenience, PayGram may provide interactive features on this
          app, such as access to tracking and user comments. You are authorized
          to use these features solely for the purposes specified and for no
          other purposes.
        </p>

        {/* disclosure of information */}
        <p className="font-bold text-base text-justify my-2">Acceptance</p>
        <p className="text-base text-justify">
          The Customer is deemed to have accepted these Terms and Conditions of
          Use, and as may be amended from time to time and which take effect
          upon Registration of their virtual account (Wallet).
        </p>

        {/* disclosure of information */}
        <p className="font-bold text-base text-justify my-2">Definitions</p>
        <p className="text-base text-justify">
          “Agent” refers to any party or device, including authorized PayGram
          Agents, Merchants, connected kiosks and bank tellers that facilitate
          PayGram transactions on behalf of PayGram.
        </p>
        <p className="text-base text-justify my-1">
          “Content” means all information whether textual, visual, audio or
          otherwise, appearing on or available through the services.
        </p>
        <p className="text-base text-justify my-2">
          “Credit” means the movement of funds into an account either via
          cash-in or transfer
        </p>
        <p className="text-base text-justify my-2">
          “Customers” or “Users” means persons that have or will have access to
          a selection of services, whether registered or unregistered.
        </p>

        <p className="text-base text-justify my-2">
          “Debit” means the movement of funds out of an account either via
          cash-out or transfer.
        </p>

        <p className="text-base text-justify my-2">
          “IVR” stands for Interactive Voice Response system, an automated phone
          line that allows a computer to recognize voice and keypad inputs.
        </p>

        <p className="text-base text-justify my-2">
          “Linked bank account” refers to the bank account that you have
          interfaced or linked to your PayGram Mobile Money account. This allows
          you to carry out transactions using funds from your bank account
          instead of using your PayGram account.
        </p>

        <p className="text-base text-justify my-2">
          “Merchant” means a seller of goods and or services who will accept
          PayGram Mobile Money as payment for goods and or services.
        </p>

        <p className="text-base text-justify my-2">
          “Mobile payments” describes the service or process that allows
          customers to make and receive a variety of payments using their mobile
          phone, among other channels. This service is available as a part of
          PayGram Mobile Money product offerings.
        </p>

        <p className="text-base text-justify my-2">
          “Mobile phone” means a device which can make and receive telephone
          calls and send and receive SMS, among other communication options.
        </p>

        <p className="text-base text-justify my-2">
          “Mobile wallet” or “wallet” is otherwise known as the PayGram Mobile
          Money customer account; is an electronic stored value account on
          PayGram Mobile Money created for use by the Customer or Agent.
          Credits, debits and charges are applied to this account. The account
          is primarily accessed through your mobile phone.
        </p>

        <p className="text-base text-justify my-2">
          “Money laundering” means all financial crimes relating to money
          laundering and the Combating the Funding of Terrorism (CFT), including
          suspicions of fraud under the Anti-Money Laundering laws in Kenya.
        </p>

        <p className="text-base text-justify my-2">
          “PayGram Mobile Money” refers to the financial services products
          offered by PayGram Company Limited, with a central feature being (a)
          transactional account(s) that may be used in relation to a wide
          variety of services.
        </p>

        <p className="text-base text-justify my-2">
          “PayGram” refers to PayGram Company Limited, mobile money transactions
          Platform Company duly incorporated and registered under the laws of
          Kenya.
        </p>

        <p className="text-base text-justify my-2">
          “PIN” means personal identification number being the secret numeric
          passcode you choose for secure use of (and access to) your PayGram
          Mobile Money account.
        </p>

        <p className="text-base text-justify my-2">
          “POS” means Point Of Sale being a location where a transaction occurs.
        </p>

        <p className="text-base text-justify my-2">
          “Products” refer to PayGram Mobile Money products, including but not
          limited to person-to-person money transfer, bill payments, airtime
          top-up and PayGram retail payments services.
        </p>

        <p className="text-base text-justify my-2">
          “Registered phone number” refers to any phone number that has been
          registered on PayGram Mobile Money.
        </p>

        <p className="text-base text-justify my-2">
          “Services” any products and services provided to the customer as part
          of PayGram Mobile Money.
        </p>

        <p className="text-base text-justify my-2">
          “SMS” or “Short Message Service” is a standard communication service
          on mobile phones which is used to exchange short text messages between
          mobile devices.
        </p>

        <p className="text-base text-justify my-2">
          “USSD” or “Unstructured Supplementary Service Data” is a real time
          messaging channel accessed from a (GSM-based) mobile phone and allows
          the user to interact with PayGram Mobile Money.
        </p>

        <p className="text-base text-justify my-2">
          “Website” means the PayGram Mobile Money official web portal or its
          associated (WAP) site.
        </p>

        {/* disclosure of information */}
        <p className="font-bold text-base text-justify my-2">
          2. PayGram Mobile Money Service
        </p>
        <p className="text-base text-justify my-2">
          2.1. Mobile Money enables users or customers to do money transfers,
          deposit and withdraw money and top up airtime from their phone or from
          authorized Agents and Bank.
        </p>
        <p className="text-base text-justify my-2">
          2.2 Customer or User will be informed from time to time, of any
          additional features that may be offered on PayGram Mobile Money, and
          where necessary, the applicable means or requirements to activate any
          such features.
        </p>

        {/* disclosure of information */}
        <p className="font-bold text-base text-justify my-2">
          3. Application for Wallet
        </p>
        <p className="text-base text-justify my-2">
          3.1. To open a PayGram Mobile Money Wallet, an individual would have
          to be 18 years and above. However persons below the age of 18 (minors)
          can open a wallet subject to certain restrictions: they are only
          eligible for opening a Tier 1 wallet.
        </p>
        <p className="text-base text-justify my-2">
          3.2. We require your personal details and certain information before a
          decision is made on your application. You must provide complete and
          accurate information.
        </p>
        <p className="text-base text-justify my-2">
          3.3. We may decline your application at our sole discretion.
        </p>
        <p className="text-base text-justify my-2">
          3.4. We will verify your Identity and may refuse to open a Wallet for
          you if we are not satisfied with proof of your identify.
        </p>

        {/* disclosure of information */}
        <p className="font-bold text-base text-justify my-2">
          4. Registration Process
        </p>
        <p className="text-base text-justify my-2">
          4.1. In order to register for the PayGram Mobile Money services you
          have to be a subscriber, from a licensed Mobile network operator in
          Kenya.
        </p>
        <p className="text-base text-justify my-2">
          4.2. Every PayGram Mobile Money Wallet is linked to one Mobile Number
          and that Mobile number represents the Wallet number.
        </p>
        <p className="text-base text-justify my-2">
          4.3 In order to receive or gain access to the PayGram Mobile Money
          services, you may be required to go to any authorized Agent within the
          Republic of Kenya.
        </p>
        <p className="text-base text-justify my-2">
          4.4. You will be required to fill out and sign the individual Account
          Registration e-Form. It should be noted that, when making an on-line
          registration, signifying acceptance of the Terms and Conditions has
          the same effect as a written signature on an account registration
          form. Personal Information and, certain documentation (if applicable)
          will be required from you depending on the category of wallet being
          applied for, by you. Please refer to clause [6.10] for a list of
          required documents.
        </p>
        <p className="text-base text-justify my-2">
          4.5. Upon registering for PayGram Mobile Money, a PIN will be
          generated for your wallet, which should be kept secret by you and not
          disclosed to any third-party, including PayGram Mobile Money staff or
          Agents. PayGram will not accept responsibility for any issues linked
          to customer violations of PIN protocols. Please refer to clause 9
          below.
        </p>
        <p className="text-base text-justify my-2">
          4.6. Upon Registration, PayGram Mobile Money will require that you
          change your default PIN before your mobile wallet becomes active for
          transaction.
        </p>

        {/* disclosure of information */}
        <p className="font-bold text-base text-justify my-2">
          5. Fees and other Charges
        </p>
        <p className="text-base text-justify my-2">
          5.1. While some transactions on PayGram Mobile Money are free to you,
          other transactions do carry a fee. Our PayGram Mobile Money fee
          schedules are available on our website; at all our partner bank
          branches; authorized PayGram Mobile Money agent locations; field
          offices; through communication with our Customer Services or through
          some communication or customer education materials. The fee schedule
          provides details on the fee amounts, and the method and source of
          payment for such fees. We reserve the right to change/modify the fee
          structure if and when the need arises. You will be notified of such
          fee modifications by way of advertisements, SMS, through our Website,
          or by using any other suitable means. Continuation of the service
          usage after such notification will be perceived by us as acceptance of
          the said modification.
        </p>
        <p className="text-base text-justify my-2">
          5.2. When you initiate and confirm a transaction on PayGram Mobile
          Money, you agree to be bound by and pay for that transaction. Do not
          commit to a transaction unless you are ready to pay and have checked
          that all provided information is accurate as all completed
          transactions are final.
        </p>
        <p className="text-base text-justify my-2">
          5.3. PayGram Mobile Money is designed to make payments convenient, so
          we allow you to make payments using a number of different payment
          sources e.g. your mobile wallet, your linked bank accounts and/or bank
          cards. When you provide us with a payment source, you also authorize:
        </p>
        <p className="text-base text-justify my-2">
          5.3.1 The collection and storing of source information along with
          other related transaction information.
        </p>
        <p className="text-base text-justify my-2">
          5.3.2 The crediting and debiting of your chosen source when you
          perform transactions on PayGram Mobile Money.
        </p>
        <p className="text-base text-justify my-2">
          5.4. To prevent financial loss or possible violations of the law,
          PayGram reserves the right to use its discretion in disclosing details
          of any payments associated with you with payment source issuers, law
          enforcement agencies, or impacted third parties (including other
          users). Such disclosures may originate from an order of any trial
          court with the jurisdiction to compel such a disclosure.
        </p>
        <p className="text-base text-justify my-2">
          5.5. You agree and confirm that you will not use this Mobile payment
          facility for money laundering or violate any law related to money
          laundering.
        </p>
        <p className="text-base text-justify my-2">
          5.6. PayGram reserves the right to demand (an) explanation(s) from you
          regarding any matter pertaining to money laundering law(s) of Kenya.
        </p>
        <p className="text-base text-justify my-2">
          5.7. PayGram Mobile Money may terminate the relationship at any time
          without notification if, in its sole judgment, the company‟s
          reputation and, or integrity is affected in an adverse manner by
          actions by you.
        </p>
        <p className="text-base text-justify my-2">
          5.8. Normal SMS charges or other charges for services (if applicable)
          by the Mobile operators may be applied for each SMS sent to PayGram
          Mobile Money or for the other services. These charges are in addition
          to any applicable stipulated fees charged by PayGram.
        </p>
        <p className="text-base text-justify my-2">
          5.9. PayGram reserves the right to change the service charges, as may
          be fixed from time to time. You hereby authorize PayGram Mobile Money
          to debit your wallet account(s) with such charges.
        </p>
        <p className="text-base text-justify my-2">
          5.10. Where there is insufficient e-value in your wallet to cover the
          applicable fees for a transaction that you initiate, that transaction
          may be invalidated.
        </p>

        {/* disclosure of information */}
        <p className="font-bold text-base text-justify my-2">Transacting</p>
        <p className="text-base text-justify my-2">
          6.1. To manage your money safely there are transactional and daily
          limits on your Wallet. You can increase or reduce these limits by
          contacting our Call Center subject to the restrictions placed on the
          wallet account shown in the table in clause 6.10 below.
        </p>
        <p className="text-base text-justify my-2">
          6.2. All payments will be processed in the Kenyan currency (Ksh).
        </p>
        <p className="text-base text-justify my-2">
          6.3. You may access the available funds in your Wallet at any time
          using your mobile phones or the internet. While we will make every
          reasonable attempt to provide the services in accordance with our
          marketing and educational materials, we provide the Services “as is”
          and without any warranties.
        </p>
        <p className="text-base text-justify my-2">
          6.4. Each transaction will be identified by a Transaction ID which is
          used to track and identify all transactions carried out on the PayGram
          Mobile Money network. This number is important for a variety of uses,
          including dispute resolution.
        </p>
        <p className="text-base text-justify my-2">
          6.5 PayGram Mobile Money will send you a message to verify and confirm
          all transactions effected from your PayGram Mobile Money account or
          performed at an agent outlet; this message may be sent via SMS, email,
          or another available channel.
        </p>
        <p className="text-base text-justify my-2">
          6.6 You must authorize your transactions with your secret PIN, which
          you create when you register, or by such other method as we may
          prescribe from time to time.
        </p>
        <p className="text-base text-justify my-2">
          6.7 Any use of or transactions concluded through PayGram Mobile Money
          are subject to these terms and conditions as may be amended by us from
          time to time.
        </p>
        <p className="text-base text-justify my-2">
          6.8 We may verify and confirm any record of a deposit in your wallet.
          Our records will be taken as correct unless the contrary is proven.
        </p>
        <p className="text-base text-justify my-2">
          6.10. The table below outlines the customer classifications PayGram
          Mobile Money adopts along KYC levels, together with applicable
          transaction limits. You may not withdraw, transfer or make any
          payments that together exceed any of the per transaction or daily
          limits defined. Should you attempt to exceed any applicable limits,
          your transactions will be declined. PayGram Mobile Money may, acting
          reasonably and in accordance with regulations set by the Central Bank
          of Kenya, vary the limits at any time and any variation will be
          notified to customers.
        </p>

        {/* disclosure of information */}
        <p className="font-bold text-base text-justify my-2">
          7. Merchant Transactions
        </p>
        <p className="text-base text-justify my-2">
          7.1. When making a payment to a merchant on PayGram Mobile Money, you
          will be required to confirm the transaction. At this time you may also
          be requested to enter your PIN via USSD, secure website, POS Terminal
          or IVR to accept the transaction. By entering your PIN to confirm the
          transaction, you agree and confirm that you authorize the transaction.
          You cannot directly reverse or cancel any payment once it has been
          approved by you.
        </p>
        <p className="text-base text-justify my-2">
          7.2. As we are unable to reverse or charge-back any payments made,
          should you have a dispute with any merchant, you should resolve such
          disputes with the merchant directly.
        </p>
        <p className="text-base text-justify my-2">
          7.3. Disputes between you and a merchant will not affect our right to
          recover payments from you.
        </p>

        {/* disclosure of information */}
        <p className="font-bold text-base text-justify my-2">
          8. PayGram Mobile Money Online Services
        </p>
        <p className="text-base text-justify my-2">
          8.1. You are responsible for protecting your Mobile phone, devices or
          computer against computer viruses when you use the Internet to access
          our PayGram Mobile Money website and mobile wallet facilities; or
          conduct Merchant transactions. We are not liable for any computer
          program or code that may originate from your systems and you indemnify
          us against any claims made in this regard.
        </p>
        <p className="text-base text-justify my-2">
          8.2. Online payments are usually conducted on secure websites and
          customer diligence is required. You hereby indemnify PayGram from
          liability on transactions that you might conduct without properly
          reviewing the security of any website that you make payments on.
        </p>

        {/* disclosure of information */}
        <p className="font-bold text-base text-justify my-2">
          9. Security amd Unauthorised Use
        </p>
        <p className="text-base text-justify my-2">
          9.1. Depending on the service type, you may be provided with a
          temporary PIN during registration for the service in the first
          instance and will be asked to change the PIN before subsequent
          transactions. This PIN is mandatory for the use of all PayGram Mobile
          Money features in such a manner that no transaction could be affected
          without entering and validating this PIN. You have three [3] attempts
          to enter the right PIN;. If you enter the wrong PIN on your third
          attempt the Mobile Money wallet will be disabled.
        </p>
        <p className="text-base text-justify my-2">
          9.1.1 As a safety measure, you, as a customer should immediately
          change PIN upon receipt of initial default PIN. You are required to
          change your PIN frequently thereafter as much as possible.
        </p>
        <p className="text-base text-justify my-2">
          9.1.2 You acknowledge that the PIN selected should act as your
          authorized signature. This signature authorizes and validates
          instructions given just as an actual written signature does.
        </p>
        <p className="text-base text-justify my-2">
          9.1.3 You are responsible for maintaining the confidentiality of your
          PIN.
        </p>
        <p className="text-base text-justify my-2">
          9.1.4 You agree that you will not under any circumstances disclose the
          PIN to anyone, including any Agent or employee of PayGram or anyone
          claiming to represent PayGram or to someone giving assistance on a
          technical helpdesk in connection with the service. It should be
          clearly understood that PayGram employees do not need your PIN for any
          reason whatsoever.
        </p>
        <p className="text-base text-justify my-2">
          9.1.5 You should ensure that no one is physically watching your PIN
          when inputting it on the Mobile phone. The PIN should not be written
          anywhere.
        </p>
        <p className="text-base text-justify my-2">
          9.1.6 PayGram shall not be held responsible for your failure to
          safeguard the secrecy of the PIN or be held liable if you allow anyone
          to have access to the PIN thereby compromising your account(s). By
          allowing anyone to have access to your PIN, you are doing so at your
          own risk.
        </p>
        <p className="text-base text-justify my-2">
          9.1.7 If you forget your PIN, you must request for issue of a new PIN
          by calling the PayGram Call Center.
        </p>
        <p className="text-base text-justify my-2">
          9.1.8 You agree that PayGram would not be held liable for any loss
          incurred by information disclosed/exchanged when carrying out an
          instruction from you in respect of transactions between your wallet
          and linked
        </p>
        <p className="text-base text-justify my-2">
          9.2. Should you dispute that any purchase or withdrawal debited to
          your Wallet was authorized by you, you will have to prove it was not
          authorized.
        </p>
        <p className="text-base text-justify my-2">
          9.3. You are responsible for protecting your Mobile Phone, devices or
          computer against computer viruses and other malware when you use the
          internet to access our PayGram Mobile Money Web portal or other online
          resources. We are not liable for any computer program or code that may
          originate from accessing our systems and you will indemnify us against
          any claims made in this regard.
        </p>

        {/* section*/}
        <p className="font-bold text-base text-justify my-2">
          10. Statements and Transaction Records
        </p>
        <p className="text-base text-justify my-2">
          10.1. You can use your Mobile phone to request a balance or mini
          statement on your wallet or alternatively you may print out your
          statement on the PayGram Mobile Customer Portal via our website.
        </p>
        <p className="text-base text-justify my-2">
          10.2. You must inform PayGram within 30 days if you believe that the
          statement is incorrect. However it must be noted that transactions
          over 60 days cannot be queried. If you do not do this within the
          timeframe, you hereby waive the right to dispute any transactions
          reflected on the statement or to recover any losses from unauthorized
          transactions reflected on the statement. Therefore we strongly advise
          that you check your statements frequently.
        </p>

        {/* section*/}
        <p className="font-bold text-base text-justify my-2">
          11. Closing or Suspension of your Wallet
        </p>
        <p className="text-base text-justify my-2">
          11.1. We may suspend, restrict or terminate the provision of our
          services (in whole or in part) and or close your account without any
          liability whatsoever under the following circumstances:
        </p>
        <p className="text-base text-justify my-2">
          11.1.1. Upon receiving a request from you at any time, we will close
          your account.
        </p>
        <p className="text-base text-justify">
          11.1.2. If you notify us that your phone has been lost or stolen or
          your PIN has been compromised, we will suspend or close your account.
        </p>
        <p className="text-base text-justify">
          11.1.3. If in any way we know or suspect your account is being used
          fraudulently, negligently or for illegal activities or if we must do
          so to comply with the law, we may close your account, restrict
          activity on your account or suspend access to your account.
        </p>
        <p className="text-base text-justify my-2">
          11.1.4. If we believe that you are in breach of these terms and
          conditions, trying to compromise our systems, and unreasonably
          interfering with any services provided by us, or for any other purpose
          in protection of our interests, we may close your account.
        </p>
        <p className="text-base text-justify my-2">
          11.1.5. Should you enter an incorrect PIN on three (3) consecutive
          occasions, we will lock your access to PayGram Mobile Money. You will
          be required to call customer service to request that your access be
          unlocked, following verification of your Identity by customer service
          representative. When you satisfying the requirement for that
          verification, your access will proceed to be unlocked.
        </p>
        <p className="text-base text-justify my-2">
          11.1.6. If your PayGram Mobile Money account remains unused for a
          period of 6 months, it goes into dormancy.
        </p>
        <p className="text-base text-justify my-2">
          11.2. We will not be responsible to you for any direct, indirect,
          consequential or special damages arising from any act or omission by
          you or any third party for whom you are responsible, whether arising
          in contract, tort or statute, as a result of the account suspension or
          closure in accordance with this Clause 11.
        </p>
        <p className="text-base text-justify my-2">
          11.3. In any case where your PayGram Mobile Money account is closed
          for any reason and has a balance of funds, upon your request to
          Customer Services, the balance will be made available to you for
          withdrawal at a PayGram Mobile Money agent field office of your
          choice, subject to properly following the laid out internal procedures
          for effecting such payments.
        </p>

        {/* section*/}
        <p className="font-bold text-base text-justify my-2">
          12. Failure or Malfunction of Equipment
        </p>
        <p className="text-base text-justify my-2">
          12.1. We are not responsible for any loss arising from any failure,
          malfunction, or delay in any POS, mobile phone network, mobile phones,
          the internet or terminals or any of its supporting or shared networks
          resulting from circumstances beyond our reasonable control.
        </p>

        {/* section*/}
        <p className="font-bold text-base text-justify my-2">13. Notices</p>
        <p className="text-base text-justify my-2">
          13.1. The address you supply on your PayGram Mobile Money payment
          registration form is regarded as your chosen address where notices may
          be given and documents in legal proceedings may be served. You must
          notify us immediately should your physical, postal email address or
          cell phone number changes.
        </p>

        {/* section*/}
        <p className="font-bold text-base text-justify my-2">
          13.2. We are entitled to send any notice to an email address specified
          on your application. This communication will be regarded as having
          been received by you unless the contrary is proved. This clause
          pertains to customers who have completed the PayGram Mobile Money
          payment registration form or have accessed and used PayGram Mobile
          Money services online.
        </p>
        <p className="text-base text-justify my-2">
          13.3. Any correspondence that we send to you by post will be
          considered to have arrived within seven (7) days of posting and any
          correspondence we send to you by fax or email will be considered to
          have arrived on the day that it was sent.
        </p>
        <p className="text-base text-justify my-2">
          13.4. We are entitled to send information to you via SMS to the
          contact mobile phone number supplied on your application form and as
          amended from time to time. These SMS are for information purposes
          only.
        </p>
        <p className="text-base text-justify my-2">
          13.5 You may contact us through the contacts provided in our contacts
          page
        </p>
        <p className="text-base text-justify my-2">
          13.6. You acknowledge and agree that this agreement will be regarded
          as having been entered into in Kenya and any breach of this agreement
          will be considered as having taken place within the Jurisdiction of
          the Republic of Kenya.
        </p>

        {/* section*/}
        <p className="font-bold text-base text-justify my-2">
          14. Fraud Prevention
        </p>
        <p className="text-base text-justify my-2">
          14.1. You, the wallet holder, consent to us carrying out identity and
          fraud prevention checks and sharing information relating to this
          application with the Kenyan Police or any fraud prevention, law
          enforcement or security agency.
        </p>
        <p className="text-base text-justify my-2">
          14.2. You consent to us providing details to the Kenyan Police or any
          fraud prevention, law enforcement or security agency, of any conduct
          on your wallet that gives reasonable cause to suspect that the wallet
          is being used for improper purposes.
        </p>
        <p className="text-base text-justify my-2">
          14.3. You understand and agree that the record of this suspicion will
          then be available to other members of the Kenyan Police or any fraud
          prevention, law enforcement or security agency.
        </p>

        {/* section*/}
        <p className="font-bold text-base text-justify my-2">
          15. Disputes and Reversals
        </p>
        <p className="text-base text-justify my-2">
          15.1. If you believe that an unauthorized or otherwise problematic
          transaction has taken place under your account, you agree to notify us
          immediately, to enable us take action to help prevent financial loss.
        </p>
        <p className="text-base text-justify my-2">
          15.2. All claims against us related to payments should be made within
          thirty (30) days after the date of such payment. It will be taken that
          you waive all claims against us, to the fullest extent of the law
          after the said period of time.
        </p>
        <p className="text-base text-justify my-2">
          15.3. You are responsible for and agree to indemnify us for all
          reversals, charge-backs, claims, fees, fines, penalties and other
          liability incurred by us (including costs and related expenses) caused
          by or arising from payments that you authorized or accepted.
        </p>
        <p className="text-base text-justify my-2">
          15.4. If you enter into a transaction with a third party and have a
          dispute over the goods or services you purchased, we have no liability
          for such goods or services. Our only involvement with regard to such
          transaction is as a payment agent.
        </p>
        <p className="text-base text-justify my-2">
          15.5. We may intervene in disputes between users concerning payments
          but have no obligation to do so.
        </p>
        <p className="text-base text-justify my-2">
          15.6. Your only remedy for a technical failure or interruption of
          service is to request that your transaction be completed at a later
          time.
        </p>
        <p className="text-base text-justify my-2">
          15.7. It is your responsibility to remit any taxes that apply to your
          transactions and not normally included in the cost of the PayGram
          Mobile Money transaction. You agree to indemnify and hold us harmless
          from and against any claim arising out of your failure to do so.
        </p>
        <p className="text-base text-justify my-2">
          15.8. The transaction ID and transaction details will be required to
          resolve all disputes
        </p>

        {/* section*/}
        <p className="font-bold text-base text-justify my-2">16. General</p>
        <p className="text-base text-justify my-2">
          16.1. We reserve the right to, at any time, amend these terms and
          conditions by notice and any such amendment will be deemed not
          constituting a violation of this agreement.
        </p>
        <p className="text-base text-justify my-2">
          16.2. These terms and conditions are governed by the laws of The
          Republic of Kenya.
        </p>
        <p className="text-base text-justify my-2">
          16.3. You must notify us if you are under an administration order,
          sequestration or any other form of insolvency.
        </p>
        <p className="text-base text-justify my-2">
          16.4. We will not be responsible to you for any indirect consequential
          or special damages arising from any act or omission by you or any
          third party for whom you are responsible for and whether arising in
          contract, statute or tort.
        </p>
        <p className="text-base text-justify my-2">
          16.5. You are responsible for your connection to the internet and all
          costs associated with that same connection.
        </p>
        <p className="text-base text-justify my-2">
          16.6. You must notify us immediately of any change of your details in
          your application.
        </p>
        <p className="text-base text-justify my-2">
          16.7. We are obliged by law to regularly update your personal
          particulars, such as your current residential address and contact
          information. We may contact you from time to time in this regard.
        </p>
        <p className="text-base text-justify my-2">
          16.8. All copyright, trademark and other intellectual property rights
          used as part of our service or contained on our documents are owned by
          PayGram or its licensors. You agree that you acquire no rights
          thereto.
        </p>
        <p className="text-base text-justify my-2">
          16.9. You accept that all the transactions effected on your Mobile
          phone are subject to other terms and conditions available on our
          website or from our Call Centre.
        </p>
        <p className="text-base text-justify my-2">
          16.10. PayGram Mobile Money may terminate the relationship at any time
          without notification if, in its sole judgment, the company‟s
          reputation and, or integrity is affected in an adverse manner by your
          actions.
        </p>
        <p className="text-base text-justify my-2">
          16.11. PayGram shall have no obligations to any person other than to
          you, for the execution of any PayGram Mobile Money Services
          instructions. However in certain circumstances, through a written
          instruction signed by you, you can authorize another person to perform
          those services on your behalf subject to additional verification of
          the authenticity of the written document by PayGram.
        </p>
        <p className="text-base text-justify my-2">
          16.12. In the event of death or circumstances that would render a
          customer mentally or physically incapable or unfit to use the PayGram
          Mobile Money services, the next of kin, as the customer has supplied
          PayGram with upon registration, would be the sole beneficiary of the
          funds remaining (if any) in their PayGram Mobile Money account. A
          claim on the funds of a deceased/unfit customer‟s account would be
          subject to provision of required documentation as required by law. The
          Parties shall use their best efforts to amicably settle all disputes
          arising out of or in connection with the performance or interpretation
          of this Terms and Conditions. Any dispute or differences arising out
          of the construction, interpretation or performance of the obligations
          created under this relationship which cannot be settled amicably
          within one (1) month after receipt by a party of the other party‟s
          request for such amicable settlement may be referred to a single
          arbitrator to be appointed in accordance with the Arbitration and
          Conciliation, Laws of Kenya. The place of arbitration shall be in
          Nairobi, Kenya and the language of arbitration shall be English
          language.
        </p>
        <p className="text-base text-justify my-2">
          16.13. These Terms and Conditions shall be in addition to and not in
          derogation of the regulations, circulars, orders, notifications,
          instructions issued by Central Bank of Kenya (CBK) from time to time,
          including the any Mobile Banking Regulations (hereinafter collectively
          referred to as the “Regulations”).
        </p>

        {/* section*/}
        <p className="font-bold text-base text-justify my-2">17. Sanctions</p>
        <p className="text-base text-justify my-2">
          17.1. Any abusive and/or fraudulent usage of a mobile money wallet and
          any false declaration may be punishable under the laws of the Republic
          of Kenya.
        </p>
        <p className="text-base text-justify my-2">
          17.2. Any abusive and/or fraudulent usage of a mobile money wallet and
          any false declaration may also lead us to suspend temporarily or
          indefinitely, rights to access to mobile money service.
        </p>
        <p className="text-base text-justify my-2">
          17.3. Any cost incurred by us in recovering transactions and undue
          payments made by the wallet user will be borne by the customer/user.
        </p>
        <p className="text-base text-justify my-2">
          17.4. Any fee that could not have been debited from the user wallet
          due to the customer‟s/user‟s fraudulent usage will be recovered by an
          additional legal interest rate.
        </p>
        <p className="text-base text-justify my-2">
          17.5. Any costs arising from a transaction or action from the customer
          on their mobile money wallet that triggers a malfunction of the system
          and requires a technical intervention, will be charged to the
          customer.
        </p>

        {/* section*/}
        <p className="font-bold text-base text-justify my-2">
          18. Termination by You
        </p>
        <p className="text-base text-justify my-2">
          18.1. You may terminate this agreement and your PayGram Mobile Money
          wallet by giving us notice via the call center or submission of a
          written letter. Upon verification, your wallet will be suspended,
          account updated and wallet will be terminated.
        </p>
      </div>
    </div>
  );
}

export default Terms;
