import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Button from "../components/global/Button";
import logo from "../assets/images/avatar.jpg";
import TextInput from "../components/global/TextInput";
import HeaderText from "../components/global/HeaderText";

function Profile(props) {
  // const dispatch = useDispatch();
  const inputs = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };
  const [inputValues, setInputValues] = useState(inputs);
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  useEffect(() => {
    if (isSubmit && props.authStore.updatedPass && props.authStore.success) {
      setIsSubmit(false);
      toast.success(props.authStore.success);
      // dispatch(logoutRequestAction());
    } else if (
      isSubmit &&
      !props.authStore.updatedPass &&
      props.authStore.error
    ) {
      toast.error(props.authStore.error);
      setIsSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authStore]);

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (
      inputValues.current_password !== "" ||
      inputValues.new_password !== "" ||
      inputValues.confirm_password !== ""
    ) {
      if (inputValues.new_password.length < 8) {
        toast.error("Password must be 8 characters long");
        setIsSubmit(false);
      } else {
        if (inputValues.new_password !== inputValues.confirm_password) {
          toast.error("New password does not match confirm password");
          setIsSubmit(false);
        } else {
          // dispatch(updatePasswordAction(inputValues));
          console.log("all set");
        }
      }
    } else {
      toast.error("All fields are required");
      setIsSubmit(false);
    }
  };
  return (
    <div className="">
      {/* page title */}
      <HeaderText title="Account settings" />

      {/* profile */}
      <div className="rounded-lg bg-white shadow-md">
        {/* profile pic */}
        <div className="flex flex-col bg-gray-50 justify-center items-center w-full h-40 my-4 p-4">
          <img
            className="rounded-full h-24 w-24 bg-gray-200 shadow-md"
            src={logo}
            alt=""
          />
          <p className="text-lg text-gray-500 font-semibold my-3">
            {props.authStore?.userInfo?.first_name}{" "}
            {props.authStore?.userInfo?.last_name}
          </p>
        </div>

        {/* profile section */}
        <div className="p-4 md:flex sm:flex-col flex justify-between sm:divide-x-0 divide-x divide-gray-200 sm:space-y-4">
          {/* PROFILE DATA */}
          <div className="sm:w-full md:w-full sm:mb-2 md:mb-2 w-1/2 space-y-4">
            {/* first name */}
            <div className="flex justify-between items-center py-2 pr-2">
              <p className="text-xs text-gray-500 font-semibold">First name</p>
              <p className="text-xs text-gray-500 w-3/4 text-left">
                {props.authStore?.userInfo?.first_name}
              </p>
            </div>
            {/* last name */}
            <div className="flex justify-between items-center py-2 pr-2">
              <p className="text-xs text-gray-500 font-semibold">Last name</p>
              <p className="text-xs text-gray-500 w-3/4 text-left">
                {props.authStore?.userInfo?.last_name}
              </p>
            </div>
            {/* email */}
            <div className="flex justify-between items-center py-2 pr-2">
              <p className="text-xs text-gray-500 font-semibold">Email</p>
              <p className="text-xs text-gray-500 w-3/4 text-left">
                {props.authStore?.userInfo?.email_address}
              </p>
            </div>
            {/* phone */}
            <div className="flex justify-between items-center py-2 pr-2">
              <p className="text-xs text-gray-500 font-semibold">
                Phone number
              </p>
              <p className="text-xs text-gray-500 w-3/4 text-left">
                {props.authStore?.userInfo?.phone_number}
              </p>
            </div>
            {/* account type */}
            {/* <div className="flex justify-between items-center py-2 pr-2">
              <p className="text-xs font-bold">Account type</p>
              <p className="text-xs w-3/4 text-left">
                {
                  data.system_user_categories.find(
                    (item) =>
                      item.value === Number(props.authStore?.userInfo?.category)
                  ).label
                }
              </p>
            </div> */}
          </div>

          {/* UPDATE PASSWORD */}
          <div className="w-1/2 sm:w-full md:w-full pl-4 sm:pl-0">
            <p className="mb-4">Change Password</p>
            <form className="form" onSubmit={handleSubmit} autoComplete="off">
              <TextInput
                required="required"
                label="Current Password"
                name="current_password"
                type="password"
                value={inputValues.current_password}
                onChange={handleChange}
              />
              <TextInput
                required="required"
                label="New Password"
                name="new_password"
                type="password"
                value={inputValues.new_password}
                onChange={handleChange}
              />
              <TextInput
                required="required"
                label="Confirm New Password"
                name="confirm_password"
                type="password"
                value={inputValues.confirm_password}
                onChange={handleChange}
              />
              <Button
                class="btn btn-success bx-pull-right"
                type="submit"
                text={isSubmit ? "Updating..." : "Update"}
                disabled={isSubmit ? "disabled" : ""}
              />
            </form>
          </div>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authStore: state.auth ? state.auth : [],
  };
};

export default connect(mapStateToProps)(Profile);
