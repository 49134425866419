/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  // Departments / groups
  fetchDepartments() {
    return apiClient.get("/permissions/groups/view");
  },
  addDepartment(data) {
    return apiClient.post("/permissions/groups/create", data);
  },
  updateDepartment(data) {
    return apiClient.post("/permissions/groups/update", data);
  },

  //   Roles / Permissions
  fetchRoles() {
    return apiClient.get("/permissions/view");
  },
  createRole(data) {
    return apiClient.post("/permissions/create", data);
  },
  assignRole(data) {
    return apiClient.post("/permissions/assign", data);
  },
  addRole(data) {
    return apiClient.post("/permissions/add/permissions", data);
  },
  deleteRole(data) {
    return apiClient.post("/permissions/remove/permissions", data);
  },

  //modules
  fetchModules() {
    return apiClient.get("/permissions/module/view");
  },
  createModule(data) {
    return apiClient.post("/permissions/module/create", data);
  },
  updateModule(data) {
    return apiClient.post("/permissions/module/update", data);
  },

  //admin users
  fetchUsers() {
    return apiClient.get("/users/view");
  },
  createUser(data) {
    return apiClient.post("/users/create", data);
  },
  updateUser(data) {
    return apiClient.post("/users/update", data);
  },
};
