import PermissionsClient from "../../api/PermissionsClient";
import {
  CREATE_USER,
  INITIALIZE_USERS_STATE,
  SET_USERS,
  SET_USER_ERROR_MESSAGE,
  SET_USER_SUCCESS_MESSAGE,
  TOGGLE_CREATING_USER,
  TOGGLE_FETCHING_USERS,
  TOGGLE_UPDATING_USER,
  UPDATE_USER,
} from "./actionTypes";
// set the actions
export const initializeUsersState = () => {
  return {
    type: INITIALIZE_USERS_STATE,
  };
};

export const toggleFetchingUsers = (status) => {
  return {
    type: TOGGLE_FETCHING_USERS,
    payload: status,
  };
};

export const toggleCreatingUser = (status) => {
  return {
    type: TOGGLE_CREATING_USER,
    payload: status,
  };
};

export const toggleUpdatingUser = (status) => {
  return {
    type: TOGGLE_UPDATING_USER,
    payload: status,
  };
};

export const setUsers = (data) => {
  return {
    type: SET_USERS,
    payload: data,
  };
};

export const createUser = (data) => {
  return {
    type: CREATE_USER,
    payload: data,
  };
};

export const updateUser = (data) => {
  return {
    type: UPDATE_USER,
    payload: data,
  };
};

export const setUserSuccessMessage = (msg) => {
  return {
    type: SET_USER_SUCCESS_MESSAGE,
    payload: msg,
  };
};

export const setUserErrorMessage = (error) => {
  return {
    type: SET_USER_ERROR_MESSAGE,
    payload: error,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchUsersAction() {
  return function action(dispatch) {
    dispatch(initializeUsersState);
    dispatch(toggleFetchingUsers(true));

    return PermissionsClient.fetchUsers()
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(setUsers(response.data.data));
          dispatch(toggleFetchingUsers(false));
        } else {
          dispatch(setUserErrorMessage("Something went wrong!"));
          dispatch(toggleFetchingUsers(false));
        }
      })
      .catch((error) => {
        dispatch(setUserErrorMessage("Server error"));
        dispatch(toggleFetchingUsers(false));
      });
  };
}
export function createUserAction(data) {
  return function action(dispatch) {
    dispatch(initializeUsersState);
    dispatch(toggleCreatingUser(true));

    return PermissionsClient.createUser(data)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(createUser(response.data.data));
          dispatch(toggleCreatingUser(false));
          return true;
        } else {
          dispatch(setUserErrorMessage("Something went wrong!"));
          dispatch(toggleCreatingUser(false));
          return false;
        }
      })
      .catch((error) => {
        // console.log(error.response);
        if (error.response.status === 400) {
          dispatch(setUserErrorMessage(error.response.data.message));
        } else {
          dispatch(setUserErrorMessage("Server error"));
        }
        dispatch(toggleCreatingUser(false));
        return false;
      });
  };
}
export function updateUserAction(data) {
  return function action(dispatch) {
    dispatch(initializeUsersState);
    dispatch(toggleUpdatingUser(true));

    return PermissionsClient.updateUser(data)
      .then((response) => {
        console.log(response.data.data);
        if (response.status === 200) {
          dispatch(updateUser(response.data.data));
          dispatch(toggleUpdatingUser(false));
        } else {
          dispatch(setUserErrorMessage("Something went wrong!"));
          dispatch(toggleUpdatingUser(false));
        }
      })
      .catch((error) => {
        dispatch(setUserErrorMessage("Server error"));
        dispatch(toggleUpdatingUser(false));
      });
  };
}
