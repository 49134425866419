import {
  CREATE_TRANSFER_CHARGE,
  INITIALIZE_TRANSFER_CHARGES_STATE,
  SET_TRANSFER_CHARGES,
  SET_TRANSFER_CHARGES_ERROR_MESSAGE,
  SET_TRANSFER_CHARGES_SUCCESS_MESSAGE,
  TOGGLE_CREATING_TRANSFER_CHARGE,
  TOGGLE_FETCHING_TRANSFER_CHARGES,
  TOGGLE_UPDATING_TRANSFER_CHARGE,
  UPDATE_TRANSFER_CHARGE,
} from "./actionTypes";

// set the initial state
const initialState = () => {
  return {
    success: "",
    error: "",
    fetchingtransferCharges: false,
    creatingTransferCharge: false,
    updatingTransferCharge: false,
    transferCharges: [],
    processSet: false,
  };
};

// set the reducer
const transferChargesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_TRANSFER_CHARGES_STATE:
      return {
        ...state,
        success: "",
        error: "",
        processSet: false,
      };
    /* falls through */
    case TOGGLE_FETCHING_TRANSFER_CHARGES:
      return {
        ...state,
        fetchingtransferCharges: action.payload,
      };
    /* falls through */
    case TOGGLE_CREATING_TRANSFER_CHARGE:
      return {
        ...state,
        creatingTransferCharge: action.payload,
      };
    /* falls through */
    case TOGGLE_UPDATING_TRANSFER_CHARGE:
      return {
        ...state,
        updatingTransferCharge: action.payload,
      };
    /* falls through */
    case SET_TRANSFER_CHARGES:
      return {
        ...state,
        transferCharges: action.payload,
      };
    /* falls through */
    case CREATE_TRANSFER_CHARGE:
      let newList = [...state.transferCharges];
      newList.unshift(action.payload);
      return {
        ...state,
        // transferCharges: [...state.transferCharges, action.payload],
        transferCharges: newList,
        processSet: true,
      };
    /* falls through */
    case UPDATE_TRANSFER_CHARGE:
      let newTransferCharges = [...state.transferCharges];
      let foundIndex = newTransferCharges?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      newTransferCharges[foundIndex] = action.payload;
      return {
        ...state,
        transferCharges: newTransferCharges,
        processSet: true,
      };
    /* falls through */

    case SET_TRANSFER_CHARGES_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.payload,
      };
    /* falls through */
    case SET_TRANSFER_CHARGES_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default transferChargesReducer;
