export const reportsItems = [
  {
    item: "Clients",
    route: "clients",
    icon: <i className="bx bx-user-check text-3xl text-gray-400"></i>,
  },
  {
    item: "Revenue",
    route: "revenue",
    icon: <i className="bx bx-line-chart text-3xl text-gray-400"></i>,
  },
  {
    item: "Savings",
    route: "savings",
    icon: <i className="bx bx-coin-stack text-3xl text-gray-400"></i>,
  },
  {
    item: "Payments",
    route: "payments",
    icon: <i className="bx bx-credit-card text-3xl text-gray-400"></i>,
  },
  {
    item: "Transactions",
    route: "transactions",
    icon: <i className="bx bx-spreadsheet text-3xl text-gray-400"></i>,
  },
  {
    item: "Network operators",
    route: "network_operators",
    icon: <i className="bx bx-broadcast text-3xl text-gray-400"></i>,
  },
  {
    item: "Banks",
    route: "banks",
    icon: <i className="bx bxs-bank text-3xl text-gray-400"></i>,
  },
  {
    item: "Agents",
    route: "agents",
    icon: <i className="bx bx-group text-3xl text-gray-400"></i>,
  },
  {
    item: "System users & Access rights",
    route: "system_users",
    icon: <i className="bx bx-shield text-3xl text-gray-400"></i>,
  },
  {
    item: "Withdrawals",
    route: "withdrawals",
    icon: <i className="bx bx-list-ol text-3xl text-gray-400"></i>,
  },
  {
    item: "Deposits",
    route: "deposits",
    icon: <i className="bx bx-download text-3xl text-gray-400"></i>,
  },
  {
    item: "Merchants",
    route: "merchants",
    icon: <i className="bx bxs-business text-3xl text-gray-400"></i>,
  },
  {
    item: "Merchants transactions",
    route: "merchants_transactions",
    icon: <i className="bx bx-layer text-3xl text-gray-400"></i>,
  },
];
