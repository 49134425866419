/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import FabButton from "../global/FabButton";
import SidePanel from "../global/SidePanel";
import { connect, useDispatch } from "react-redux";
import {
  closeAddSideModal,
  createWithdrawalChargeAction,
  fetchWithdrawalChargesAction,
  openAddSideModal,
  updateWithdrawalChargeAction,
} from "../../redux/Index";
import TextInput from "../global/TextInput";
import Button from "../global/Button";
import { ToastContainer, toast } from "react-toastify";
import CustomDateFormat from "../global/CustomDateFormat";
import { floatCurrencyFormatter } from "../global/CurrencyFormatter";

function WithdrawalCharges(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [withdrawalCharges, setWithdrawalCharges] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    loadWithdrawalCharges();
  }, []);

  const loadWithdrawalCharges = () => {
    dispatch(fetchWithdrawalChargesAction());
  };

  useEffect(() => {
    setWithdrawalCharges(props.withdrawalChargesState?.withdrawalCharges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.withdrawalChargesState?.withdrawalCharges]);

  const headers = [
    "Min",
    "Max",
    "Paygram fee",
    "Agent fee",
    "Total charge",
    "Created on",
    "Updated on",
    "Edit",
  ];
  let tableRows = [];
  if (withdrawalCharges?.length) {
    for (let i = 0; i < withdrawalCharges?.length; i++) {
      const element = withdrawalCharges[i];
      tableRows.push({
        min: floatCurrencyFormatter(element.min),
        max: floatCurrencyFormatter(element.max),
        paygram_fee: floatCurrencyFormatter(element.paygram_fee),
        agent_fee: floatCurrencyFormatter(element.agent_fee),
        total_charge: floatCurrencyFormatter(element.total_charge),
        created_at: <CustomDateFormat>{element.created_at}</CustomDateFormat>,
        updated_at: <CustomDateFormat>{element.updated_at}</CustomDateFormat>,
        edit: (
          <i
            className="bx bx-edit links text-[20px] cursor-pointer"
            onClick={() => editModuleDetails(element)}
          ></i>
        ),
      });
    }
  }

  useEffect(() => {
    if (isEdit && selectedItem) {
      setInputValues({
        min: selectedItem.min,
        max: selectedItem.max,
        paygram_fee: selectedItem.paygram_fee,
        agent_fee: selectedItem.agent_fee,
      });
    }
  }, [isEdit, selectedItem]);

  const editModuleDetails = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    openSidePanel();
  };

  const [inputValues, setInputValues] = useState({
    min: "",
    max: "",
    paygram_fee: "",
    agent_fee: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (
      inputValues.min !== "" ||
      inputValues.max !== "" ||
      inputValues.paygram_fee !== "" ||
      inputValues.agent_fee !== ""
    ) {
      if (isEdit) inputValues.id = selectedItem.id;
      dispatch(
        isEdit
          ? updateWithdrawalChargeAction(inputValues)
          : createWithdrawalChargeAction(inputValues)
      ).then((response) => {
        if (response) {
          setIsSubmit(false);
          closeSidePanel();
          toast.success("Success");
        } else {
          toast.error("Failed to complete  the request");
        }
      });
    } else {
      toast.error("Please fill the form correctly");
    }
  };

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const closeSidePanel = () => {
    dispatch(closeAddSideModal());
  };

  const fabIconClick = () => {
    openSidePanel();
    setIsEdit(false);
    setSelectedItem(null);
    setInputValues({
      min: "",
      max: "",
      paygram_fee: "",
      agent_fee: "",
    });
  };

  if (props.withdrawalChargesState?.fetchingWithdrawalCharges) {
    return <Loader />;
  }

  return (
    <div className="flex-1 h-full">
      <FabButton onPressAction={fabIconClick} />
      {/* load items in table */}
      <DataTable headers={headers} data={tableRows} />
      {/* display sidepanel */}
      <SidePanel
        width="35%"
        title="Withdrawal charges"
        subtitle={selectedItem ? "Update record" : "Create record"}
      >
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            required="required"
            label="Minimum amount"
            name="min"
            type="number"
            value={inputValues.min}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Maximum amount"
            name="max"
            type="number"
            value={inputValues.max}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="PayGram fee"
            name="paygram_fee"
            type="number"
            value={inputValues.paygram_fee}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Agent fee"
            name="agent_fee"
            type="number"
            value={inputValues.agent_fee}
            onChange={handleChange}
          />
          <Button
            text={isSubmit ? "Processing..." : isEdit ? "Update" : "Save"}
            disabled={isSubmit}
          />
        </form>
      </SidePanel>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    withdrawalChargesState: state.withdrawalCharges
      ? state.withdrawalCharges
      : [],
  };
};

export default connect(mapStateToProps)(WithdrawalCharges);
