/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  login(user) {
    return apiClient.post("/auth/login", user);
  },
  logout() {
    return apiClient.post("/auth/logout/");
  },
};
