import React, { useEffect, useState } from "react";
import { clientsList } from "../../constants/data";
import DataTable from "../datatable/DataTable";
import Button from "../global/Button";
import CountryName from "../global/CountryName";
import FullDateFormat from "../global/FullDateFormat";
import HeaderNav from "../global/HeaderNav";
import Loader from "../global/Loader";
import TextInput from "../global/TextInput";
import { openAddSideModal } from "../../redux/Index";
import { useDispatch } from "react-redux";
import WithdrawalsReport from "../clients/WithdrawalsReport";
import DepositsReport from "../clients/DepositsReport";
import BankTransfersReport from "../clients/BankTransfersReport";
import SidePanel from "../global/SidePanel";
import TabsComponent from "../global/TabsComponent";

function ClientsReport() {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [inputValues, setInputValues] = useState({
    start_date: "",
    end_date: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [loadedData, setLoadedData] = useState(null);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  let loadData = () => {
    setLoadedData(clientsList);
    setIsSubmit(false);
  };

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  //submit the form
  const handleSubmit = () => {
    setIsSubmit(true);

    if (
      inputValues.start_date !== "" &&
      inputValues.end_date !== "" &&
      isSubmit
    ) {
      loadData();
    }
  };

  const headers = [
    "Profile picture",
    "National ID number",
    "First name",
    "Last name",
    "Email",
    "telephone",
    "Country",
    "View transactions",
  ];
  let tableRows = [];
  if (loadedData?.length) {
    for (let i = 0; i < loadedData?.length; i++) {
      tableRows.push({
        profile_image: (
          <img
            src={loadedData[i].profile_image}
            className="w-10 h-10 rounded-full"
            alt="Uimage"
          />
        ),
        national_id: loadedData[i].user_id,
        first_name: loadedData[i].first_name,
        last_name: loadedData[i].last_name,
        email: loadedData[i].email,
        telephone: loadedData[i].telephone,
        country: <CountryName id={loadedData[i].country} />,
        more: (
          <i
            className="bx bx-link-external links text-[16px] cursor-pointer"
            onClick={() => openMore(loadedData[i])}
          ></i>
        ),
      });
    }
  }

  const openMore = (item) => {
    setSelectedItem(item);
    openSidePanel();
  };

  const displayContent = isSubmit ? (
    <Loader />
  ) : loadedData ? (
    <DataTable headers={headers} data={tableRows} />
  ) : (
    ""
  );

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const tabs = [
    { name: "Withdrawals", component: <WithdrawalsReport /> },
    { name: "Deposits", component: <DepositsReport /> },
    { name: "Bank transfers", component: <BankTransfersReport /> },
  ];

  let exportPDF = () => {
    console.log("Export PDF");
  };

  return (
    <div>
      {/* display header */}
      <HeaderNav title="Clients report" />

      {/* filters */}
      <div className="flex justify-center space-x-6">
        <div className="flex space-x-4 flex-row items-center">
          <TextInput
            required="required"
            label="From"
            name="start_date"
            type="date"
            value={inputValues.start_date}
            onChange={handleChange}
          />
        </div>
        <div className="flex space-x-4 flex-row items-center">
          <TextInput
            required="required"
            label="To"
            name="end_date"
            type="date"
            value={inputValues.end_date}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* summary */}
      {loadedData ? (
        <div className="flex flex-row sm:flex-col sm:space-y-2 justify-between items-center m-4">
          <div className="text-xs text-gray-600">
            <FullDateFormat>{inputValues.start_date}</FullDateFormat> &ensp; -
            &ensp; <FullDateFormat>{inputValues.end_date}</FullDateFormat>
          </div>
          <Button
            onClick={exportPDF}
            text="Export PDF"
            bgColor={"bg-primary"}
          />
        </div>
      ) : (
        ""
      )}

      {/* display contents */}
      {displayContent}
      {/* display sidepanel */}
      <SidePanel
        width="75%"
        title={`${selectedItem.first_name} ${selectedItem.last_name}`}
        subtitle="View transactions"
      >
        <TabsComponent tabsList={tabs} />
      </SidePanel>
    </div>
  );
}

export default ClientsReport;
