import React from "react";

function HorizontalDisplay({ title, text }) {
  return (
    <div className="flex flex-row items-center border-b py-2 border-gray-100">
      <p className="font-semibold text-gray-500 w-1/2 text-xs">{title}</p>
      <p className="w-1/2 text-gray-600 text-xs">{text}</p>
    </div>
  );
}

export default HorizontalDisplay;
