import TransactionsClient from "../../api/TransactionsClient";
import {
  CREATE_TRANSFER_CHARGE,
  INITIALIZE_TRANSFER_CHARGES_STATE,
  SET_TRANSFER_CHARGES,
  SET_TRANSFER_CHARGES_ERROR_MESSAGE,
  SET_TRANSFER_CHARGES_SUCCESS_MESSAGE,
  TOGGLE_CREATING_TRANSFER_CHARGE,
  TOGGLE_FETCHING_TRANSFER_CHARGES,
  TOGGLE_UPDATING_TRANSFER_CHARGE,
  UPDATE_TRANSFER_CHARGE,
} from "./actionTypes";

// set the actions
export const initializeTransferChargesState = () => {
  return {
    type: INITIALIZE_TRANSFER_CHARGES_STATE,
  };
};

export const toggleFetchingTransferCharges = (status) => {
  return {
    type: TOGGLE_FETCHING_TRANSFER_CHARGES,
    payload: status,
  };
};

export const toggleCreatingTransferCharge = (status) => {
  return {
    type: TOGGLE_CREATING_TRANSFER_CHARGE,
    payload: status,
  };
};

export const toggleUpdatingTransferCharge = (status) => {
  return {
    type: TOGGLE_UPDATING_TRANSFER_CHARGE,
    payload: status,
  };
};

export const setTransferCharges = (data) => {
  return {
    type: SET_TRANSFER_CHARGES,
    payload: data,
  };
};

export const createTransferCharge = (data) => {
  return {
    type: CREATE_TRANSFER_CHARGE,
    payload: data,
  };
};

export const updateTransferCharge = (data) => {
  return {
    type: UPDATE_TRANSFER_CHARGE,
    payload: data,
  };
};

export const setTransferChargesuccessMessage = (msg) => {
  return {
    type: SET_TRANSFER_CHARGES_SUCCESS_MESSAGE,
    payload: msg,
  };
};

export const setTransferChargeErrorMessage = (error) => {
  return {
    type: SET_TRANSFER_CHARGES_ERROR_MESSAGE,
    payload: error,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchTransferChargesAction() {
  return function action(dispatch) {
    dispatch(initializeTransferChargesState);
    dispatch(toggleFetchingTransferCharges(true));

    return TransactionsClient.fetchTransferCharges()
      .then((response) => {
        if (response.status === 200) {
          dispatch(setTransferCharges(response.data.data));
          dispatch(toggleFetchingTransferCharges(false));
        }
      })
      .catch((error) => {
        dispatch(toggleFetchingTransferCharges(false));
      });
  };
}
export function createTransferChargeAction(data) {
  return function action(dispatch) {
    return TransactionsClient.createTransferCharge(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(createTransferCharge(response.data.data));
        }
        return true;
      })
      .catch((error) => {
        return false;
      });
  };
}
export function updateTransferChargeAction(data) {
  return function action(dispatch) {
    return TransactionsClient.updateTransferCharge(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(updateTransferCharge(response.data.data));
        }
        return true;
      })
      .catch((error) => {
        return false;
      });
  };
}
