import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import SidePanel from "../global/SidePanel";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchingMerchantRecordAction,
  fetchingMerchantsActionsAction,
  fetchingMerchantsDocumentsAction,
  openAddSideModal,
  verifyMerchantDocsAction,
} from "../../redux/Index";
import HeaderText from "../global/HeaderText";
import HorizontalDisplay from "../global/HorizontalDisplay";
import { standardDate, standardDateWithOrdinal } from "../global/DateUtilities";
import Legend from "../global/Legend";
import Button from "../global/Button";
import { ToastContainer, toast } from "react-toastify";
import ApiDataTable from "../datatable/ApiDataTable";
import MerchantTransactionsHistory from "./MerchantsTransactionsHistory";

function MerchantsList() {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemDocs, setSelectedItemDocs] = useState({});
  const merchantsState = useSelector((state) => state.merchants);
  const [merchantsList, setMerchantsList] = useState(null);
  const [merchantsDocsList, setMerchantsDocsList] = useState(null);
  const [loading, setLoading] = useState(null);
  const [viewTransactions, setViewTransactions] = useState(null);

  const loadData = (rowsPerPage, page) => {
    dispatch(fetchingMerchantsActionsAction(rowsPerPage, page));
    dispatch(fetchingMerchantsDocumentsAction());
  };

  const fetchRecord = (query) => {
    toast.info("Searching...");
    dispatch(fetchingMerchantRecordAction(query)).then((res) => {
      if (res.success) {
        const num = res.data?.data?.length;
        if (num) {
          setMerchantsList(res.data);
          toast.success(`${num} Record(s) found`);
        } else {
          setMerchantsList(merchantsState.merchants);
          toast.warning(`No Records found`);
        }
      } else {
        setMerchantsList(merchantsState.merchants);
        toast.error(
          "Sorry, an error occured while searching. Please try again"
        );
      }
    });
  };

  useEffect(() => {
    loadData(10, 1);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setMerchantsList(merchantsState.merchants);
  }, [merchantsState.merchants]);

  useEffect(() => {
    setMerchantsDocsList(merchantsState.merchantsDocuments);
  }, [merchantsState.merchantsDocuments]);

  const approveAccount = (item) => {
    setSelectedItem(item);
    setSelectedItemDocs(
      merchantsDocsList?.filter(
        (el) => parseInt(el.store.id) === parseInt(item.id)
      )
    );
    openSidePanel();
    setViewTransactions(false);
  };

  const setSelectedMechant = (item) => {
    // dispatch(setSelectedMerchantAction(item));
    setViewTransactions(true);
    setSelectedItem(item);
    openSidePanel();
  };

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const verifyAccount = (id, status) => {
    setLoading(true);
    const data = {
      document_id: id,
      review_status: status ? "A" : "R",
    };
    dispatch(verifyMerchantDocsAction(data)).then((response) => {
      if (response.status) {
        setLoading(false);
        toast.success("Updated successfully");
        const newList = [...selectedItemDocs];
        const foundIndex = newList?.findIndex((item) => item.id === id);
        newList[foundIndex] = response.data;
        setSelectedItemDocs(newList);
        loadData(10, 1);
      } else {
        setLoading(false);
        toast.error(response.data);
      }
    });
  };

  const headers = [
    "Business name",
    "Contact person",
    "Email",
    "Phone",
    "Date created",
    "Status",
    "View transactions",
  ];

  let tableRows = [];
  const list = merchantsList?.data ? merchantsList.data : [];
  if (list?.length) {
    for (let i = 0; i < list?.length; i++) {
      const element = list[i];
      tableRows.push({
        merchant_name: element.business_name,
        contact_person: element.admin?.name,
        email: element.admin?.email,
        phone: element.admin?.phone,
        created_at: standardDate(element.created_at),
        status: (
          <span
            onClick={() => approveAccount(element)}
            className={`flex flex-row items-center space-x-2 text-xl cursor-pointer h-10 ${
              element.is_verified ? "text-green-500" : "text-red-600"
            }`}
          >
            <i
              className={` ${
                element.is_verified ? "bx bxs-user-check" : "bx bx-user-x"
              } links text-xl cursor-pointer`}
            ></i>
            <p className="text-xs">
              {element.is_verified ? "Verified" : "Not Verified"}{" "}
            </p>
          </span>
        ),
        more: (
          <span>
            <i
              className="bx bx-link-external links text-lg cursor-pointer"
              onClick={() => setSelectedMechant(element)}
            ></i>
          </span>
        ),
      });
    }
  }

  const docs = [];
  if (selectedItemDocs?.length) {
    for (let j = 0; j < selectedItemDocs?.length; j++) {
      const element = selectedItemDocs[j];
      docs.push(
        <div className="w-full min-h-fit flex-col justify-between border border-gray-100 shadow-md shadow-gray-300 mb-3">
          <div className="flex-1">
            <img
              src={element?.document.file}
              className="w-full rounded-sm"
              alt="file"
            />
          </div>
          <div className="h-16 border-t bg-gray-100 border-gray-200 w-full items-center flex justify-end px-4 flex-row">
            <div className="flex-1">
              <HorizontalDisplay title="Document" text={element.name} />
            </div>
            <Button
              text={
                element?.document?.review_status === "A" ? "Reject" : "Verify"
              }
              onClick={() =>
                verifyAccount(
                  element.id,
                  element?.document?.review_status === "A" ? 0 : 1
                )
              }
              disabled={loading}
              bgColor={
                element?.document?.review_status === "A"
                  ? "bg-red-500"
                  : "bg-green-500"
              }
            />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="flex-1 h-full w-full">
      {/* page title */}
      <HeaderText title="Merchants" />

      <div className="flex items-center justify-start"></div>
      {merchantsState.fetchingMerchants ? (
        <Loader />
      ) : (
        <ApiDataTable
          headers={headers}
          data={tableRows}
          loadDataAction={loadData}
          searchAction={fetchRecord}
          totalPageCount={merchantsList?.last_page}
        />
      )}
      {/* display sidepanel */}
      <SidePanel
        width="50%"
        title={selectedItem ? selectedItem.business_name : "Merchant details"}
        subtitle={
          viewTransactions ? "View transactions" : "Confirm merchant details"
        }
      >
        {viewTransactions ? (
          <div>
            <MerchantTransactionsHistory selectedMerchant={selectedItem} />
          </div>
        ) : (
          <div>
            <Legend title={"Business details"} />

            <HorizontalDisplay
              title="Business name"
              text={selectedItem?.business_name}
            />
            <HorizontalDisplay title="Country" text={selectedItem?.country} />
            <HorizontalDisplay title="City" text={selectedItem?.city} />
            <HorizontalDisplay
              title="Till number"
              text={selectedItem?.till_number}
            />
            <HorizontalDisplay
              title="Account creation date"
              text={standardDateWithOrdinal(selectedItem?.created_at)}
            />

            <Legend title={"Administrator details"} />
            <HorizontalDisplay title="Name" text={selectedItem?.admin?.name} />
            <HorizontalDisplay
              title="Email"
              text={selectedItem?.admin?.email}
            />
            <HorizontalDisplay
              title="Phone number"
              text={selectedItem?.admin?.phone}
            />

            <Legend title={"Uploaded Documents"} />
            {docs}
          </div>
        )}
      </SidePanel>
      <ToastContainer />
    </div>
  );
}

export default MerchantsList;
