import {
  INITIALIZE_TRANSACTIONS_STATE,
  SET_TRANSACTIONS,
  SET_TRANSACTION_ERROR_MESSAGE,
  SET_TRANSACTION_SUCCESS_MESSAGE,
  SET_WALLET_BALANCES,
  SET_WITHDRAWALS,
  SET_WITHDRAWAL_REQUESTS,
  TOGGLE_FETCHING_TRANSACTIONS,
  TOGGLE_FETCHING_WALLET_BALANCES,
  TOGGLE_FETCHING_WITHDRAWALS,
  TOGGLE_FETCHING_WITHDRAWAL_REQUESTS,
} from "./actionTypes";

// set the initial state
const initialState = () => {
  return {
    success: "",
    error: "",
    fetchingTransactions: false,
    transactions: [],
    fetchingWalletBalances: false,
    walletBalances: [],
  };
};

// set the reducer
const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_TRANSACTIONS_STATE:
      return {
        ...state,
        success: "",
        error: "",
      };
    /* falls through */
    case TOGGLE_FETCHING_TRANSACTIONS:
      return {
        ...state,
        fetchingTransactions: action.payload,
      };
    /* falls through */
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };

    /* falls through */
    case TOGGLE_FETCHING_WALLET_BALANCES:
      return {
        ...state,
        fetchingWalletBalances: action.payload,
      };

    /* falls through */
    case SET_WALLET_BALANCES:
      return {
        ...state,
        walletBalances: action.payload,
      };
    /* falls through */
    case TOGGLE_FETCHING_WITHDRAWALS:
      return {
        ...state,
        fetchingWithdrawals: action.payload,
      };

    /* falls through */
    case SET_WITHDRAWALS:
      return {
        ...state,
        withdrawals: action.payload,
      };
    /* falls through */
    case TOGGLE_FETCHING_WITHDRAWAL_REQUESTS:
      return {
        ...state,
        fetchingWithdrawalRequests: action.payload,
      };

    /* falls through */
    case SET_WITHDRAWAL_REQUESTS:
      return {
        ...state,
        withdrawalRequests: action.payload,
      };
    /* falls through */

    case SET_TRANSACTION_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.payload,
      };
    /* falls through */
    case SET_TRANSACTION_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default transactionsReducer;
