//
//      Combime all the reducers and export as one to the store.js
//
import { combineReducers } from "redux";
import agentReducer from "./agents/agentReducers";
import authReducer from "./auth/authReducer";
import banksReducer from "./banks/bankReducers";
import networkOperatorReducer from "./networkOperators/operatorReducers";
import sidebarReducer from "./sidebarMenu/sidebarReducer";
import sidePanelReducer from "./sideModal/modalReducers";
import merchantReducer from "./merchants/merchatReducers";
import permissionsReducer from "./permissions/permissionsReducer";
import modulesReducer from "./modules/modulesReducer";
import usersReducer from "./users/usersReducer";
import clientsReducer from "./clients/usersReducer";
import transactionsReducer from "./transactions/usersReducer";
import documentsReducer from "./documents/documentsReducers";
import transferChargesReducer from "./transferCharges/transferChargesReducer";
import withdrawalChargesReducer from "./withdrawalCharges/withdrawalChargesReducer";
import dashboardReducer from "./dashboard/dashboardReducers";

export const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  sidePanel: sidePanelReducer,
  agents: agentReducer,
  banks: banksReducer,
  networkOperators: networkOperatorReducer,
  merchants: merchantReducer,
  permissions: permissionsReducer,
  modules: modulesReducer,
  users: usersReducer,
  clients: clientsReducer,
  transactions: transactionsReducer,
  documents: documentsReducer,
  transferCharges: transferChargesReducer,
  withdrawalCharges: withdrawalChargesReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
