import {
  CREATE_MODULE,
  INITIALIZE_MODULES_STATE,
  SET_MODULES,
  SET_MODULE_ERROR_MESSAGE,
  SET_MODULE_SUCCESS_MESSAGE,
  TOGGLE_CREATING_MODULE,
  TOGGLE_FETCHING_MODULES,
  TOGGLE_UPDATING_MODULE,
  UPDATE_MODULE,
} from "./actionTypes";

// set the initial state
const initialState = () => {
  return {
    success: "",
    error: "",
    fetchingModules: false,
    creatingModule: false,
    updatingModule: false,
    modules: [],
    processSet: false,
  };
};

// set the reducer
const modulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_MODULES_STATE:
      return {
        ...state,
        success: "",
        error: "",
        processSet: false,
      };
    /* falls through */
    case TOGGLE_FETCHING_MODULES:
      return {
        ...state,
        fetchingModules: action.payload,
      };
    /* falls through */
    case TOGGLE_CREATING_MODULE:
      return {
        ...state,
        creatingModule: action.payload,
      };
    /* falls through */
    case TOGGLE_UPDATING_MODULE:
      return {
        ...state,
        updatingModule: action.payload,
      };
    /* falls through */
    case SET_MODULES:
      return {
        ...state,
        modules: action.payload,
      };
    /* falls through */
    case CREATE_MODULE:
      let newList = [...state.modules];
      newList.unshift(action.payload);
      return {
        ...state,
        // modules: [...state.modules, action.payload],
        modules: newList,
        processSet: true,
      };
    /* falls through */
    case UPDATE_MODULE:
      let newModules = [...state.modules];
      let foundIndex = newModules?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      newModules[foundIndex] = action.payload;
      return {
        ...state,
        modules: newModules,
        processSet: true,
      };
    /* falls through */

    case SET_MODULE_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.payload,
      };
    /* falls through */
    case SET_MODULE_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default modulesReducer;
