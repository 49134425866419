import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import { deposits } from "../../constants/data";
import CurrencyFormatter from "../global/CurrencyFormatter";
import DateTimeFormat from "../global/DateTimeFormat";
import { deposit_commissions } from "../../constants/Tariffs";
import HeaderInfo from "../agents/HeaderInfo";
import Button from "../global/Button";

function DepositsReport() {
  const headers = [
    "Transaction ID",
    "Client ID",
    "Amount",
    "Commission",
    "Date",
    "Print statement",
  ];
  let tableRows = [];
  let commission = 0;

  if (deposits.length) {
    for (let i = 0; i < deposits.length; i++) {
      let commissionEarned = deposit_commissions(deposits[i].amount);
      commission = commission += commissionEarned;
      tableRows.push({
        transaction_id: deposits[i].transaction_id,
        client_id: deposits[i].client_id_number,
        amount: <CurrencyFormatter value={deposits[i].amount} />,
        commission: <CurrencyFormatter value={commissionEarned} />,
        created_at: <DateTimeFormat>{deposits[i].created_at}</DateTimeFormat>,
        more: (
          <i
            className="bx bx-printer links text-[16px] cursor-pointer"
            onClick={() => printStatement(deposits[i])}
          ></i>
        ),
      });
    }
  }

  const printStatement = (id) => {
    console.log(id);
  };
  // fetching data
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  useEffect(() => {
    handleAction(selectedMonth);
  }, [selectedMonth]);

  let handleAction = (val) => {
    console.log(val);
  };

  const displayContent = deposits ? (
    !deposits.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );

  return (
    <div>
      {/* header information */}
      <HeaderInfo commission={commission} action={setSelectedMonth} />
      <div className="flex items-center justify-end mb-3">
        <Button text={"Export Excel"} />
        <Button text={"Export PDF"} bgColor={"bg-red-500"} />
      </div>
      {displayContent}
    </div>
  );
}

export default DepositsReport;
