import React, { useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import { agentsList, deposits, withdrawals } from "../../constants/data";
import SidePanel from "../global/SidePanel";
import { useDispatch } from "react-redux";
import { openAddSideModal } from "../../redux/Index";
import Button from "../global/Button";
import CountryName from "../global/CountryName";
import CountyName from "../global/CountyName";
import FloatLimitValue from "../global/FloatLimitValue";
import WithdrawalsReport from "../agents/WithdrawalsReport";
import DepositsReport from "../agents/DepositsReport";
import TabsComponent from "../global/TabsComponent";
import HeaderNav from "../global/HeaderNav";

function AgentsList() {
  const dispatch = useDispatch();
  const headers = [
    "Agent ID",
    "Business name",
    "Branch",
    "Float Limit",
    "Country",
    "County/Province",
    "Contact person",
    "Email",
    "Telephone",
    "View transactions",
  ];
  let tableRows = [];
  if (agentsList.length) {
    for (let i = 0; i < agentsList.length; i++) {
      tableRows.push({
        agent_id: agentsList[i].agent_id,
        business_name: agentsList[i].business_name,
        branch: agentsList[i].branch,
        float_limit: <FloatLimitValue id={agentsList[i].float_limit} />,
        country: <CountryName id={agentsList[i].country} />,
        county: <CountyName id={agentsList[i].county} />,
        contact_person: agentsList[i].contact_person,
        email: agentsList[i].email,
        telephone: agentsList[i].telephone,
        more: (
          <i
            className="bx bx-link-external links text-[16px] cursor-pointer"
            onClick={() => openMore(agentsList[i])}
          ></i>
        ),
      });
    }
  }

  const [selectedAgent, setSelectedAgent] = useState();
  const [withdrawalTransactions, setWithdrawalTransactions] = useState([]);
  const [depositalTransactions, setDeposits] = useState([]);

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const selectedItem = (id) => {
    console.log("id");
    const withdrawalTransactions = withdrawals?.filter(
      (item) => item.agent_id === id
    );
    const depositalTransactions = deposits?.filter(
      (item) => item.agent_id === id
    );
    setWithdrawalTransactions(withdrawalTransactions);
    setDeposits(depositalTransactions);
  };

  const openMore = (item) => {
    console.log(item);
    selectedItem(item.agent_id);
    setSelectedAgent(item.business_name);
    openSidePanel();
  };

  const displayContent = agentsList ? (
    !agentsList.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );

  // TRANSACTIONS DATA
  const tabs = [
    {
      name: "Withdwals",
      component: <WithdrawalsReport withdrawals={withdrawalTransactions} />,
    },
    {
      name: "Deposits",
      component: <DepositsReport deposits={depositalTransactions} />,
    },
  ];

  return (
    <div>
      {/* header information */}
      <div className="flex sm:flex-col justify-between items-center mb-2">
        <HeaderNav title="Agents report" />
        <div className="flex items-center justify-end">
          <Button text={"Export Excel"} />
          <Button text={"Export PDF"} bgColor={"bg-red-500"} />
        </div>
      </div>

      {displayContent}
      {/* display sidepanel */}
      <SidePanel
        width="75%"
        title={selectedAgent}
        subtitle={`Transactions report`}
      >
        <TabsComponent tabsList={tabs} />
      </SidePanel>
    </div>
  );
}

export default AgentsList;
