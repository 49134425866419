import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import { payments } from "../../constants/data";
import CurrencyFormatter from "../global/CurrencyFormatter";
import DateTimeFormat from "../global/DateTimeFormat";
import { connect } from "react-redux";
import SelectInput from "../global/SelectInput";
import Button from "../global/Button";

function OperatorCancelledTransactionsReport(props) {
  const headers = [
    "Transaction ID",
    "Amount",
    "Transaction date",
    "Client",
    "Account number",
  ];
  let tableRows = [];

  let list = payments?.filter(
    (item) =>
      Number(item.status) === 2 &&
      item.bank_id === props.selectedOperator?.operator_id
  );

  if (list.length) {
    for (let i = 0; i < list.length; i++) {
      tableRows.push({
        payment_id: list[i].payment_id,
        amount: <CurrencyFormatter value={list[i].amount} />,
        created_at: <DateTimeFormat>{list[i].created_at}</DateTimeFormat>,
        client: list[i].client,
        account_number: list[i].account_number,
        more: (
          <i
            className="bx bx-printer links text-[16px] cursor-pointer"
            onClick={() => printStatement(list[i])}
          ></i>
        ),
      });
    }
  }

  const displayContent = list ? (
    <DataTable headers={headers} data={tableRows} />
  ) : (
    <Loader />
  );

  const [selectedFrequency, setSelectedFrequency] = useState(1);

  useEffect(() => {
    handleAction(selectedFrequency);
  }, [selectedFrequency]);

  let handleAction = (val) => {
    console.log(val);
  };

  const options = [
    { label: "Daily", value: 1 },
    { label: "Weekly", value: 2 },
    { label: "Monthly", value: 3 },
  ];

  const handleChange = (e) => {
    const val = e.target.value;
    setSelectedFrequency(val);
    handleAction(val);
  };

  const printStatement = (id) => {
    console.log(id);
  };

  return (
    <div>
      <div className="flex sm:flex-col justify-between items-center mb-2">
        <div className="w-40 sm:w-full mt-3">
          <SelectInput
            label="Select frequency"
            onChange={handleChange}
            options={options}
            name="switch_frequency"
            value={selectedFrequency}
          />
        </div>
        <div className="flex items-center justify-end">
          <Button text={"Export Excel"} />
          <Button text={"Export PDF"} bgColor={"bg-red-500"} />
        </div>
      </div>
      {displayContent}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedOperator: state.networkOperators?.selectedOperator ?? {},
  };
};

export default connect(mapStateToProps)(OperatorCancelledTransactionsReport);
