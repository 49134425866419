/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderNav from "../global/HeaderNav";
import DataTable from "../datatable/DataTable";
import SidePanel from "../global/SidePanel";
import {
  approveAgentDocsAction,
  openAddSideModal,
  sideModalClose,
} from "../../redux/Index";
import Button from "../global/Button";
import { ToastContainer, toast } from "react-toastify";

function ApproveAgentAccount() {
  const dispatch = useDispatch();
  const docsState = useSelector((state) => state?.documents);
  const selectedAgent = useSelector((state) => state?.agents?.selectedAgent);
  const [userDocs, setUserDocs] = useState(null);
  useEffect(() => {
    setUserDocs(
      docsState?.documents?.filter(
        (item) => Number(item.uploaded_by) === Number(selectedAgent?.client)
      )
    );
  }, [docsState]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const openFile = (item) => {
    setSelectedFile(item);
    dispatch(openAddSideModal());
  };

  const approveAccount = (status) => {
    setLoading(true);
    const data = {
      agent_id: selectedAgent?.id,
      document_id: selectedFile?.id,
      status: status ? true : false,
    };
    dispatch(approveAgentDocsAction(data)).then((response) => {
      if (response.status) {
        setLoading(false);
        dispatch(sideModalClose());
        toast.success(response.data);
        const newList = [...userDocs];
        const foundIndex = newList?.findIndex(
          (item) => item.id === selectedFile.id
        );
        newList[foundIndex].status = status ? true : false;
        setUserDocs(newList);
      } else {
        setLoading(false);
        toast.error(response.data);
      }
    });
  };

    const headers = ["File name", "Uploaded", "Status", "View file"];

    let tableRows = [];
    if (userDocs?.length) {
      for (let i = 0; i < userDocs?.length; i++) {
        const element = userDocs[i];
        tableRows.push({
          type: element.type_display,
          created_at: element.created_at,
          status: (
            <i
              className={`bx ${
                element.status
                  ? "bxs-check-circle text-green-500"
                  : "bx-circle text-red-500"
              } text-[20px] cursor-pointer`}
            ></i>
          ),
          more: (
            <i
              className="bx bx-link-external links text-[16px] cursor-pointer"
              onClick={() => openFile(element)}
            ></i>
          ),
        });
      }
    }

  return (
    <div>
      {/* page title */}
      <div className="flex items-center justify-between mb-2">
        <HeaderNav
          title={`Approve ${selectedAgent?.business_name}'s account`}
        />
      </div>
      <DataTable headers={headers} data={tableRows} />
      {/* view file */}
      <SidePanel
        width="50%"
        title={`View ${selectedFile?.type}`}
        subtitle={`${selectedAgent?.business_name}`}
      >
        <div className="w-full h-[100%] flex-col justify-between">
          <div className="flex-1">
            <img
              src={selectedFile?.file}
              className="w-full rounded-sm"
              alt="file"
            />
          </div>
          <div className="mt-4 border-t border-gray-200 w-full justify-between items-center flex-row py-4">
            <Button
              bgColor={"bg-primary"}
              text={"Reject"}
              onClick={() => approveAccount(0)}
              disabled={loading}
            />

            <Button
              text={"Approve"}
              onClick={() => approveAccount(1)}
              disabled={loading}
            />
          </div>
        </div>
      </SidePanel>
      <ToastContainer />
    </div>
  );
}

export default ApproveAgentAccount;
