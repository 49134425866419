import {
  SET_ANALYSIS,
  SET_CHART_ANALYSIS,
  SET_TRANSACTIONS_ANALYSIS,
  TOGGLE_FETCHING_ANALYSIS,
  TOGGLE_FETCHING_CHART_ANALYSIS,
  TOGGLE_FETCHING_TRANSACTIONS_ANALYSIS,
} from "./dashboardActionTypes";

// set the initial state
const initialState = () => {
  return {
    fetchingDashboardAnalysis: false,
    dashboardAnalysis: [],
    fetchingChartAnalysis: false,
    chartAnalysis: [],
    fetchingTransactionsAnalysis: false,
    transactionsAnalysis: [],
  };
};

// set the reducer
const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FETCHING_ANALYSIS:
      return {
        ...state,
        fetchingDashboardAnalysis: action.payload,
      };
    case SET_ANALYSIS:
      return {
        ...state,
        dashboardAnalysis: action.payload,
      };

    case TOGGLE_FETCHING_CHART_ANALYSIS:
      return {
        ...state,
        fetchingChartAnalysis: action.payload,
      };
    case SET_CHART_ANALYSIS:
      return {
        ...state,
        chartAnalysis: action.payload,
      };

    case TOGGLE_FETCHING_TRANSACTIONS_ANALYSIS:
      return {
        ...state,
        fetchingTransactionsAnalysis: action.payload,
      };
    case SET_TRANSACTIONS_ANALYSIS:
      return {
        ...state,
        transactionsAnalysis: action.payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
