import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import { bankList } from "../../constants/data";
import FabButton from "../global/FabButton";
import SidePanel from "../global/SidePanel";
import { useDispatch } from "react-redux";
import { openAddSideModal, setSelectedbankAction } from "../../redux/Index";
import TextInput from "../global/TextInput";
import Button from "../global/Button";
import HeaderText from "../global/HeaderText";
import FullDateFormat from "../global/FullDateFormat";
import { Link } from "react-router-dom";

function BanksList() {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const dispatch = useDispatch();

  const headers = [
    "Logo",
    "Bank name",
    "Paybill number",
    "Commission",
    "Date created",
    "Edit",
    "Transactions",
  ];
  let tableRows = [];
  if (bankList.length) {
    for (let i = 0; i < bankList.length; i++) {
      tableRows.push({
        logo: (
          <img
            src={bankList[i].logo}
            className="w-10 h-10 rounded-full"
            alt="Uimage"
          />
        ),
        bank_name: bankList[i].bank_name,
        paybill_number: bankList[i].paybill_number,
        commission: bankList[i].commission,
        created_at: <FullDateFormat>{bankList[i].created_at}</FullDateFormat>,
        edit: (
          <i
            className="bx bx-pencil links text-[16px] cursor-pointer"
            onClick={() => editItem(bankList[i])}
          ></i>
        ),
        more: (
          <Link to="/banks/transactions">
            <i
              className="bx bx-link-external links text-[16px] cursor-pointer"
              onClick={() => setSelectedbank(bankList[i])}
            ></i>
          </Link>
        ),
      });
    }
  }

  useEffect(() => {
    if (isEdit && selectedItem) {
      setInputValues({
        logo: selectedItem.logo,
        bank_name: selectedItem.bank_name,
        paybill_number: selectedItem.paybill_number,
        commission: selectedItem.commission,
      });
    }
  }, [isEdit, selectedItem]);

  const editItem = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    openSidePanel();
  };

  const setSelectedbank = (item) => {
    dispatch(setSelectedbankAction(item));
  };

  const [inputValues, setInputValues] = useState({
    logo: "",
    bank_name: "",
    paybill_number: "",
    commission: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (isSubmit) {
      console.log("submit");
    }
  };

  const displayContent = bankList ? (
    !bankList.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const fabIconClick = () => {
    openSidePanel();
    setIsEdit(false);
    setInputValues({
      logo: "",
      bank_name: "",
      paybill_number: "",
      commission: "",
    });
  };

  return (
    <div>
      <FabButton onPressAction={fabIconClick} />
      {/* page title */}
      <HeaderText title="Banks" />

      <div className="flex items-center justify-start"></div>
      {displayContent}
      {/* display sidepanel */}
      <SidePanel
        width="30%"
        title={isEdit ? selectedItem.bank_name : "Banks"}
        subtitle={isEdit ? "Edit account" : "Add a bank account"}
      >
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            required="required"
            label="Logo"
            name="logo"
            type="text"
            value={inputValues.logo}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Bank name"
            name="bank_name"
            type="text"
            value={inputValues.bank_name}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Paybill number"
            name="paybill_number"
            type="number"
            value={inputValues.paybill_number}
            onChange={handleChange}
          />

          <TextInput
            required="required"
            label="Commission"
            name="commission"
            type="number"
            value={inputValues.commission}
            onChange={handleChange}
          />

          <Button text={isEdit ? "Update" : "Save"} disabled={isSubmit} />
        </form>
      </SidePanel>
    </div>
  );
}

export default BanksList;
