import { countries } from "../../constants/Countries";

const CountryName = ({ id }) => {
  return countries?.length
    ? countries?.find((item) => item.id === Number(id))
      ? countries?.find((item) => item.id === Number(id)).name
      : "Unknown"
    : "Not defined";
};

export default CountryName;
