export const INITIALIZE_TRANSFER_CHARGES_STATE =
  "INITIALIZE_TRANSFER_CHARGES_STATE";

export const TOGGLE_FETCHING_TRANSFER_CHARGES =
  "TOGGLE_FETCHING_TRANSFER_CHARGES";
export const TOGGLE_CREATING_TRANSFER_CHARGE =
  "TOGGLE_CREATING_TRANSFER_CHARGE";
export const TOGGLE_UPDATING_TRANSFER_CHARGE =
  "TOGGLE_UPDATING_TRANSFER_CHARGE";
export const SET_TRANSFER_CHARGES = "SET_TRANSFER_CHARGES";
export const CREATE_TRANSFER_CHARGE = "CREATE_TRANSFER_CHARGE";
export const UPDATE_TRANSFER_CHARGE = "UPDATE_TRANSFER_CHARGE";

export const SET_TRANSFER_CHARGES_SUCCESS_MESSAGE =
  "SET_TRANSFER_CHARGES_SUCCESS_MESSAGE";
export const SET_TRANSFER_CHARGES_ERROR_MESSAGE =
  "SET_TRANSFER_CHARGES_ERROR_MESSAGE";
