import { float_limits } from "../../constants/data";
import CurrencyFormatter from "./CurrencyFormatter";

const FloatLimitValue = ({ id }) => {
  return (
    <CurrencyFormatter
      value={float_limits?.find((item) => item.id === Number(id)).value ?? 0}
    />
  );
};

export default FloatLimitValue;
