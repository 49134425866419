import React from "react";
import { Link } from "react-router-dom";
import HeaderText from "../global/HeaderText";
import { reportsItems } from "./ReportItems";

function ReportsMenu() {
  return (
    <div>
      {/* display header */}
      <HeaderText title="Reports" />

      {/* display the cards for mnu items */}
      <div className="grid grid-cols-5 gap-4 sm:flex sm:flex-col md:grid-cols-3">
        {reportsItems
          .sort((a, b) => a.route.localeCompare(b.route))
          .map((item, index) => (
            <Link key={index} to={"/reports/" + item.route}>
              <div className="border border-gray-200 rounded-lg bg-gray-50 hover:shadow-md min-h-[120px] pt-2 px-4 space-y-2 transition duration-500 hover:scale-y-110">
                <div className="">{item.icon}</div>
                <div className="text-gray-500 text-sm">{item.item}</div>
                <div className="flex justify-end mt-4">
                  <p className="text-xs text-primary">Open report</p>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}

export default ReportsMenu;
