import React from "react";
import { connect } from "react-redux";
import HeaderNav from "../global/HeaderNav";
import TabsComponent from "../global/TabsComponent";
import OperatorCancelledTransactions from "./OperatorCancelledTransactions";
import OperatorCompletedTransactions from "./OperatorCompletedTransactions";
import OperatorReversedTransactions from "./OperatorReversedTransactions";

function OperatorTransactionsHistory(props) {
  const tabs = [
    { name: "Completed", component: <OperatorCompletedTransactions /> },
    { name: "Reversals", component: <OperatorReversedTransactions /> },
    { name: "Cancelled", component: <OperatorCancelledTransactions /> },
  ];
  return (
    <div>
      <HeaderNav
        title={
          props.selectedOperator?.operator_name + " Transactions" ??
          "Unknown operator"
        }
      />
      <TabsComponent tabsList={tabs} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedOperator: state.networkOperators?.selectedOperator ?? {},
  };
};

export default connect(mapStateToProps)(OperatorTransactionsHistory);
