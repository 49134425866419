import React from "react";

function DashboardSegment(props) {
  return (
    <div className="grow w-1/2 sm:w-full md:w-full min-h-[200px] sm:min-h-0 md:min-h-0 md:h-auto border border-gray-200 shadow-sm rounded-md py-2">
      <div className="flex justify-between border-b border-gray-200 px-4 pb-2">
        <div className=" text-gray-400 font-semibold text-sm">
          {props.header}
        </div>
      </div>
      <div className="px-4 py-1">{props.children}</div>
    </div>
  );
}

export default DashboardSegment;
