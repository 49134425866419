import React, { useState } from "react";
import { months } from "../../constants/data";
import CurrencyFormatter from "../global/CurrencyFormatter";
import SelectInput from "../global/SelectInput";

const HeaderInfo = ({ commission, action }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  //   handle select field change
  const handleChange = (e) => {
    const val = e.target.value;
    setSelectedMonth(val);
    action(val);
  };

  //  months select input options
  let options = [{ value: "", label: "-- select --" }];

  if (months) {
    for (let i = 0; i < months.length; i++) {
      options.push({
        value: months[i].number,
        label: months[i].name_long,
      });
    }
  }

  return (
    <div className="flex h-20 items-center justify-between my-2">
      <div className="">
        <SelectInput
          label="Select month"
          onChange={handleChange}
          options={options}
          name="switch_month"
          value={selectedMonth}
        />
      </div>
      <div className="-mt-7 text-gray-700">
        {" "}
        Commission : <CurrencyFormatter value={commission} />
      </div>
    </div>
  );
};

export default HeaderInfo;
