/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import CurrencyFormatter from "../global/CurrencyFormatter";
import { useDispatch, useSelector } from "react-redux";
import ApiDataTable from "../datatable/ApiDataTable";
import { fetchWithdrawalRequestsAction } from "../../redux/Index";

function WithdrawalRequests() {
  const dispatch = useDispatch();
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const withdrawalRequestsState = useSelector((state) => state?.transactions);

  useEffect(() => {
    getWithdrawalRequests(10, 1);
  }, []);

  const getWithdrawalRequests = (rowsPerPage, page) => {
    dispatch(fetchWithdrawalRequestsAction(rowsPerPage, page));
  };

  useEffect(() => {
    setWithdrawalRequests(withdrawalRequestsState?.withdrawalRequests);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawalRequestsState?.withdrawalRequests]);

  const headers = [
    "Reference No.",
    "Client",
    "Client's phone number",
    "Agent",
    "Agent's phone number",
    "Business name",
    "Amount withdrawn",
    "Approved",
    "Approval date",
  ];
  let tableRows = [];
  const list = withdrawalRequests?.data ? [...withdrawalRequests.data] : [];

  if (list?.length) {
    for (let i = 0; i < list?.length; i++) {
      const element = list[i];
      tableRows.push({
        ref: element?.ref,
        client: element?.client_full_name,
        c_phone_number: element?.client_phone_number,
        agent: element?.agent_full_name,
        ag_phone_number: element?.agent_phone_number,
        business: element?.business,
        amount: <CurrencyFormatter value={element?.amount ?? 0} />,
        approved: element?.approved ? "Yes" : "No",
        approved_at: element?.approved_at ? element?.approved_at : "N/A",
      });
    }
  }

  const displayContent =
    !withdrawalRequestsState?.fetchingWithdrawalRequests ? (
      <ApiDataTable
        headers={headers}
        data={tableRows}
        loadDataAction={getWithdrawalRequests}
        totalPageCount={withdrawalRequests?.last_page}
      />
    ) : (
      <Loader />
    );

  return (
    <div>
      {/* header information */}
      <div className="flex flex-row items-center justify-end my-2">
        <p className="text-gray-700 text-sm">
          Total withdrawal requests:&nbsp;{" "}
        </p>
        <CurrencyFormatter
          value={withdrawalRequests?.total_amount_transacted ?? 0}
        />
      </div>
      {displayContent}
    </div>
  );
}

export default WithdrawalRequests;
