import React from "react";
import GridWrapper from "./GridWrapper";
import DashboardSegment from "./DashboardSegment";
import CommissionsRevenue from "./CommissionsRevenue";
import TransactionVolumesDoughnut from "./TransactionVolumesDoughnut";

function TransactionsGrid() {
  return (
    <div>
      <GridWrapper>
        <DashboardSegment header="Commissions revenue">
          <CommissionsRevenue />
        </DashboardSegment>
        <DashboardSegment header="Transaction volumes">
          <TransactionVolumesDoughnut />
        </DashboardSegment>
      </GridWrapper>
    </div>
  );
}

export default TransactionsGrid;
