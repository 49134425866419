import { SET_SELECTED_OPERATOR } from "./operatorActionTypes";

// set the initial state
const initialState = () => {
  return {
    selectedOperator: {},
  };
};

const networkOperatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_OPERATOR:
      return { selectedOperator: action.payload };

    default:
      return state;
  }
};

export default networkOperatorReducer;
