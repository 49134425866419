import DashboardClient from "../../api/DashboardClient";
import {
  SET_ANALYSIS,
  SET_CHART_ANALYSIS,
  SET_TRANSACTIONS_ANALYSIS,
  TOGGLE_FETCHING_ANALYSIS,
  TOGGLE_FETCHING_CHART_ANALYSIS,
  TOGGLE_FETCHING_TRANSACTIONS_ANALYSIS,
} from "./dashboardActionTypes";

export const toggleDashboardFetchingAnalysis = (status) => {
  return {
    type: TOGGLE_FETCHING_ANALYSIS,
    payload: status,
  };
};

export const setDashboardAnalysis = (data) => {
  return {
    type: SET_ANALYSIS,
    payload: data,
  };
};

export const toggleFetchingChartAnalysis = (status) => {
  return {
    type: TOGGLE_FETCHING_CHART_ANALYSIS,
    payload: status,
  };
};

export const setChartAnalysis = (data) => {
  return {
    type: SET_CHART_ANALYSIS,
    payload: data,
  };
};

export const toggleFetchingTransactionsAnalysis = (status) => {
  return {
    type: TOGGLE_FETCHING_TRANSACTIONS_ANALYSIS,
    payload: status,
  };
};

export const setTransactionsAnalysis = (data) => {
  return {
    type: SET_TRANSACTIONS_ANALYSIS,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchingDashboardAnalysisAction() {
  return function action(dispatch, getState) {
    const { userInfo } = getState().auth;
    dispatch(toggleDashboardFetchingAnalysis(true));

    return DashboardClient.fetchAnalysis(userInfo?.id)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setDashboardAnalysis(response.data.data));
          dispatch(toggleDashboardFetchingAnalysis(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleDashboardFetchingAnalysis(false));
      });
  };
}

export function fetchChartsAnalysisAction() {
  return function action(dispatch) {
    dispatch(toggleFetchingChartAnalysis(true));

    return DashboardClient.fetchChartsAnalysis()
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setChartAnalysis(response.data.data));
          dispatch(toggleFetchingChartAnalysis(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingChartAnalysis(false));
      });
  };
}

export function fetchTransactionsAnalysisAction() {
  return function action(dispatch) {
    dispatch(toggleFetchingTransactionsAnalysis(true));

    return DashboardClient.fetchTransactionsAnalysis()
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setTransactionsAnalysis(response.data.data));
          dispatch(toggleFetchingTransactionsAnalysis(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingTransactionsAnalysis(false));
      });
  };
}
