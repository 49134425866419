import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import { withdrawals } from "../../constants/data";
import CurrencyFormatter from "../global/CurrencyFormatter";
import DateTimeFormat from "../global/DateTimeFormat";
import { withdrawal_commissions_unregistered } from "../../constants/Tariffs";
import SelectInput from "../global/SelectInput";
import Button from "../global/Button";

function WithdrawalsReport() {
  const headers = [
    "Transaction ID",
    "Client",
    "Client ID",
    "Amount",
    "Commission",
    "Type",
    "Date",
  ];
  let tableRows = [];

  let commission = 0;

  if (withdrawals.length) {
    for (let i = 0; i < withdrawals.length; i++) {
      const commissionEarned =
        withdrawals[i].type > 1
          ? withdrawal_commissions_unregistered(withdrawals[i].amount_withdrawn)
          : withdrawal_commissions_unregistered(
              withdrawals[i].amount_withdrawn
            );
      commission = commission += commissionEarned;
      tableRows.push({
        withdrawal_id: withdrawals[i].withdrawal_id,
        client_name: withdrawals[i].client_name,
        client_id: withdrawals[i].client_id_number,
        amount_withdrawn: (
          <CurrencyFormatter value={withdrawals[i].amount_withdrawn} />
        ),
        commision: <CurrencyFormatter value={commissionEarned} />,
        type: withdrawals[i].type > 1 ? "Unregistered user" : "Registered user",
        created_at: (
          <DateTimeFormat>{withdrawals[i].created_at}</DateTimeFormat>
        ),
      });
    }
  }

  const [selectedFrequency, setSelectedFrequency] = useState(1);

  useEffect(() => {
    handleAction(selectedFrequency);
  }, [selectedFrequency]);

  let handleAction = (val) => {
    console.log(val);
  };

  const displayContent = withdrawals ? (
    !withdrawals.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );

  const options = [
    { label: "Daily", value: 1 },
    { label: "Weekly", value: 2 },
    { label: "Monthly", value: 3 },
  ];

  const handleChange = (e) => {
    const val = e.target.value;
    setSelectedFrequency(val);
    handleAction(val);
  };

  return (
    <div>
      {/* header information */}
      <div className="flex sm:flex-col justify-between items-center mb-2">
        <div className="w-40 sm:w-full mt-3">
          <SelectInput
            label="Select frequency"
            onChange={handleChange}
            options={options}
            name="switch_frequency"
            value={selectedFrequency}
          />
        </div>
        <div className="flex items-center justify-end">
          <Button text={"Export Excel"} />
          <Button text={"Export PDF"} bgColor={"bg-red-500"} />
        </div>
      </div>
      {displayContent}
    </div>
  );
}

export default WithdrawalsReport;
