import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import Loader from "../global/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    x: {
      drawBorder: true, // <-- this removes y-axis line
      lineWidth: 0.5,
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
      },
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

function NumberOfClientsChart() {
  const dashboardState = useSelector((state) => state.dashboard);
  const [data, setData] = useState(null);

  const findMonthlyData = (month) => {
    const dataArray = dashboardState.chartAnalysis?.clients;
    const index = dataArray?.findIndex((el) => el.month === month);
    if (index > -1) {
      return dataArray[index].count;
    } else return 0;
  };

  useEffect(() => {
    const dataList = [];
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    months.forEach((month) => {
      dataList.push(findMonthlyData(month));
    });
    setData(dataList);
    // eslint-disable-next-line
  }, [dashboardState.chartAnalysis]);

  const revenueByMonths = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    data: data,
  };

  const chartData = {
    labels: revenueByMonths.labels,
    datasets: [
      {
        label: "Registered clients",
        data: revenueByMonths.data,
        lineTension: 0.4,
      },
    ],
  };

  return (
    <div className="">
      {dashboardState.fetchingChartAnalysis ? (
        <Loader />
      ) : (
        <Line options={options} data={chartData} height={`100px`} />
      )}
    </div>
  );
}

export default NumberOfClientsChart;
