/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import FabButton from "../global/FabButton";
import SidePanel from "../global/SidePanel";
import { connect, useDispatch } from "react-redux";
import {
  closeAddSideModal,
  createDepartmentAction,
  fetchDepartmentsAction,
  openAddSideModal,
  updateDepartmentAction,
} from "../../redux/Index";
import TextInput from "../global/TextInput";
import Button from "../global/Button";
import { ToastContainer, toast } from "react-toastify";

function Departments(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [departments, setDepartments] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    loadDepartments();
  }, []);

  useEffect(() => {
    setDepartments(props.permissions?.departments);
  }, [props.permissions]);

  const loadDepartments = () => {
    dispatch(fetchDepartmentsAction());
  };

  useEffect(() => {
    if (isSubmit) {
      if (isEdit) {
        if (props.permissions?.processSet) {
          setIsSubmit(false);
          closeSidePanel();
          toast.success("Department updated successfully");
        } else {
          toast.error("Failed to update department");
        }
      } else {
        if (props.permissions?.processSet) {
          setIsSubmit(false);
          closeSidePanel();
          toast.success("Department created");
        } else {
          toast.error("Failed to create department");
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.permissions?.departments]);

  const headers = ["Department name", "Edit"];
  let tableRows = [];
  if (departments?.length) {
    for (let i = 0; i < departments?.length; i++) {
      tableRows.push({
        name: departments[i].name,
        edit: (
          <i
            className="bx bx-edit links text-[16px] cursor-pointer"
            onClick={() => editDepartmentDetails(departments[i])}
          ></i>
        ),
      });
    }
  }

  useEffect(() => {
    if (isEdit && selectedItem) {
      setInputValues({
        name: selectedItem.name,
      });
    }
  }, [isEdit, selectedItem]);

  const editDepartmentDetails = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    openSidePanel();
  };

  const [inputValues, setInputValues] = useState({
    name: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (inputValues.name !== "") {
      if (isEdit) {
        inputValues.group_id = selectedItem.id;
        dispatch(updateDepartmentAction(inputValues));
      } else {
        dispatch(createDepartmentAction(inputValues));
      }
    }
  };

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const closeSidePanel = () => {
    dispatch(closeAddSideModal());
  };

  const fabIconClick = () => {
    openSidePanel();
    setIsEdit(false);
    setInputValues({
      name: "",
    });
  };

  if (props.permissions?.fetchingDepartments) {
    return <Loader />;
  }

  return (
    <div className="flex-1 h-full">
      <FabButton onPressAction={fabIconClick} />
      {/* load items in table */}
      <DataTable headers={headers} data={tableRows} />
      {/* display sidepanel */}
      <SidePanel width="30%" title="Departments" subtitle="Create a Department">
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            required="required"
            label="Department name"
            name="name"
            type="text"
            value={inputValues.name}
            onChange={handleChange}
          />
          <Button
            text={isSubmit ? "Processing..." : isEdit ? "Update" : "Save"}
            disabled={isSubmit}
          />
        </form>
      </SidePanel>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions ? state.permissions : [],
  };
};

export default connect(mapStateToProps)(Departments);
