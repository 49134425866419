import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Clients to agents", "Clients to banks", "Clients to merchants"],
  datasets: [
    {
      label: "Commission",
      data: [12, 19, 3],
      backgroundColor: [
        "rgba(86, 0, 95, 0.8)",
        "rgba(255, 125, 0, 0.8)",
        "rgba(5, 100, 18, 0.8)",
      ],
      borderColor: [
        "rgba(86, 0, 95, 0.8)",
        "rgba(255, 125, 0, 0.8)",
        "rgba(5, 100, 18, 0.8)",
      ],
      borderWidth: 1,
    },
  ],
};

export default function TransactionVolumesDoughnut() {
  return (
    <Doughnut
      width={"100%"}
      options={{ maintainAspectRatio: false }}
      data={data}
    />
  );
}
