export const INITIALIZE_CLIENTS_STATE = "INITIALIZE_CLIENTS_STATE";

export const TOGGLE_FETCHING_CLIENTS = "TOGGLE_FETCHING_CLIENTS";
export const TOGGLE_UPDATING_CLIENT = "TOGGLE_UPDATING_CLIENT";
export const SET_CLIENTS = "SET_CLIENTS";
export const UPDATE_CLIENT = "UPDATE_CLIENT";

export const TOGGLE_FETCHING_SAVINGS = "TOGGLE_FETCHING_SAVINGS";
export const SET_SAVINGS = "SET_SAVINGS";

export const SET_CLIENT_SUCCESS_MESSAGE = "SET_CLIENT_SUCCESS_MESSAGE";
export const SET_CLIENT_ERROR_MESSAGE = "SET_CLIENT_ERROR_MESSAGE";

export const APPROVE_CLIENT_DOCUMENTS = "APPROVE_CLIENT_DOCUMENTS";
export const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT";

export const UPDATE_USER_PROFILE_PIC = "UPDATE_USER_PROFILE_PIC";
