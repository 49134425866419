import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { closeAddSideModal } from "../../redux/Index";
import useWindowDimensions from "../hooks/useWindowDimensions";

function SidePanel({ sidePanelOpen, title, subtitle, width, children }) {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(sidePanelOpen);
  }, [sidePanelOpen]);

  let closeSidePanel = () => {
    dispatch(closeAddSideModal());
  };

  return (
    <SlidingPane
      className="sideModal"
      overlayClassName="sideModal-overlay"
      isOpen={modalOpen}
      title={title ?? ""}
      subtitle={subtitle ?? ""}
      width={useWindowDimensions().width > 768 ? width : "100%"}
      onRequestClose={() => {
        closeSidePanel();
      }}
    >
      <div className="pb-8">{children}</div>
    </SlidingPane>
  );
}

const mapStateToProps = (state) => {
  return {
    sidePanelOpen: state.sidePanel ? state.sidePanel?.panelOpen : false,
  };
};

export default connect(mapStateToProps)(SidePanel);
