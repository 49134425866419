import React from "react";
import Button from "./Button";

function PromptModal({
  setPromptOpen,
  open,
  setOpenModal,
  action,
  title,
  loading,
  bodyText,
}) {
  let onClose = () => {
    setPromptOpen(false);
    setOpenModal(false);
  };
  return (
    <div>
      <div
        className={`${
          open ? "flex" : "hidden"
        } flex-col sm:left-[5%] md:left-[30%] left-[37%] top-[37%] h-40 w-[30%] sm:w-[90%] md:w-[40%] z-[999] bg-white bg-opacity-1 px-4 py-2 absolute rounded-lg ease-in-out duration-700`}
      >
        <div className="text-gray-500 justify-between items-center border-b border-gray-100 flex ">
          {title}
          <i
            onClick={onClose}
            className="bx bx-x cursor-pointer text-2xl hover:bg-gray-300 hover:rounded-full"
          ></i>
        </div>
        <div className="flex-1 items-center justify-center flex font-semibold text-gray-500 text-sm">
          {bodyText}
        </div>
        <div className="flex justify-between text-white font-semibold mb-2">
          <Button
            bgColor={"bg-red-700"}
            type="button"
            text="Cancel"
            onClick={onClose}
          />
          <Button
            bgColor={"bg-green-700"}
            type="button"
            text="Continue"
            onClick={action}
            disabled={loading}
          />
        </div>
      </div>
      <div
        style={{ zIndex: 100 }}
        className={`${
          open ? "flex bg-black opacity-60" : "hidden"
        } justify-center items-center overflow-hidden w-screen h-screen fixed  inset-0 outline-none focus:outline-none`}
      ></div>
    </div>
  );
}

export default PromptModal;
