import {
  CREATE_WITHDRAWAL_CHARGE,
  INITIALIZE_WITHDRAWAL_CHARGES_STATE,
  SET_WITHDRAWAL_CHARGES,
  SET_WITHDRAWAL_CHARGES_ERROR_MESSAGE,
  SET_WITHDRAWAL_CHARGES_SUCCESS_MESSAGE,
  TOGGLE_CREATING_WITHDRAWAL_CHARGE,
  TOGGLE_FETCHING_WITHDRAWAL_CHARGES,
  TOGGLE_UPDATING_WITHDRAWAL_CHARGE,
  UPDATE_WITHDRAWAL_CHARGE,
} from "./actionTypes";

// set the initial state
const initialState = () => {
  return {
    success: "",
    error: "",
    fetchingWithdrawalCharges: false,
    creatingWithdrawalCharge: false,
    updatingWithdrawalCharge: false,
    withdrawalCharges: [],
    processSet: false,
  };
};

// set the reducer
const withdrawalChargesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_WITHDRAWAL_CHARGES_STATE:
      return {
        ...state,
        success: "",
        error: "",
        processSet: false,
      };
    /* falls through */
    case TOGGLE_FETCHING_WITHDRAWAL_CHARGES:
      return {
        ...state,
        fetchingWithdrawalCharges: action.payload,
      };
    /* falls through */
    case TOGGLE_CREATING_WITHDRAWAL_CHARGE:
      return {
        ...state,
        creatingWithdrawalCharge: action.payload,
      };
    /* falls through */
    case TOGGLE_UPDATING_WITHDRAWAL_CHARGE:
      return {
        ...state,
        updatingWithdrawalCharge: action.payload,
      };
    /* falls through */
    case SET_WITHDRAWAL_CHARGES:
      return {
        ...state,
        withdrawalCharges: action.payload,
      };
    /* falls through */
    case CREATE_WITHDRAWAL_CHARGE:
      let newList = [...state.withdrawalCharges];
      newList.unshift(action.payload);
      return {
        ...state,
        // WithdrawalCharges: [...state.WithdrawalCharges, action.payload],
        withdrawalCharges: newList,
        processSet: true,
      };
    /* falls through */
    case UPDATE_WITHDRAWAL_CHARGE:
      let newWithdrawalCharges = [...state.withdrawalCharges];
      let foundIndex = newWithdrawalCharges?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      newWithdrawalCharges[foundIndex] = action.payload;
      return {
        ...state,
        withdrawalCharges: newWithdrawalCharges,
        processSet: true,
      };
    /* falls through */

    case SET_WITHDRAWAL_CHARGES_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.payload,
      };
    /* falls through */
    case SET_WITHDRAWAL_CHARGES_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default withdrawalChargesReducer;
