import React from "react";
import { useNavigate } from "react-router-dom";
import HeaderText from "./HeaderText";

function HeaderNav({ title }) {
  const navigation = useNavigate();
  return (
    <div className="flex space-x-4">
      <i
        onClick={() => navigation(-1)}
        className="bx bx-chevron-left text-secondary text-3xl bottom-4 cursor-pointer"
      ></i>
      <HeaderText title={title} />
    </div>
  );
}

export default HeaderNav;
