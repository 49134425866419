import React from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import { usersList } from "../../constants/data";
import DepartmentName from "../admin/DepartmentName";
import RoleName from "../admin/RoleName";
import SidePanel from "../global/SidePanel";
import { useDispatch } from "react-redux";
import { openAddSideModal } from "../../redux/Index";
import { useState } from "react";
import HeaderNav from "../global/HeaderNav";
import Button from "../global/Button";
import { menuItems } from "../../configs/SidebarNav";

function SystemUsers() {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedUser, setSelectedUser] = useState("");
  const headers = [
    "Image",
    "Name",
    "email",
    "Phone",
    "Address",
    "ID #",
    "Department",
    "Roles",
    "Edit",
  ];
  let tableRows = [];
  if (usersList.length) {
    for (let i = 0; i < usersList.length; i++) {
      tableRows.push({
        profile_image: (
          <img
            src={usersList[i].profile_image}
            className="w-10 h-10 rounded-full"
            alt="Uimage"
          />
        ),
        name: usersList[i].first_name + " " + usersList[i].last_name,
        email: usersList[i].email,
        phone: usersList[i].phone,
        address: usersList[i].address,
        in_number: usersList[i].id_number,
        department: <DepartmentName id={usersList[i].department} />,
        role: <RoleName id={usersList[i].role} />,
        edit: (
          <i
            className="bx bx-link-external links text-[16px] cursor-pointer"
            onClick={() => editSelected(usersList[i])}
          ></i>
        ),
      });
    }
  }

  const editSelected = (item) => {
    setSelectedItem(item);
    setSelectedUser(item.first_name + " " + item.last_name);
    openSidePanel();
  };

  const displayContent = usersList ? (
    !usersList.length ? (
      <Loader />
    ) : (
      <DataTable headers={headers} data={tableRows} />
    )
  ) : (
    <Loader />
  );

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const exportPDF = () => {};
  const printUserModules = () => {};

  const isAssigned = (id) => {
    return selectedItem?.assigned_modules?.includes(id);
  };

  return (
    <div>
      {/* header information */}
      <div className="flex flex-row sm:flex-col sm:space-y-2 justify-between items-center my-4">
        <HeaderNav title="System users report" />
        <div className="flex flex-row sm:flex-col sm:space-y-2 justify-end items-center">
          <Button
            onClick={exportPDF}
            text="Export PDF"
            bgColor={"bg-primary"}
          />
        </div>
      </div>

      {displayContent}
      {/* display sidepanel */}
      <SidePanel width="30%" title={selectedUser} subtitle="Assigned modules">
        {/* print users modules link */}
        <div className="flex justify-end items-center space-x-1">
          <div className="flex justify-end items-center space-x-1 cursor-pointer hover:text-blue-600">
            <i
              className="bx bx-printer links text-[18px]"
              onClick={printUserModules}
            ></i>
            <p className="text-sm text-gray-600">Print</p>
          </div>
        </div>

        {/* Display the users module */}
        <div className=" max-h-[100%] overflow-auto divide-y divide-gray-100">
          {menuItems?.map((item, index) => (
            <div key={index} className="flex items-center space-x-3 py-3 ">
              {isAssigned(item.id) ? (
                <i className="bx bx-checkbox-square text-blue-500 links text-2xl"></i>
              ) : (
                <i className="bx bx-checkbox text-red-500 links text-2xl"></i>
              )}
              <p className="text-sm text-gray-600">{item.label}</p>
            </div>
          ))}
        </div>
      </SidePanel>
    </div>
  );
}

export default SystemUsers;
