export const INITIALIZE_TRANSACTIONS_STATE = "INITIALIZE_TRANSACTIONS_STATE";

export const TOGGLE_FETCHING_TRANSACTIONS = "TOGGLE_FETCHING_TRANSACTIONS";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";

export const SET_TRANSACTION_SUCCESS_MESSAGE =
  "SET_TRANSACTION_SUCCESS_MESSAGE";
export const SET_TRANSACTION_ERROR_MESSAGE = "SET_TRANSACTION_ERROR_MESSAGE";

export const TOGGLE_FETCHING_WALLET_BALANCES =
  "TOGGLE_FETCHING_WALLET_BALANCES";
export const SET_WALLET_BALANCES = "SET_WALLET_BALANCES";

export const TOGGLE_FETCHING_WITHDRAWALS = "TOGGLE_FETCHING_WITHDRAWALS";
export const SET_WITHDRAWALS = "SET_WITHDRAWALS";

export const TOGGLE_FETCHING_WITHDRAWAL_REQUESTS =
  "TOGGLE_FETCHING_WITHDRAWAL_REQUESTS";
export const SET_WITHDRAWAL_REQUESTS = "SET_WITHDRAWAL_REQUESTS";
