import { SET_SELECTED_BANK } from "./bankActionTypes";

// set the initial state
const initialState = () => {
  return {
    selectedBank: {},
  };
};

const banksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_BANK:
      return { selectedBank: action.payload };

    default:
      return state;
  }
};

export default banksReducer;
