// list all the menu items here
export const menuItems = [
  {
    id: 1,
    key: "dashboard",
    label: "Dashboard",
    path: "/home",
    icon: <i className="bx bx-home-alt"></i>,
  },
  {
    id: 2,
    key: "admin",
    label: "Administration",
    path: "/admin",
    icon: <i className="bx bxs-shield"></i>,
  },
  {
    id: 3,
    key: "clients",
    label: "Clients",
    path: "/clients/list",
    icon: <i className="bx bxs-user-check"></i>,
  },
  {
    id: 4,
    key: "agents",
    label: "Agents",
    path: "/agents/list",
    icon: <i className="bx bx-group"></i>,
  },
  {
    id: 5,
    key: "banks",
    label: "Banks",
    path: "/banks/list",
    icon: <i className="bx bxs-bank"></i>,
  },
  {
    id: 6,
    key: "network_operators",
    label: "Network operators",
    path: "/network_operators/list",
    icon: <i className="bx bx-broadcast"></i>,
  },
  {
    id: 7,
    key: "Transactions",
    label: "Transactions",
    path: "/transactions",
    icon: <i className="bx bx-spreadsheet"></i>,
  },
  {
    id: 8,
    key: "payments",
    label: "Bank transfers",
    path: "/payments",
    icon: <i className="bx bx-credit-card"></i>,
  },
  {
    id: 9,
    key: "savings",
    label: "Savings",
    path: "/savings",
    icon: <i className="bx bx-coin-stack"></i>,
  },
  {
    id: 10,
    key: "merchants",
    label: "Merchants",
    path: "/merchants/list",
    icon: <i className="bx bxs-business"></i>,
  },
  {
    id: 11,
    key: "wallets",
    label: "Wallets",
    path: "/wallets",
    icon: <i className="bx bxs-wallet"></i>,
  },
  {
    id: 12,
    key: "withdrawals",
    label: "Withdrawals",
    path: "/withdrawals",
    icon: <i className="bx bx-money-withdraw"></i>,
  },
  {
    id: 13,
    key: "reports",
    label: "Reports",
    path: "/reports/menu",
    icon: <i className="bx bxs-report"></i>,
  },
];

export const bottomMenu = [
  {
    key: "profile",
    label: "Profile",
    path: "/profile",
    icon: <i className="bx bx-user-circle"></i>,
  },
  {
    key: "notifications",
    label: "Notifications",
    path: "/notifications",
    icon: <i className="bx bx-bell"></i>,
  },
];
