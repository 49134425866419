import React from "react";

const FabButton = ({ onPressAction }) => {
  return (
    <button
      onClick={onPressAction}
      className={`bg-primary opacity-50 flex items-center justify-center right-8 h-11 w-11 p-2 rounded-full absolute bottom-8 z-5 shadow-2xl`}
    >
      <div>
        <i className="bx bx-plus text-white text-2xl text-center"></i>
      </div>
    </button>
  );
};

export default FabButton;
