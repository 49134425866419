/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  fetchAnalysis() {
    return apiClient.get(`/dashboard/analysis`);
  },
  fetchChartsAnalysis() {
    return apiClient.get(`/dashboard/analysis/charts`);
  },
  fetchTransactionsAnalysis() {
    return apiClient.get(`/dashboard/transactions/analysis`);
  },
};
