import React from "react";
import HeaderText from "../components/global/HeaderText";
import TabsComponent from "../components/global/TabsComponent";
import WithdrawalRequests from "../components/withdrawals/WithdrawalRequests";
import WithdrawalsList from "../components/withdrawals/WithdrawalsList";

function Withdrawals() {
  const tabs = [
    { name: "Withdrawals", component: <WithdrawalsList /> },
    { name: "Withdrawal requests", component: <WithdrawalRequests /> },
  ];
  return (
    <div className="flex-1 h-full">
      <HeaderText title="Withdrawals" />
      <TabsComponent tabsList={tabs} />
    </div>
  );
}

export default Withdrawals;
