import TransactionsClient from "../../api/TransactionsClient";
import WithDrawalsClient from "../../api/WithdrawalsClient";
import {
  INITIALIZE_TRANSACTIONS_STATE,
  SET_TRANSACTIONS,
  SET_TRANSACTION_ERROR_MESSAGE,
  SET_WALLET_BALANCES,
  SET_WITHDRAWALS,
  SET_WITHDRAWAL_REQUESTS,
  TOGGLE_FETCHING_TRANSACTIONS,
  TOGGLE_FETCHING_WALLET_BALANCES,
  TOGGLE_FETCHING_WITHDRAWALS,
  TOGGLE_FETCHING_WITHDRAWAL_REQUESTS,
} from "./actionTypes";
// set the actions
export const initializeTransactionsState = () => {
  return {
    type: INITIALIZE_TRANSACTIONS_STATE,
  };
};

export const toggleFetchingTransactions = (status) => {
  return {
    type: TOGGLE_FETCHING_TRANSACTIONS,
    payload: status,
  };
};

export const setTransactions = (data) => {
  return {
    type: SET_TRANSACTIONS,
    payload: data,
  };
};

export const setTransactionErrorMessage = (error) => {
  return {
    type: SET_TRANSACTION_ERROR_MESSAGE,
    payload: error,
  };
};

export const toggleFetchingWalletBalances = (status) => {
  return {
    type: TOGGLE_FETCHING_WALLET_BALANCES,
    payload: status,
  };
};

export const setWalletBalances = (data) => {
  return {
    type: SET_WALLET_BALANCES,
    payload: data,
  };
};

export const toggleFetchingWithdrawals = (status) => {
  return {
    type: TOGGLE_FETCHING_WITHDRAWALS,
    payload: status,
  };
};

export const setWithdrawals = (data) => {
  return {
    type: SET_WITHDRAWALS,
    payload: data,
  };
};

export const toggleFetchingWithdrawalRequests = (status) => {
  return {
    type: TOGGLE_FETCHING_WITHDRAWAL_REQUESTS,
    payload: status,
  };
};

export const setWithdrawalRequests = (data) => {
  return {
    type: SET_WITHDRAWAL_REQUESTS,
    payload: data,
  };
};
// ////////////////// DISPATCH ACTIONS /////////////

export function fetchTransactionsAction(rowsPerPage, page) {
  return function action(dispatch) {
    dispatch(initializeTransactionsState);
    if (page === 1) dispatch(toggleFetchingTransactions(true));

    return TransactionsClient.fetchTransactions(rowsPerPage, page)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(setTransactions(response.data));
        }
        dispatch(toggleFetchingTransactions(false));
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(setTransactionErrorMessage("Server error"));
        dispatch(toggleFetchingTransactions(false));
      });
  };
}

export function fetchWalletBalancesAction(rowsPerPage, page) {
  return function action(dispatch) {
    if (page === 1) dispatch(toggleFetchingWalletBalances(true));

    return TransactionsClient.fetchClientsWalletBalances(rowsPerPage, page)
      .then((response) => {
        const resp = response.data;
        // console.log(resp);
        dispatch(setWalletBalances(resp));
        dispatch(toggleFetchingWalletBalances(false));
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingWalletBalances(false));
      });
  };
}

export function fetchWithdrawalsAction(rowsPerPage, page) {
  return function action(dispatch) {
    if (page === 1) dispatch(toggleFetchingWithdrawals(true));

    return WithDrawalsClient.fetchWithdrawals(rowsPerPage, page)
      .then((response) => {
        const resp = response.data;
        // console.log(resp);
        dispatch(setWithdrawals(resp));
        dispatch(toggleFetchingWithdrawals(false));
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingWithdrawals(false));
      });
  };
}

export function fetchClientWithdrawalsAction(rowPerPage, page, clientId) {
  return function action() {
    return WithDrawalsClient.fetchClientWithdrawals(rowPerPage, page, clientId)
      .then((response) => {
        // console.log(response.data);
        return { success: true, data: response.data };
      })
      .catch((error) => {
        // console.log(error.response);
        return { success: false, data: null };
      });
  };
}

export function fetchWithdrawalRequestsAction(rowsPerPage, page) {
  return function action(dispatch) {
    if (page === 1) dispatch(toggleFetchingWithdrawalRequests(true));

    return WithDrawalsClient.fetchWithdrawalRequests(rowsPerPage, page)
      .then((response) => {
        const resp = response.data;
        // console.log(resp);
        dispatch(setWithdrawalRequests(resp));
        dispatch(toggleFetchingWithdrawalRequests(false));
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingWithdrawalRequests(false));
      });
  };
}
