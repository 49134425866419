const CurrencyFormatter = ({ value }) => {
  const currency = "Ksh. ";
  return (
    currency +
    value.toFixed(2).replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    })
  );
};

export const floatCurrencyFormatter = (value) => {
  const currency = "Ksh. ";
  return currency + value.toFixed(2);
};

export default CurrencyFormatter;
