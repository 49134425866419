export const INITIALIZE_USERS_STATE = "INITIALIZE_USERS_STATE";

export const TOGGLE_FETCHING_USERS = "TOGGLE_FETCHING_USERS";
export const TOGGLE_CREATING_USER = "TOGGLE_CREATING_USER";
export const TOGGLE_UPDATING_USER = "TOGGLE_UPDATING_USER";
export const SET_USERS = "SET_USERS";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";

export const SET_USER_SUCCESS_MESSAGE = "SET_USER_SUCCESS_MESSAGE";
export const SET_USER_ERROR_MESSAGE = "SET_USER_ERROR_MESSAGE";
