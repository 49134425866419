import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import CurrencyFormatter from "../global/CurrencyFormatter";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientWithdrawalsAction } from "../../redux/Index";
import { standardDateTime } from "../global/DateUtilities";
import ApiDataTable from "../datatable/ApiDataTable";

function Withdrawals() {
  const dispatch = useDispatch();
  const clientsState = useSelector((state) => state.clients);
  const selectedClient = clientsState?.selectedClient;

  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(null);

  const fetchClientWithdrawals = (rowsPerPage, page) => {
    setLoading(true);
    dispatch(
      fetchClientWithdrawalsAction(rowsPerPage, page, selectedClient)
    ).then((res) => {
      if (res.success) {
        setTransactions(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchClientWithdrawals(10, 1);
    // eslint-disable-next-line
  }, []);

  const headers = [
    "Client",
    "Account number",
    "Transaction type",
    "Credit",
    "Debit",
    "Transaction date",
  ];
  let tableRows = [];
  const list = transactions?.data;

  if (list?.length) {
    for (let i = 0; i < list?.length; i++) {
      const element = list[i];
      tableRows.push({
        client: element?.store?.client?.full_name,
        account_number: element?.store?.client?.phone_number,
        type: element?.type_display,
        cr_amount: <CurrencyFormatter value={element?.cr_amount ?? 0} />,
        dr_amount: <CurrencyFormatter value={element?.dr_amount ?? 0} />,
        created_at: standardDateTime(element.created_at),
      });
    }
  }

  const displayContent = !loading ? (
    <ApiDataTable
      headers={headers}
      data={tableRows}
      loadDataAction={fetchClientWithdrawals}
      totalPageCount={transactions?.last_page}
    />
  ) : (
    <Loader />
  );

  return (
    <div>
      {/* display the data */}
      {displayContent}
    </div>
  );
}

export default Withdrawals;
