export const INITIALIZE_MODULES_STATE = "INITIALIZE_MODULES_STATE";

export const TOGGLE_FETCHING_MODULES = "TOGGLE_FETCHING_MODULES";
export const TOGGLE_CREATING_MODULE = "TOGGLE_CREATING_MODULE";
export const TOGGLE_UPDATING_MODULE = "TOGGLE_UPDATING_MODULE";
export const SET_MODULES = "SET_MODULES";
export const CREATE_MODULE = "CREATE_MODULE";
export const UPDATE_MODULE = "UPDATE_MODULE";

export const SET_MODULE_SUCCESS_MESSAGE = "SET_MODULE_SUCCESS_MESSAGE";
export const SET_MODULE_ERROR_MESSAGE = "SET_MODULE_ERROR_MESSAGE";
