import React from "react";
import { connect } from "react-redux";
import HeaderNav from "../global/HeaderNav";
import TabsComponent from "../global/TabsComponent";
import Deposits from "./Deposits";
import Withdrawals from "./Withdrawals";

function AgentsTransactionsHistory(props) {
  const tabs = [
    { name: "Withdwals", component: <Withdrawals /> },
    { name: "Deposits", component: <Deposits /> },
  ];
  return (
    <div>
      <HeaderNav
        title={
          props.selectedAgent.business_name + " Transactions" ?? "Unknown agent"
        }
      />
      <TabsComponent tabsList={tabs} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedAgent: state.agents?.selectedAgent ?? {},
  };
};

export default connect(mapStateToProps)(AgentsTransactionsHistory);
