import React, { useEffect, useState } from "react";
import HeaderNav from "../global/HeaderNav";
import TextInput from "../global/TextInput";
import Button from "../global/Button";
import FullDateFormat from "../global/FullDateFormat";
import CurrencyFormatter from "../global/CurrencyFormatter";

function RevenueReport() {
  const [clientTransactions, setClientTransactions] = useState();
  const [agentsCommission, setAgentsCommission] = useState();
  const [bankTransfers, setBankTransfers] = useState();
  const [merchantCommissions, setMerchantCommissions] = useState();

  useEffect(() => {
    setClientTransactions(2000);
    setAgentsCommission(300);
    setBankTransfers(6900);
    setMerchantCommissions(6300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = [
    {
      title: "Commission from clients transactions",
      value: clientTransactions,
    },
    { title: "Commission from agents transactions", value: agentsCommission },
    { title: "Commission from bank transfers", value: bankTransfers },
    {
      title: "Commission from merchant transactions",
      value: merchantCommissions,
    },
  ];

  const [inputValues, setInputValues] = useState({
    start_date: "",
    end_date: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [showData, setShowData] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  let loadData = () => {
    setIsSubmit(false);
    setShowData(true);
  };

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  //submit the form
  const handleSubmit = () => {
    setIsSubmit(true);

    if (
      inputValues.start_date !== "" &&
      inputValues.end_date !== "" &&
      isSubmit
    ) {
      loadData();
    }
  };

  const exportPDF = () => {
    console.log("print pdf");
  };
  return (
    <div>
      {/* display header */}
      <HeaderNav title="Revenues report" />

      {/* filters */}
      <div className="flex justify-center space-x-6">
        <div className="flex space-x-4 flex-row items-center">
          <TextInput
            required="required"
            label="From"
            name="start_date"
            type="date"
            value={inputValues.start_date}
            onChange={handleChange}
          />
        </div>
        <div className="flex space-x-4 flex-row items-center">
          <TextInput
            required="required"
            label="To"
            name="end_date"
            type="date"
            value={inputValues.end_date}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Display data */}
      {showData && (
        <div>
          <div className="mx-[9%] md:mx-0 sm:mx-0">
            <div className="flex flex-row sm:flex-col sm:space-y-2 justify-between items-center m-4">
              <div className="text-xs text-gray-600">
                <FullDateFormat>{inputValues.start_date}</FullDateFormat> &ensp;
                - &ensp; <FullDateFormat>{inputValues.end_date}</FullDateFormat>
              </div>
              <Button
                onClick={exportPDF}
                text="Export PDF"
                bgColor={"bg-primary"}
              />
            </div>
          </div>

          {/* Display data */}
          <div className="mx-[10%] md:mx-0 sm:mx-0 divide-y divide-gray-200 my-8 p-6 border border-gray-200 rounded-md">
            {data.map((item, index) => (
              <div key={index} className="space-x-4 flex py-4 items-center">
                <p className="w-1/2 text-gray-500 text-sm">{item.title}</p>
                <p className="w-1/2 text-gray-500 text-sm">
                  <CurrencyFormatter value={item?.value} />
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default RevenueReport;
