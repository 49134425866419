/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  //all documents
  fetchDocuments() {
    return apiClient.get("/documents/list");
  },
  getClientProfilePic(id) {
    return apiClient.get(`/documents/list?client_id=${id}&type="PROF"`);
  },
  getClientDocs(id) {
    return apiClient.get(`/documents/list?client_id=${id}`);
  },
  verifyClientDocument(data) {
    return apiClient.patch(`/documents/client/verify`, data);
  },
  verifyAgentDocument(data) {
    return apiClient.patch(`/documents/agent/verify`, data);
  },
};
