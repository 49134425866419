import {
  CREATE_USER,
  INITIALIZE_USERS_STATE,
  SET_USERS,
  SET_USER_ERROR_MESSAGE,
  SET_USER_SUCCESS_MESSAGE,
  TOGGLE_CREATING_USER,
  TOGGLE_FETCHING_USERS,
  TOGGLE_UPDATING_USER,
  UPDATE_USER,
} from "./actionTypes";

// set the initial state
const initialState = () => {
  return {
    success: "",
    error: "",
    fetchingUsers: false,
    creatingUser: false,
    updatingUser: false,
    users: [],
    processSet: false,
  };
};

// set the reducer
const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_USERS_STATE:
      return {
        ...state,
        success: "",
        error: "",
        processSet: false,
      };
    /* falls through */
    case TOGGLE_FETCHING_USERS:
      return {
        ...state,
        fetchingUsers: action.payload,
      };
    /* falls through */
    case TOGGLE_CREATING_USER:
      return {
        ...state,
        creatingUser: action.payload,
      };
    /* falls through */
    case TOGGLE_UPDATING_USER:
      return {
        ...state,
        updatingUser: action.payload,
      };
    /* falls through */
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    /* falls through */
    case CREATE_USER:
      let newList = [...state.users];
      newList.unshift(action.payload);
      return {
        ...state,
        // users: [...state.users, action.payload],
        users: newList,
        processSet: true,
      };
    /* falls through */
    case UPDATE_USER:
      let newUsers = [...state.users];
      let foundIndex = newUsers?.findIndex(
        (x) => Number(x.id) === Number(action.payload.id)
      );
      newUsers[foundIndex] = action.payload;
      return {
        ...state,
        users: newUsers,
        processSet: true,
      };
    /* falls through */

    case SET_USER_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.payload,
      };
    /* falls through */
    case SET_USER_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default usersReducer;
