import React from "react";
import UsersList from "../components/admin/UsersList";
import Departments from "../components/admin/Departments";
import Roles from "../components/admin/Roles";
import HeaderText from "../components/global/HeaderText";
import TabsComponent from "../components/global/TabsComponent";
import Modules from "../components/admin/Modules";
import TransferCharges from "../components/admin/TransferCharges";
import WithdrawalCharges from "../components/admin/WithdrawalCharges";

function Administration() {
  const tabs = [
    { name: "Modules", component: <Modules /> },
    { name: "Departments", component: <Departments /> },
    { name: "Roles", component: <Roles /> },
    { name: "Users", component: <UsersList /> },
    { name: "Transfer charges", component: <TransferCharges /> },
    { name: "Withdrawal charges", component: <WithdrawalCharges /> },
  ];
  return (
    <div className="flex-1 h-full">
      <HeaderText title="Administration" />
      <TabsComponent tabsList={tabs} />
    </div>
  );
}

export default Administration;
