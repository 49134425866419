import React from "react";
import logo from "../../assets/images/logo.png";
import HeaderText from "./HeaderText";

function BrandHeader({ title }) {
  return (
    <div className="fixed flex flex-col items-center justify-center w-full p-4 bg-white shadow-md">
      <img className="rounded-full w-[5%] sm:w-1/4" src={logo} alt="Logo" />
      <HeaderText title={title} />
    </div>
  );
}

export default BrandHeader;
