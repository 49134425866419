import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Clients", "Merchants", "Bank transfers"],
  datasets: [
    {
      label: "Commision",
      data: [12, 19, 3],
      backgroundColor: [
        "rgba(245, 71, 39, 0.8)",
        "rgba(39, 200, 245, 0.8)",
        "rgba(255, 206, 86, 0.2)",
      ],
      borderColor: [
        "rgba(245, 71, 39, 0.8)",
        "rgba(39, 200, 245, 0.8)",
        "rgba(255, 206, 86, 1)",
      ],
      borderWidth: 1,
    },
  ],
  options: {
    responsive: true,
  },
};

export default function CommissionsRevenue() {
  return (
    <Pie width={"100%"} options={{ maintainAspectRatio: false }} data={data} />
  );
}
