/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import CurrencyFormatter from "../global/CurrencyFormatter";
import { useDispatch, useSelector } from "react-redux";
import ApiDataTable from "../datatable/ApiDataTable";
import { fetchWithdrawalsAction } from "../../redux/Index";

function WithdrawalsList() {
  const dispatch = useDispatch();
  const [withdrawals, setWithdrawals] = useState([]);
  const withdrawalsState = useSelector((state) => state?.transactions);

  useEffect(() => {
    getWithdrawals(10, 1);
  }, []);

  const getWithdrawals = (rowsPerPage, page) => {
    dispatch(fetchWithdrawalsAction(rowsPerPage, page));
  };

  useEffect(() => {
    setWithdrawals(withdrawalsState?.withdrawals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawalsState?.withdrawals]);

  const headers = [
    "Reference No.",
    "Client",
    "Client's phone number",
    "Agent",
    "Agent's phone number",
    "Business name",
    "Amount withdrawn",
    "Approved",
    "Approval date",
  ];
  let tableRows = [];

  if (withdrawals?.data?.length) {
    for (let i = 0; i < withdrawals?.data?.length; i++) {
      const element = withdrawals?.data[i];
      tableRows.push({
        ref: element?.ref,
        client: element?.client_full_name,
        c_phone_number: element?.client_phone_number,
        agent: element?.agent_full_name,
        ag_phone_number: element?.agent_phone_number,
        business: element?.business,
        amount: <CurrencyFormatter value={element?.amount ?? 0} />,
        approved: element?.approved ? "Yes" : "No",
        approved_at: element?.approved_at ? element?.approved_at : "N/A",
      });
    }
  }

  const displayContent = !withdrawalsState?.fetchingWithdrawals ? (
    <ApiDataTable
      headers={headers}
      data={tableRows}
      loadDataAction={getWithdrawals}
      totalPageCount={withdrawals?.last_page}
    />
  ) : (
    <Loader />
  );

  return (
    <div>
      {/* header information */}
      <div className="flex flex-row items-center justify-end my-2">
        <p className="text-gray-700 text-sm">Total withdrawals:&nbsp; </p>
        <CurrencyFormatter value={withdrawals?.total_amount_transacted ?? 0} />
      </div>
      {displayContent}
    </div>
  );
}

export default WithdrawalsList;
