export const INITIALIZE_PERMISSIONS_STATE = "INITIALIZE_PERMISSIONS_STATE";

export const TOGGLE_FETCHING_DEPARTMENTS = "TOGGLE_FETCHING_DEPARTMENTS";
export const TOGGLE_CREATING_DEPARTMENT = "TOGGLE_CREATING_DEPARTMENT";
export const TOGGLE_UPDATING_DEPARTMENT = "TOGGLE_UPDATING_DEPARTMENT";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";

export const TOGGLE_FETCHING_ROLES = "TOGGLE_FETCHING_ROLES";
export const TOGGLE_CREATING_ROLE = "TOGGLE_CREATING_ROLE";
export const TOGGLE_ADDING_ROLE = "TOGGLE_ADDING_ROLE";
export const TOGGLE_ASSIGNING_ROLE = "TOGGLE_ASSIGNING_ROLE";
export const TOGGLE_DELETING_ROLE = "TOGGLE_DELETING_ROLE";

export const SET_ROLES = "SET_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";
export const ASSIGN_ROLE = "ASSIGN_ROLE";
export const ADD_ROLE = "ADD_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
