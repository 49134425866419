export const paymentStatus = (val) => {
  let status;
  switch (val) {
    case 1:
      status = <p className="text-green-700">Completed</p>;
      break;
    case 2:
      status = <p className="text-red-700">Reversed</p>;
      break;
    case 3:
      status = <p className="text-blue-700">Cancelled</p>;
      break;

    default:
      status = <p className="text-secondary-100">Unknown</p>;
      break;
  }
  return status;
};
