import React from "react";
import BrandHeader from "../components/global/BrandHeader";

function Privacy() {
  return (
    <div className="">
      <BrandHeader title="About Us" />
      <div className="p-[11%] sm:px-4 sm:py-[40%] space-y-3">
        <p className="text-base text-justify my-2">
          PayGram is a mobile money service offered by PayGram Company Limited.
          With PayGram, one can electronically facilitate fast, simple, secure,
          affordable and convenient send cash from user to user; deposit and
          withdraw funds from a fellow user or an agent; transfer cash from your
          bank to your PayGram e-wallet; pay for services and utility bills;
          send or receive cash globally; and so much more.
        </p>
        <p className="text-base text-justify my-2">
          Prompted by the ever increasing demand for electronic transfer,
          payment and settlement of commodities in the payment industry, PayGram
          provides an exciting and secure way of performing these functions.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
