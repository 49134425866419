import React from "react";
import { Oval } from "react-loader-spinner";

function Spinner({ size, color }) {
  return (
    <div className="w-full bottom-0 top-0 overflow-hidden flex flex-1 items-center justify-center">
      <Oval
        height={size || 15}
        width={size || 15}
        color={color || "white"}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        // secondaryColor="red"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
}

export default Spinner;
