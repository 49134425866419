import React, { useEffect, useMemo, useState } from "react";
import SearchInput from "../global/SearchInput";
import img from "../../assets/images/no-data-found.png";
import Pagination from "./Pagination";
import SelectInput from "../global/SelectInput";

function DataTable({ headers, data }) {
  let [list, setList] = useState([]);
  let [PageSize, setPageSize] = useState(10);
  let [displayData, setDisplayData] = useState(data);

  useEffect(() => {
    setList(data);
    setDisplayData(data);
  }, [data]);

  // new configs /////////////////////////
  const [currentPage, setCurrentPage] = useState(1);
  let options = [
    { value: 10, label: "10 records" },
    { value: 25, label: "25 records" },
    { value: 50, label: "50 records" },
    { value: list?.length, label: "All records" },
  ];
  const [show_num_of_records, setShowNumOfRecords] = useState(10);
  const [isSearch, setIsSearch] = useState(false);

  //   handle select field change
  const handleChange = (e) => {
    const val = e.target.value;
    setPageSize(val);
    setShowNumOfRecords(val);
    setIsSearch(true);
  };

  let currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return displayData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, PageSize, displayData]);

  useEffect(() => {
    setPageSize(PageSize);
  }, [PageSize]);

  // new configs /////////////////////////
  let searchData = (e) => {
    let searchText = e.target.value.toLowerCase().trim();
    if (searchText) {
      let filtered = [];
      if (searchText) {
        filtered = list.filter((el) => {
          return Object.values(el).some((val) =>
            String(val).toLowerCase().includes(searchText)
          );
        });
        setDisplayData(filtered);
      }
    } else {
      setDisplayData(list);
    }
  };

  const dataTable = currentTableData?.length ? (
    currentTableData?.map((row, index) => {
      let rowData = [];
      let i = 0;

      for (const key in row) {
        rowData.push({
          key: headers[i],
          val: row[key],
        });
        i++;
      }
      return (
        <tr
          className=" hover:bg-blue-50 sm:border sm:border-gray-200 sm:shadow-lg sm:grid sm:divide-y sm:divide-gray-200 sm:bg-white sm:rounded-xl even:bg-gray-200 "
          key={index}
        >
          {rowData.map((data, index) => (
            <td
              className={`py-2 sm:py-3 px-3 text-[12px] tracking-wide text-gray-600 before:text-gray-700 sm:flex sm:before:justify-between sm:basis sm:before:basis-1/2 sm:before:content-[attr(data-label)]`}
              key={index}
              data-label={data.key}
              data-heading={data.key}
            >
              {data.val}
            </td>
          ))}
        </tr>
      );
    })
  ) : (
    <tr className="w-full">
      <td colSpan={Object.keys(headers).length}>
        <img src={img} alt="" />
      </td>
    </tr>
  );
  return (
    <div className=" bg-transparent py-1 sm:bg-transparent border border-gray-200 sm:border-none min-h-fit rounded-lg shadow-sm">
      <div className={`${list?.length ? "" : "hidden"}`}>
        <div
          className={`${
            list?.length < 6
              ? "hidden"
              : "flex flex-row justify-between mt-1  space-x-4 border-b sm:border-none border-gray-200 p-3 sm:p-0 sm:my-3"
          }`}
        >
          <div className={`w-max sm:w-1/3`}>
            <SelectInput
              label="Show"
              onChange={handleChange}
              options={options}
              name="show_num_of_records"
              value={show_num_of_records}
            />
          </div>
          <div className="w-1/3 sm:w-2/3">
            <SearchInput search={searchData} />
          </div>
        </div>
        <div className="px-2 sm:px-0 mb-1 ">
          <table
            className={`${currentTableData?.length < 1 ? "hidden" : "w-full"}`}
          >
            <thead className="border-b border-gray-300 sm:hidden">
              <tr className="">
                {headers.map((col, index) => (
                  <th
                    className="py-2 px-3 text-[12px] font-semibold tracking-wide text-left text-gray-500"
                    key={index}
                  >
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="sm:space-y-2 divide-y divide-gray-200">
              {dataTable}
            </tbody>
          </table>
          <div
            className={`${
              currentTableData?.length
                ? "hidden"
                : "items-center w-full h-full flex justify-center"
            }`}
          >
            <img src={img} alt="" />
          </div>
        </div>
        <div
          className={`${
            list?.length > 10 || isSearch ? "block" : "hidden"
          } flex justify-between px-5 py-3`}
        >
          <div className={`text-xs text-gray-600`}>
            Total records: {list?.length}
          </div>
          <div className="flex justify-between">
            <Pagination
              className="flex justify-between space-x-3"
              currentPage={currentPage}
              totalCount={list?.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
      <div
        className={`${
          list?.length
            ? "hidden"
            : "items-center w-full h-full flex justify-center"
        }`}
      >
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default DataTable;
