// set the actions

import PermissionsClient from "../../api/PermissionsClient";
import {
  ADD_ROLE,
  ASSIGN_ROLE,
  CREATE_DEPARTMENT,
  CREATE_ROLE,
  DELETE_ROLE,
  INITIALIZE_PERMISSIONS_STATE,
  SET_DEPARTMENTS,
  SET_ERROR_MESSAGE,
  SET_ROLES,
  SET_SUCCESS_MESSAGE,
  TOGGLE_ADDING_ROLE,
  TOGGLE_ASSIGNING_ROLE,
  TOGGLE_CREATING_DEPARTMENT,
  TOGGLE_CREATING_ROLE,
  TOGGLE_DELETING_ROLE,
  TOGGLE_FETCHING_DEPARTMENTS,
  TOGGLE_UPDATING_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from "./actionTypes";

export const initializePermissionsState = () => {
  return {
    type: INITIALIZE_PERMISSIONS_STATE,
  };
};

export const toggleFetchingDepartments = (status) => {
  return {
    type: TOGGLE_FETCHING_DEPARTMENTS,
    payload: status,
  };
};

export const toggleCreatingDepartment = (status) => {
  return {
    type: TOGGLE_CREATING_DEPARTMENT,
    payload: status,
  };
};

export const toggleUpdatingDepartment = (status) => {
  return {
    type: TOGGLE_UPDATING_DEPARTMENT,
    payload: status,
  };
};

export const setDepartments = (data) => {
  return {
    type: SET_DEPARTMENTS,
    payload: data,
  };
};

export const createDepartment = (dept) => {
  return {
    type: CREATE_DEPARTMENT,
    payload: dept,
  };
};

export const updateDepartment = (dept) => {
  return {
    type: UPDATE_DEPARTMENT,
    payload: dept,
  };
};

export const toggleFetchingRoles = (status) => {
  return {
    type: TOGGLE_FETCHING_DEPARTMENTS,
    payload: status,
  };
};

export const toggleCreatingRole = (status) => {
  return {
    type: TOGGLE_CREATING_ROLE,
    payload: status,
  };
};

export const toggleAddingRole = (status) => {
  return {
    type: TOGGLE_ADDING_ROLE,
    payload: status,
  };
};

export const toggleAssigningRole = (status) => {
  return {
    type: TOGGLE_ASSIGNING_ROLE,
    payload: status,
  };
};

export const toggleDeletingRole = (status) => {
  return {
    type: TOGGLE_DELETING_ROLE,
    payload: status,
  };
};

export const setRoles = (data) => {
  return {
    type: SET_ROLES,
    payload: data,
  };
};

export const createRole = (data) => {
  return {
    type: CREATE_ROLE,
    payload: data,
  };
};

export const assignRole = (data) => {
  return {
    type: ASSIGN_ROLE,
    payload: data,
  };
};

export const addRole = (data) => {
  return {
    type: ADD_ROLE,
    payload: data,
  };
};

export const deleteRole = (data) => {
  return {
    type: DELETE_ROLE,
    payload: data,
  };
};

export const setSuccessMessage = (msg) => {
  return {
    type: SET_SUCCESS_MESSAGE,
    payload: msg,
  };
};

export const setErrorMessage = (error) => {
  return {
    type: SET_ERROR_MESSAGE,
    payload: error,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchDepartmentsAction() {
  return function action(dispatch) {
    dispatch(initializePermissionsState);
    dispatch(toggleFetchingDepartments(true));

    return PermissionsClient.fetchDepartments()
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(setDepartments(response.data.data));
          dispatch(toggleFetchingDepartments(false));
        } else {
          dispatch(setErrorMessage("Something went wrong!"));
          dispatch(toggleFetchingDepartments(false));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setErrorMessage("Server error"));
        dispatch(toggleFetchingDepartments(false));
      });
  };
}
export function createDepartmentAction(data) {
  return function action(dispatch) {
    dispatch(initializePermissionsState);
    dispatch(toggleCreatingDepartment(true));

    return PermissionsClient.addDepartment(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(createDepartment(response.data.data));
          dispatch(toggleCreatingDepartment(false));
        } else {
          dispatch(setErrorMessage("Something went wrong!"));
          dispatch(toggleCreatingDepartment(false));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage("Server error"));
        dispatch(toggleCreatingDepartment(false));
      });
  };
}
export function updateDepartmentAction(data) {
  return function action(dispatch) {
    dispatch(initializePermissionsState);
    dispatch(toggleUpdatingDepartment(true));

    return PermissionsClient.updateDepartment(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateDepartment(response.data.data));
          dispatch(toggleUpdatingDepartment(false));
        } else {
          dispatch(setErrorMessage("Something went wrong!"));
          dispatch(toggleUpdatingDepartment(false));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage("Server error"));
        dispatch(toggleUpdatingDepartment(false));
      });
  };
}

export function fetchRolesAction() {
  return function action(dispatch) {
    dispatch(initializePermissionsState);
    dispatch(toggleFetchingRoles(true));

    return PermissionsClient.fetchRoles()
      .then((response) => {
        if (response.status === 200) {
          dispatch(setRoles(response.data.data));
          dispatch(toggleFetchingRoles(false));
        } else {
          dispatch(setErrorMessage("Something went wrong!"));
          dispatch(toggleFetchingRoles(false));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage("Server error"));
        dispatch(toggleFetchingRoles(false));
      });
  };
}

export function createRoleAction(data) {
  return function action(dispatch) {
    dispatch(initializePermissionsState);
    dispatch(toggleCreatingRole(true));

    return PermissionsClient.createRole(data)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          dispatch(createRole(response.data.data));
          dispatch(toggleCreatingRole(false));
        } else {
          dispatch(setErrorMessage("Something went wrong!"));
          dispatch(toggleCreatingRole(false));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage("Server error"));
        dispatch(toggleCreatingRole(false));
      });
  };
}

export function assignRoleAction(data) {
  return function action(dispatch) {
    dispatch(initializePermissionsState);
    dispatch(toggleAssigningRole(true));

    return PermissionsClient.assignRole(data)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          dispatch(assignRole(response.data.data));
          dispatch(toggleAssigningRole(false));
        } else {
          dispatch(setErrorMessage("Something went wrong!"));
          dispatch(toggleAssigningRole(false));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage("Server error"));
        dispatch(toggleAssigningRole(false));
      });
  };
}

export function addRoleAction(data) {
  return function action(dispatch) {
    dispatch(initializePermissionsState);
    dispatch(toggleAddingRole(true));

    return PermissionsClient.addRole(data)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          dispatch(addRole(response.data.data));
          dispatch(toggleAddingRole(false));
        } else {
          dispatch(setErrorMessage("Something went wrong!"));
          dispatch(toggleAddingRole(false));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage("Server error"));
        dispatch(toggleAddingRole(false));
      });
  };
}

export function deleteRoleAction(data) {
  return function action(dispatch) {
    dispatch(initializePermissionsState);
    dispatch(toggleDeletingRole(true));

    return PermissionsClient.deleteRole(data)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          dispatch(deleteRole(response.data.data));
          dispatch(toggleDeletingRole(false));
        } else {
          dispatch(setErrorMessage("Something went wrong!"));
          dispatch(toggleDeletingRole(false));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage("Server error"));
        dispatch(toggleDeletingRole(false));
      });
  };
}
