/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import FabButton from "../global/FabButton";
import SidePanel from "../global/SidePanel";
import { connect, useDispatch } from "react-redux";
import {
  closeAddSideModal,
  createRoleAction,
  fetchDepartmentsAction,
  fetchRolesAction,
  openAddSideModal,
} from "../../redux/Index";
import Button from "../global/Button";
import SelectInput from "../global/SelectInput";
import TextInput from "../global/TextInput";
import { ToastContainer, toast } from "react-toastify";
// import { menuItems } from "../../configs/SidebarNav";
import CheckBox from "../global/CheckBox";

function Roles(props) {
  // const [isEdit, setIsEdit] = useState(false);
  // const [selectedItem, setSelectedItem] = useState({});
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setDepartments(props.permissions?.departments);
    setRoles(props.permissions?.createdRoles);
  }, [props.permissions]);

  useEffect(() => {
    setMenuItems(props.modulesState?.modules);
  }, [props.modulesState]);

  const loadData = () => {
    dispatch(fetchDepartmentsAction());
    dispatch(fetchRolesAction());
  };

  useEffect(() => {
    if (isSubmit) {
      if (props.permissions?.processSet) {
        setIsSubmit(false);
        closeSidePanel();
        toast.success("Role created");
      } else {
        toast.error("Failed to create Role");
        setIsSubmit(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.permissions?.createdRoles]);

  const headers = ["Name", "Department"];
  let tableRows = [];
  if (roles?.length) {
    for (let i = 0; i < roles?.length; i++) {
      tableRows.push({
        name: roles[i].name,
        department: roles[i].group,
        // edit: (
        //   <i
        //     className="bx bx-edit links text-[16px] cursor-pointer"
        //     onClick={() => editSelected(roles[i])}
        //   ></i>
        // ),
      });
    }
  }

  // useEffect(() => {
  //   if (isEdit && selectedItem) {
  //     setInputValues({
  //       name: selectedItem.name,
  //       department: selectedItem.department,
  //     });
  //   }
  // }, [isEdit, selectedItem]);

  // const editSelected = (item) => {
  //   setIsEdit(true);
  //   setSelectedItem(item);
  //   openSidePanel();
  // };

  //  departments select input options
  let deptOptions = [{ value: "", label: "-- select --" }];

  if (departments) {
    for (let i = 0; i < departments?.length; i++) {
      deptOptions.push({
        value: departments[i].id,
        label: departments[i].name,
      });
    }
  }
  //  modules select input options
  let moduleOptions = [{ value: 0, label: "-- select --" }];
  const [newMenuList, setNewMenuList] = useState([
    ...props.modulesState?.modules,
  ]);

  if (newMenuList) {
    for (let i = 0; i < newMenuList?.length; i++) {
      moduleOptions.push({
        value: newMenuList[i].id,
        label: newMenuList[i].name,
      });
    }
  }

  // update menu when an item has been assigned
  const updateMenuList = (id) => {
    let newList = newMenuList?.filter((el) => el.id !== id);
    setNewMenuList(newList);
  };

  // repopulate modules list when modal opens
  const refreshModulesList = (id) => {
    setNewMenuList([...props.modulesState?.modules]);
  };

  const [inputValues, setInputValues] = useState({
    name: "",
    group: "",
    req_permissions: [],
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);

  // store permissions
  const [reqPerms, setReqPerms] = useState({
    module: null,
  });
  const [can_read, setCan_read] = useState(false);
  const [can_write, setCan_write] = useState(false);
  const [can_create, setCan_create] = useState(false);
  const [can_delete, setCan_delete] = useState(false);
  const [can_import, setCan_import] = useState(false);
  const [can_export, setCan_export] = useState(false);

  // update permissions state
  const handlePermisssionsChange = (e) => {
    const { name, value } = e.target;
    setReqPerms({ ...reqPerms, [name]: value });
  };

  // this function adds request permissions to inputValues
  const assignModule = () => {
    setIsAssigning(true);
    let permissions = {
      module: Number(reqPerms.module),
      can_read: can_read,
      can_write: can_write,
      can_create: can_create,
      can_delete: can_delete,
      can_import: can_import,
      can_export: can_export,
    };
    if (!permissions.module || permissions.module < 1) {
      toast.error("Please select a module");
      setIsAssigning(false);
    } else {
      let newReqPerms = inputValues.req_permissions
        ? [...inputValues.req_permissions]
        : [];
      newReqPerms.push(permissions);
      updateMenuList(permissions.module);
      setIsAssigning(false);
      setInputValues({ ...inputValues, req_permissions: newReqPerms });
      setReqPerms({ ...reqPerms, module: null });
      setCan_read(false);
      setCan_write(false);
      setCan_create(false);
      setCan_delete(false);
      setCan_import(false);
      setCan_export(false);
    }
  };
  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
    setIsSubmit(false);
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (
      inputValues.name !== "" &&
      inputValues.group !== "" &&
      inputValues.req_permissions.length > 0
    ) {
      inputValues.group = Number(inputValues.group);
      dispatch(createRoleAction(inputValues));
    } else {
      setIsSubmit(false);
      toast.error("Check your form, some fields are empty!");
    }
  };

  let getModuleName = (id) => {
    const item_id = Number(id);
    if (item_id > 0) {
      const item = menuItems?.find((el) => el.id === item_id);
      return Object.keys(item).length > 0 ? item.name : "Unknown module";
    }
  };

  let removeAssignedModule = (index) => {
    const item_id = inputValues.req_permissions?.find(
      (el, i) => i === index
    ).module;
    const item = menuItems?.find((el) => el.id === item_id);
    let newList = [...newMenuList];
    newList.unshift(item);
    setNewMenuList(newList);
    setInputValues({
      ...inputValues,
      req_permissions: inputValues.req_permissions.filter(
        (el, i) => i !== index
      ),
    });
  };

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };
  const closeSidePanel = () => {
    dispatch(closeAddSideModal());
  };

  const fabIconClick = () => {
    refreshModulesList();
    openSidePanel();
    setInputValues({
      name: "",
      group: "",
      req_permissions: [],
    });
  };

  if (props.permissions?.fetchingRoles) {
    return <Loader />;
  }

  return (
    <div>
      <FabButton onPressAction={fabIconClick} />
      <div className="flex items-center justify-start"></div>
      <DataTable headers={headers} data={tableRows} />
      {/* display sidepanel */}
      <SidePanel width="30%" title="Roles" subtitle="Create a Role">
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            required="required"
            label="Role name"
            name="name"
            type="text"
            value={inputValues?.name}
            onChange={handleChange}
          />

          {inputValues.name && (
            <SelectInput
              label="Department"
              onChange={handleChange}
              options={deptOptions}
              name="group"
              value={inputValues?.group}
            />
          )}

          {inputValues.group && (
            <div className="border border-gray-200 rounded-md px-3 py-4 mb-4">
              <SelectInput
                label="Assign module"
                onChange={handlePermisssionsChange}
                options={moduleOptions}
                name="module"
                value={reqPerms?.module}
              />
              {reqPerms.module > 0 ? (
                <div>
                  <CheckBox
                    onChange={() => setCan_read(!can_read)}
                    name="can_read"
                    title="Can read"
                    isChecked={can_read}
                    value={can_read}
                  />
                  <CheckBox
                    onChange={() => setCan_write(!can_write)}
                    name="can_write"
                    title="Can write"
                    isChecked={can_write}
                    value={can_write}
                  />
                  <CheckBox
                    onChange={() => setCan_create(!can_create)}
                    name="can_create"
                    title="Can create"
                    isChecked={can_create}
                    value={can_create}
                  />
                  <CheckBox
                    onChange={() => setCan_delete(!can_delete)}
                    name="can_delete"
                    title="Can delete"
                    isChecked={can_delete}
                    value={can_delete}
                  />
                  <CheckBox
                    onChange={() => setCan_import(!can_import)}
                    name="can_import"
                    title="Can import"
                    isChecked={can_import}
                    value={can_import}
                  />
                  <CheckBox
                    onChange={() => setCan_export(!can_export)}
                    name="can_export"
                    title="Can export"
                    isChecked={can_export}
                    value={can_export}
                  />
                  <button
                    className="bg-blue-500 px-3 py-1 text-white text-sm rounded-sm"
                    type="button"
                    onClick={assignModule}
                    disabled={isAssigning}
                  >
                    {isAssigning ? "Assigning" : "Assign"}
                  </button>
                </div>
              ) : null}
            </div>
          )}

          {/* list the assigned modules */}
          {inputValues.req_permissions?.length > 0 && (
            <div className="max-h-80 overflow-y-auto border border-gray-200 rounded-md w-full mb-4">
              <p className="text-gray-600 text-sm py-2 mb-1 border-b border-gray-100">
                <span className="mx-2">Assigned modules</span>
              </p>
              <div className="divide-y divide-gray-100">
                {inputValues.req_permissions?.map((item, index) => (
                  <div className="p-2" key={index}>
                    <div className="flex justify-between items-center w-full">
                      <p className="text-gray-500 text-sm font-semibold">
                        {index + 1 + ". " + getModuleName(item.module)}
                      </p>
                      <i
                        onClick={() => removeAssignedModule(index)}
                        className="bx bx-trash links text-[20px] cursor-pointer text-red-500"
                      ></i>
                    </div>
                    <div className="px-6 divide-y divide-gray-100">
                      <p className="text-gray-500 text-xs items-center justify-between flex py-1">
                        Can read? <span>{item.can_read ? "Yes" : "No"}</span>
                      </p>
                      <p className="text-gray-500 text-xs items-center justify-between flex py-1">
                        Can write? <span>{item.can_write ? "Yes" : "No"}</span>
                      </p>
                      <p className="text-gray-500 text-xs items-center justify-between flex py-1">
                        Can create?{" "}
                        <span>{item.can_create ? "Yes" : "No"}</span>
                      </p>
                      <p className="text-gray-500 text-xs items-center justify-between flex py-1">
                        Can delete?{" "}
                        <span>{item.can_delete ? "Yes" : "No"}</span>
                      </p>
                      <p className="text-gray-500 text-xs items-center justify-between flex py-1">
                        Can import?{" "}
                        <span>{item.can_import ? "Yes" : "No"}</span>
                      </p>
                      <p className="text-gray-500 text-xs items-center justify-between flex py-1">
                        Can export?{" "}
                        <span>{item.can_export ? "Yes" : "No"}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <Button text={isSubmit ? "Processing" : "Save"} disabled={isSubmit} />
        </form>
      </SidePanel>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions ? state.permissions : [],
    modulesState: state.modules ? state.modules : [],
  };
};

export default connect(mapStateToProps)(Roles);
