import AgentsClient from "../../api/AgentsClient";
import DocumentsClient from "../../api/DocumentsClient";
import {
  CLEAR_AGENTS_STATE,
  SET_AGENTS,
  SET_AGENTS_ERROR,
  SET_AGENTS_SUCCESS,
  SET_SELECTED_AGENT,
  TOGGLE_FETCHING_AGENTS,
} from "./agentActionTypes";

// set selected agent
export const clearAgentsState = () => {
  return {
    type: CLEAR_AGENTS_STATE,
  };
};

export const toggleFetchingAgents = (status) => {
  return {
    type: TOGGLE_FETCHING_AGENTS,
    payload: status,
  };
};

export const setAgents = (data) => {
  return {
    type: SET_AGENTS,
    payload: data,
  };
};

export const setAgentsSuccess = (msg) => {
  return {
    type: SET_AGENTS_SUCCESS,
    payload: msg,
  };
};

export const setAgentsError = (error) => {
  return {
    type: SET_AGENTS_ERROR,
    payload: error,
  };
};

export const setSelectedAgent = (data) => {
  return {
    type: SET_SELECTED_AGENT,
    payload: data,
  };
};

///////////////////// DISPATCH ACTIONS ////////////////////

export function setSelectedAgentAction(data) {
  return function action(dispatch) {
    dispatch(setSelectedAgent(data));
  };
}

export function fetchAgentsAction(rowsPerPage, page) {
  return function action(dispatch) {
    dispatch(clearAgentsState);
    if (page === 1) dispatch(toggleFetchingAgents(true));

    return AgentsClient.fetchAgents(rowsPerPage, page)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setAgents(response.data));
        }
        dispatch(toggleFetchingAgents(false));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(toggleFetchingAgents(false));
      });
  };
}

export function approveAgentDocsAction(data) {
  return function action(dispatch) {
    return DocumentsClient.verifyAgentDocument(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          return { status: true, data: "Success" };
        }
      })
      .catch((error) => {
        // console.log(error.response);
        return { status: false, data: error.response.data.message };
      });
  };
}
