import { SET_SELECTED_BANK } from "./bankActionTypes";

// set selected agent
export const setSelectedBank = (data) => {
  return {
    type: SET_SELECTED_BANK,
    payload: data,
  };
};

export function setSelectedbankAction(data) {
  return function action(dispatch) {
    dispatch(setSelectedBank(data));
  };
}
