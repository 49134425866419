import {
  ADD_ROLE,
  ASSIGN_ROLE,
  CREATE_DEPARTMENT,
  CREATE_ROLE,
  DELETE_ROLE,
  INITIALIZE_PERMISSIONS_STATE,
  SET_DEPARTMENTS,
  SET_ERROR_MESSAGE,
  SET_ROLES,
  SET_SUCCESS_MESSAGE,
  TOGGLE_ADDING_ROLE,
  TOGGLE_ASSIGNING_ROLE,
  TOGGLE_CREATING_DEPARTMENT,
  TOGGLE_CREATING_ROLE,
  TOGGLE_DELETING_ROLE,
  TOGGLE_FETCHING_DEPARTMENTS,
  TOGGLE_FETCHING_ROLES,
  TOGGLE_UPDATING_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from "./actionTypes";

// set the initial state
const initialState = () => {
  return {
    success: "",
    error: "",
    fetchingDepartments: false,
    creatingDepartment: false,
    updatingDepartment: false,
    departments: [],
    fetchingRoles: false,
    creatingRole: false,
    addingRole: false,
    assigningRole: false,
    deletingRole: false,
    createdRoles: [],
    addedRoles: [],
    processSet: false,
  };
};

// set the reducer
const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_PERMISSIONS_STATE:
      return {
        ...state,
        success: "",
        error: "",
        processSet: false,
      };
    /* falls through */
    case TOGGLE_FETCHING_DEPARTMENTS:
      return {
        ...state,
        fetchingDepartments: action.payload,
      };
    /* falls through */
    case TOGGLE_CREATING_DEPARTMENT:
      return {
        ...state,
        creatingDepartment: action.payload,
      };
    /* falls through */
    case TOGGLE_UPDATING_DEPARTMENT:
      return {
        ...state,
        updatingDepartment: action.payload,
      };
    /* falls through */
    case SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    /* falls through */
    case CREATE_DEPARTMENT:
      return {
        ...state,
        departments: [...state.departments, action.payload],
        processSet: true,
      };
    /* falls through */
    case UPDATE_DEPARTMENT:
      let newDepts = [...state?.departments];
      let foundIndex = newDepts?.findIndex((x) => x.id === action.payload.id);
      newDepts[foundIndex] = action.payload;
      return {
        ...state,
        departments: newDepts,
      };
    /* falls through */
    case TOGGLE_FETCHING_ROLES:
      return {
        ...state,
        fetchingRoles: action.payload,
      };
    /* falls through */
    case TOGGLE_CREATING_ROLE:
      return {
        ...state,
        creatingRole: action.payload,
      };
    /* falls through */
    case TOGGLE_ADDING_ROLE:
      return {
        ...state,
        addingRole: action.payload,
      };
    /* falls through */
    case TOGGLE_ASSIGNING_ROLE:
      return {
        ...state,
        assigningRole: action.payload,
      };
    /* falls through */
    case TOGGLE_DELETING_ROLE:
      return {
        ...state,
        deletingRole: action.payload,
      };
    /* falls through */
    case SET_ROLES:
      return {
        ...state,
        createdRoles: action.payload,
      };
    /* falls through */
    case CREATE_ROLE:
      return {
        ...state,
        createdRoles: [...state.createdRoles, action.payload],
        processSet: true,
      };
    /* falls through */
    case ASSIGN_ROLE:
      return {
        ...state,
        createdRoles: action.payload,
      };
    /* falls through */
    case ADD_ROLE:
      return {
        ...state,
        addedRoles: [...state.addedRoles, action.payload],
      };
    /* falls through */
    case DELETE_ROLE:
      let newRoles = [
        ...state?.roles?.filter(
          (el) => Number(el.id) !== Number(action.payload)
        ),
      ];
      return {
        ...state,
        createdRoles: newRoles,
      };
    /* falls through */
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.payload,
      };
    /* falls through */
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    /* falls through */

    default:
      return state;
  }
};

export default permissionsReducer;
