import React from "react";

function Legend({ title }) {
  return (
    <div className="flex flex-row items-center justify-between space-x-2 my-2">
      <p className="text-gray-600 font-semibold text-[14px]">{title}</p>

      <div className="border border-gray-200 w-full flex-1"></div>
    </div>
  );
}

export default Legend;
