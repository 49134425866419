/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../components/global/Loader";
import DataTable from "../components/datatable/DataTable";
import CurrencyFormatter from "../components/global/CurrencyFormatter";
import HeaderText from "../components/global/HeaderText";
import {
  fetchClientsAction,
  fetchSavingsAction,
  openAddSideModal,
} from "../redux/Index";
import { useDispatch, useSelector } from "react-redux";
import SidePanel from "../components/global/SidePanel";
import DateTimeFormat from "../components/global/DateTimeFormat";
import { sumValues } from "../components/global/utilityFunctions";
import ApiDataTable from "../components/datatable/ApiDataTable";

function Savings() {
  const dispatch = useDispatch();
  const [clients, setClients] = useState(null);
  const [savings, setSavings] = useState(null);
  const clientsState = useSelector((state) => state?.clients);

  const [selectedClient, setSelectedClient] = useState();
  const [userTransactions, setUserTransactions] = useState(null);

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const getClients = (rowsPerPage, page) => {
    dispatch(fetchClientsAction(page, rowsPerPage));
  };
  const getSavings = (rowsPerPage, page) => {
    dispatch(fetchSavingsAction(rowsPerPage, page));
  };

  useEffect(() => {
    getSavings(10, 1);
    getClients(10, 1);
  }, []);

  useEffect(() => {
    setClients(clientsState?.clients);
  }, [clientsState.clients]);

  useEffect(() => {
    setUserTransactions(
      savings?.data?.filter((item) => item.client === selectedClient?.id)
    );
  }, [selectedClient]);

  useEffect(() => {
    setSavings(clientsState?.savings.data);
  }, [clientsState.savings]);

  const headers = [
    "Saver's national ID",
    "Saver's name",
    "Phone number",
    "More",
  ];
  let tableRows = [];
  const list = clients?.data;

  if (list?.length) {
    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      tableRows.push({
        client_id: element?.id_number,
        client: `${element?.first_name} ${element?.last_name}`,
        phone: element?.phone_number,
        more: (
          <i
            className="bx bx-link-external links text-[16px] cursor-pointer"
            onClick={() => openMore(element)}
          ></i>
        ),
      });
    }
  }

  const openMore = (item) => {
    setSelectedClient(item);
    openSidePanel();
  };

  const displayContent = clientsState?.fetchingClients ? (
    <Loader />
  ) : (
    <ApiDataTable
      headers={headers}
      data={tableRows}
      loadDataAction={getClients}
      totalPageCount={clients?.last_page}
    />
  );

  // DISPLAY SAVINGS AS PER CLIENT
  const savingsHeaders = [
    "Transaction ID",
    "Title",
    "Transaction date",
    "Type",
    "Amount",
    "Start date",
    "Target date",
    "Update date",
    "Status ",
  ];
  let savingsTableRows = [];

  if (userTransactions?.length) {
    for (let i = 0; i < userTransactions.length; i++) {
      const element = userTransactions[i];
      savingsTableRows.push({
        transaction_id: element?.id,
        title: element?.name,
        transaction_date: (
          <DateTimeFormat>{element?.created_at}</DateTimeFormat>
        ),
        type: element?.type,
        amount: <CurrencyFormatter value={element?.target_amount} />,
        start_date: <DateTimeFormat>{element?.start_date}</DateTimeFormat>,
        target_date: <DateTimeFormat>{element?.target_date}</DateTimeFormat>,
        updated_at: <DateTimeFormat>{element?.updated_at}</DateTimeFormat>,
        is_active: element?.is_active > 0 ? "Active" : "Inactive",
      });
    }
  }

  // Total amount saved
  const [totalAmountSaved, setTotalAmountSaved] = useState(0);

  useEffect(() => {
    setTotalAmountSaved(savings ? sumValues(savings, "target_amount") : 0);
  }, [savings]);

  return (
    <div>
      {/* header information */}
      <HeaderText title="Savings" />

      {/* display the total amount saved */}
      <div className="flex items-center justify-end w-full my-4">
        <div className=" border border-gray-200 h-16 w-max flex-col p-4 rounded-md shadow-md">
          <p className="text-end text-xs text-gray-500">Total amount saved</p>
          <span className="font-bold text-gray-500">
            {<CurrencyFormatter value={totalAmountSaved} />}
          </span>
        </div>
      </div>

      {/* display table contents of the clients records */}
      {displayContent}
      <SidePanel
        width="80%"
        title={`${selectedClient?.first_name} ${selectedClient?.last_name}`}
        subtitle={`Savings history`}
      >
        {/* display the total amount saved */}
        <div className="flex items-center justify-end w-full my-4">
          <div className=" border border-gray-200 h-16 w-max flex-col p-4 rounded-md shadow-md">
            <p className="text-end text-xs text-gray-500">
              {`${selectedClient?.first_name} ${selectedClient?.last_name}'s savings`}
            </p>
            <span className="font-bold text-gray-500">
              {
                <CurrencyFormatter
                  value={
                    userTransactions
                      ? sumValues(userTransactions, "target_amount")
                      : 0
                  }
                />
              }
            </span>
          </div>
        </div>
        <DataTable headers={savingsHeaders} data={savingsTableRows} />
      </SidePanel>
    </div>
  );
}

export default Savings;
