import React from "react";
import { connect } from "react-redux";
import HeaderNav from "../global/HeaderNav";
import TabsComponent from "../global/TabsComponent";
import CancelledTransactions from "./CancelledTransactions";
import CompletedTransactions from "./CompletedTransactions";
import ReversedTransactions from "./ReversedTransactions";

function BankTransactionsHistory(props) {
  const tabs = [
    { name: "Completed", component: <CompletedTransactions /> },
    { name: "Reversals", component: <ReversedTransactions /> },
    { name: "Cancelled", component: <CancelledTransactions /> },
  ];
  return (
    <div>
      <HeaderNav
        title={
          props.selectedBank?.bank_name + " Transactions" ?? "Unknown bank"
        }
      />
      <TabsComponent tabsList={tabs} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedBank: state.banks?.selectedBank ?? {},
  };
};

export default connect(mapStateToProps)(BankTransactionsHistory);
