/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../global/Loader";
import DataTable from "../datatable/DataTable";
import FabButton from "../global/FabButton";
import SidePanel from "../global/SidePanel";
import { connect, useDispatch } from "react-redux";
import {
  closeAddSideModal,
  fetchRolesAction,
  openAddSideModal,
} from "../../redux/Index";
import TextInput from "../global/TextInput";
import Button from "../global/Button";
import { ToastContainer, toast } from "react-toastify";
import {
  createUserAction,
  fetchUsersAction,
  updateUserAction,
} from "../../redux/users/actions";
import logo from "../../assets/images/avatar.jpg";
import { countries } from "../../constants/Countries";
import SelectInput from "../global/SelectInput";
import { Switch } from "@mui/material";

function UsersList(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [users, setUsers] = useState(null);
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = () => {
    dispatch(fetchUsersAction(), fetchRolesAction());
  };

  useEffect(() => {
    setRoles(props.permissionsState?.createdRoles);
  }, [props.permissionsState]);

  useEffect(() => {
    setUsers(props.usersState?.users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.usersState]);

  const headers = [
    "Image",
    "Name",
    "email",
    "Phone",
    "ID #",
    "Gender",
    "Status",
    "Edit",
  ];
  let tableRows = [];
  if (users?.length) {
    for (let i = 0; i < users?.length; i++) {
      tableRows.push({
        profile_image: (
          <img
            src={users[i].profile_image ? users[i].profile_image : logo}
            className="w-8 h-8 rounded-full bg-gray-100"
            alt="Uimage"
          />
        ),
        name: users[i].first_name + " " + users[i].last_name,
        email: users[i].email_address,
        phone: users[i].phone_number,
        id_number: users[i].id_number,
        gender: users[i].gender,
        status: (
          <Switch
            checked={users[i].is_active}
            onChange={() => toggleModuleStatus(users[i].id)}
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
        edit: (
          <i
            className="bx bx-pencil links text-[20px] cursor-pointer"
            onClick={() => editSelected(users[i])}
          ></i>
        ),
      });
    }
  }

  const toggleModuleStatus = (id) => {};

  useEffect(() => {
    if (isEdit && selectedItem) {
      setInputValues({
        first_name: selectedItem.first_name,
        last_name: selectedItem.last_name,
        email_address: selectedItem.email_address,
        phone_number: selectedItem.phone_number,
        gender: selectedItem.gender,
        id_number: selectedItem.id_number,
        country: selectedItem.country,
        role: selectedItem.role,
      });
    }
  }, [isEdit, selectedItem]);

  const editSelected = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    openSidePanel();
  };

  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    gender: "",
    id_number: "",
    country: "",
    role: "",
  });

  //  departments select input options
  let roleOptions = [{ value: "", label: "-- select --" }];

  if (roles) {
    for (let i = 0; i < roles?.length; i++) {
      roleOptions.push({
        value: roles[i].id,
        label: roles[i].name,
      });
    }
  }

  //  countries select input options
  let countryOptions = [{ value: "", label: "-- select --" }];

  const countriesList = countries.sort((a, b) => a.name.localeCompare(b.name));

  if (countriesList) {
    for (let i = 0; i < countriesList.length; i++) {
      countryOptions.push({
        value: countriesList[i].id,
        label: countriesList[i].name,
      });
    }
  }

  //  gender select input options
  let genderOptions = [
    { value: "", label: "-- select --" },
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ];

  const [isSubmit, setIsSubmit] = useState(false);

  //   handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  //submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (inputValues.name !== "") {
      if (isEdit) {
        inputValues.id = selectedItem.id;
        dispatch(updateUserAction(inputValues)).then((response) => {
          if (response) {
            setIsSubmit(false);
            closeSidePanel();
            toast.success("User account updated successfully");
          } else {
            toast.error(props.usersState?.error);
          }
        });
      } else {
        dispatch(createUserAction(inputValues)).then((response) => {
          if (response) {
            setIsSubmit(false);
            closeSidePanel();
            toast.success("User account created");
          } else {
            setIsSubmit(false);
            toast.error(props.usersState?.error);
          }
        });
      }
    }
  };

  const openSidePanel = () => {
    dispatch(openAddSideModal());
  };

  const closeSidePanel = () => {
    dispatch(closeAddSideModal());
  };

  const fabIconClick = () => {
    openSidePanel();
    setIsEdit(false);
    setInputValues({
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
      gender: "",
      id_number: "",
      country: "",
      role: "",
    });
  };

  if (props.usersState?.fetchingUsers) {
    return <Loader />;
  }

  return (
    <div className="flex-1 h-full">
      <FabButton onPressAction={fabIconClick} />
      {/* load items in table */}
      <DataTable headers={headers} data={tableRows} />
      {/* display sidepanel */}
      <SidePanel width="30%" title="Users" subtitle="Create a User">
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            required="required"
            label="First name"
            name="first_name"
            type="text"
            value={inputValues.first_name}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Last name"
            name="last_name"
            type="text"
            value={inputValues.last_name}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Email address"
            name="email_address"
            type="email"
            value={inputValues.email_address}
            onChange={handleChange}
          />
          <TextInput
            required="required"
            label="Phone number"
            name="phone_number"
            type="text"
            maxLength="15"
            value={inputValues.phone_number}
            onChange={handleChange}
          />
          {/* country */}
          <SelectInput
            label="Country"
            onChange={handleChange}
            options={countryOptions}
            name="country"
            value={inputValues.country}
          />

          {/* gender */}
          <SelectInput
            label="Gender"
            onChange={handleChange}
            options={genderOptions}
            name="gender"
            value={inputValues.gender}
          />

          <TextInput
            required="required"
            label="ID number"
            name="id_number"
            type="number"
            maxLength="10"
            value={inputValues.id_number}
            onChange={handleChange}
          />
          <SelectInput
            label="Assign role"
            onChange={handleChange}
            options={roleOptions}
            name="role"
            value={inputValues?.role}
          />
          <Button
            text={isEdit ? "Update" : isSubmit ? "Processing" : "Save"}
            disabled={isSubmit}
          />
        </form>
      </SidePanel>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    permissionsState: state.permissions ? state.permissions : [],
    usersState: state.users ? state.users : [],
  };
};

export default connect(mapStateToProps)(UsersList);
