import { departments } from "../../constants/data";

function DepartmentName({ id }) {
  const name = departments
    ? departments?.find((item) => item.department_id === Number(id))
        .department_name
    : "Undefined";
  return name;
}

export default DepartmentName;
