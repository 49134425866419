import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchMerchantReportAction,
  fetchMerchantsTransactionsAction,
} from "../../redux/Index";
import CurrencyFormatter from "../global/CurrencyFormatter";
import { standardDateTime } from "../global/DateUtilities";
import Loader from "../global/Loader";
import ApiDataTable from "../datatable/ApiDataTable";
import Button from "../global/Button";
import { ToastContainer, toast } from "react-toastify";

function MerchantsTransactionsHistory({ selectedMerchant }) {
  const dispatch = useDispatch();

  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(null);

  const fetchMerchantTransactions = (rowsPerPage, page) => {
    if (page === 1) setLoading(true);

    dispatch(
      fetchMerchantsTransactionsAction(rowsPerPage, page, selectedMerchant.id)
    ).then((res) => {
      if (res.success) {
        setTransactions(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const exportPdf = () => {
    setLoading(true);
    dispatch(fetchMerchantReportAction(selectedMerchant.id)).then((res) => {
      res
        ? toast.success(`Report generated, please check your email.`)
        : toast.error(`Failed to export report. Please try again later.`);

      setLoading(false);
    });
  };

  useEffect(() => {
    fetchMerchantTransactions(10, 1);
    // eslint-disable-next-line
  }, []);

  const headers = [
    "Client",
    "Account number",
    "Transaction type",
    "Credit",
    "Debit",
    "PayGram fees",
    "Transaction date",
  ];
  let tableRows = [];
  const list = transactions?.data;

  if (list?.length) {
    for (let i = 0; i < list?.length; i++) {
      const element = list[i];
      tableRows.push({
        client: element?.store?.client?.full_name,
        account_number: element?.store?.client?.phone_number,
        type: element?.type_display,
        cr_amount: <CurrencyFormatter value={element?.cr_amount ?? 0} />,
        dr_amount: <CurrencyFormatter value={element?.dr_amount ?? 0} />,
        paygram_fees: <CurrencyFormatter value={element?.paygram_fees ?? 0} />,
        created_at: standardDateTime(element.created_at),
      });
    }
  }

  const displayContent = !loading ? (
    <ApiDataTable
      headers={headers}
      data={tableRows}
      loadDataAction={fetchMerchantTransactions}
      totalPageCount={transactions?.last_page}
    />
  ) : (
    <Loader />
  );

  return (
    <div>
      {/* header information */}
      {tableRows?.length ? (
        <div className="flex flex-row items-center justify-end mb-4 space-x-4">
          <div className="flex flex-row items-center">
            <p className="text-gray-700 text-sm">Total transactions:&nbsp; </p>
            <CurrencyFormatter
              value={transactions?.total_amount_transacted ?? 0}
            />
          </div>
          <Button
            disabled={loading}
            onClick={exportPdf}
            text="Export PDF"
            bgColor={"bg-primary"}
          />
        </div>
      ) : null}
      {/* display the data */}
      {displayContent}
      <ToastContainer />
    </div>
  );
}

export default MerchantsTransactionsHistory;
