/* eslint-disable import/no-anonymous-default-export */
import { searchQueryFormatter } from "../components/global/utilityFunctions";
import apiClient from "../redux/ApiClient";

export default {
  fetchMerchants(rowsPerPage, page) {
    return apiClient.get(
      `/merchants/store/view?rows=${rowsPerPage}&page=${page}`
    );
  },
  fetchMerchantRecord(query) {
    return apiClient.get(
      `/merchants/store/view?query=${searchQueryFormatter(query)}`
    );
  },
  fetchMerchantReport(store_id) {
    return apiClient.get(`/transactions/merchant/report?store_id=${store_id}`);
  },
  fetchMerchantTransactions(rowsPerPage, page, storeId) {
    return apiClient.get(
      `/transactions/view?rows=${rowsPerPage}&page=${page}&store_id=${storeId}`
    );
  },
  fetchMerchantsDocuments() {
    return apiClient.get("/merchant/store/document/view");
  },
  verifyMerchant(data) {
    return apiClient.patch("/merchant/store/document/verify/merchant", data);
  },
};
