import ClientsApiClient from "../../api/ClientsApiClient";
import DocumentsClient from "../../api/DocumentsClient";
import { fetchClientDocsAction } from "../Index";
import {
  INITIALIZE_CLIENTS_STATE,
  SET_CLIENTS,
  SET_CLIENT_ERROR_MESSAGE,
  SET_CLIENT_SUCCESS_MESSAGE,
  SET_SAVINGS,
  SET_SELECTED_CLIENT,
  TOGGLE_FETCHING_CLIENTS,
  TOGGLE_FETCHING_SAVINGS,
  TOGGLE_UPDATING_CLIENT,
  UPDATE_CLIENT,
  UPDATE_USER_PROFILE_PIC,
} from "./actionTypes";
// set the actions
export const initializeClientsState = () => {
  return {
    type: INITIALIZE_CLIENTS_STATE,
  };
};

export const toggleFetchingClients = (status) => {
  return {
    type: TOGGLE_FETCHING_CLIENTS,
    payload: status,
  };
};

export const toggleFetchingSavings = (status) => {
  return {
    type: TOGGLE_FETCHING_SAVINGS,
    payload: status,
  };
};

export const toggleUpdatingClient = (status) => {
  return {
    type: TOGGLE_UPDATING_CLIENT,
    payload: status,
  };
};

export const setClients = (data) => {
  return {
    type: SET_CLIENTS,
    payload: data,
  };
};

export const setSavings = (data) => {
  return {
    type: SET_SAVINGS,
    payload: data,
  };
};

export const updateClient = (data) => {
  return {
    type: UPDATE_CLIENT,
    payload: data,
  };
};

export const setClientSuccessMessage = (msg) => {
  return {
    type: SET_CLIENT_SUCCESS_MESSAGE,
    payload: msg,
  };
};

export const setClientErrorMessage = (error) => {
  return {
    type: SET_CLIENT_ERROR_MESSAGE,
    payload: error,
  };
};

export const setSelectedClient = (data) => {
  return {
    type: SET_SELECTED_CLIENT,
    payload: data,
  };
};

export const updateUserProfilePic = (data) => {
  return {
    type: UPDATE_USER_PROFILE_PIC,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////
export function setSelectedClientAction(data) {
  return function action(dispatch) {
    dispatch(setSelectedClient(data));
  };
}

// update checklist perscentage
export function updateUserProfilePicAction(data) {
  return function action(dispatch) {
    dispatch(updateUserProfilePic(data));
  };
}

export function mergeClientDataDocs(resp) {
  return function action(dispatch) {
    const data = [];
    resp?.data?.forEach((element) => {
      dispatch(
        fetchClientDocsAction(element.id).then((res) => {
          const item = res?.find((el) => el.type === "PROF");
          data.push({ ...element, file: item.file });
        })
      );
    });
    const clientData = {
      success: true,
      data: data,
      last_page: resp.last_page,
    };
    console.log(clientData);
    dispatch(setClients(clientData));
  };
}

export function fetchClientsAction(page, rowsPerPage) {
  return function action(dispatch) {
    dispatch(initializeClientsState);
    if (page === 1) {
      dispatch(toggleFetchingClients(true));
    }

    return ClientsApiClient.fetchClients(page, rowsPerPage)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          // dispatch(mergeClientDataDocs(response.data));
          dispatch(setClients(response.data));
          dispatch(toggleFetchingClients(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingClients(false));
      });
  };
}

export function fetchingClientRecordAction(query) {
  return function action() {
    return ClientsApiClient.fetchClientRecord(query)
      .then((response) => {
        return { success: true, data: response.data };
      })
      .catch((error) => {
        return { success: false, data: null };
      });
  };
}

export function fetchSavingsAction(rowsPerPage, page) {
  return function action(dispatch) {
    dispatch(initializeClientsState);
    if (page === 1) dispatch(toggleFetchingSavings(true));

    return ClientsApiClient.fetchClientsSavings(rowsPerPage, page)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setSavings(response.data));
          dispatch(toggleFetchingSavings(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingSavings(false));
      });
  };
}

export function updateClientAction(data) {
  return function action(dispatch) {
    dispatch(initializeClientsState);
    dispatch(toggleUpdatingClient(true));

    return ClientsApiClient.clientActions(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(updateClient(response.data.data));
          dispatch(toggleUpdatingClient(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleUpdatingClient(false));
      });
  };
}

export function approveClientDocsAction(data) {
  return function action(dispatch) {
    dispatch(initializeClientsState);

    return DocumentsClient.verifyClientDocument(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          return { status: true, data: "Success" };
        }
      })
      .catch((error) => {
        // console.log(error.response);
        return { status: false, data: error.response.data.message };
      });
  };
}

export function fetchingClientReportAction(client_id) {
  return function action() {
    return ClientsApiClient.fetchClientReport(client_id)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  };
}
